import React from 'react';
import './index.scss';

export default function ApplicationTabularScrollbar({ children }) {
    return (
        <div className="application-tabular-scrollbar ">
            <div>
                { children }
            </div>
        </div>
    )
}