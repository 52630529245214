import React from 'react';
import LoaderImage from '../../assets/images/mdj_loader.gif';
import './index.scss';

const loader = ({ className }) => {
    return (
        <div className = 'loader'>
            <img className = {className} src = {LoaderImage}  alt = "loader"/>
        </div>
    );
};

export default loader;