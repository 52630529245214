import {connect} from 'react-redux';
import { withApollo} from 'react-apollo';
import { updateHomeDetails } from './store/dispatchers';
import gql from 'graphql-tag';

const getHomDetails = gql `
  query getHomeDetailsForWebsite {
    getHomeDetailsForWebsite {
        mapData {
       categoryId,
       displayName,
       data {
         courtId,
         countyList {
           countyId,
           displayName,
           fiscalYears {
             displayName,
             filings,
             dispositions
           }
         },
         fiscalYears {
             displayName,
             filings,
             dispositions
           }
       }
     }
     }
  }`;
const HomeDetailsGetQuery = ({
  client,
  updateHomeDetails,
  // loadingHomeDetails
}) => {
  client.query({
      query: getHomDetails
    })
    .then(details => {
      const data = JSON.parse(JSON.stringify(details));
      updateHomeDetails(true, [...data.data.getHomeDetailsForWebsite.mapData])
    });

  return '';
};

// const mapStateWithProps = ({home}) => {
//   return {
//     loadingHomeDetails: home.loadingHomeDetails
//   };
// };

const mapDispatchToProps = (dispatch) => {
  return {
    updateHomeDetails(status, details) {
      dispatch(updateHomeDetails(status, details));
    }
  };
};

export default connect(null, mapDispatchToProps)(withApollo(HomeDetailsGetQuery));