import { withApollo } from 'react-apollo';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { onPreviewDetailsLoaded } from '../Preview/store/dispatchers';
import gql from 'graphql-tag';

const GetPreviewGetQuery = gql`
    query GetPreviewDetailsById($previewId: Int!)
    {
        getPreviewDetailsByPreviewId(previewId:$previewId) {
            dataUploadId,
            categoryName,
            categoryId,
            subCategoryId,
            subCategoryName,
            duration,
            fiscalYears,
            durationValue{
              fiscalYear,
              start {
                year,
                month
              }
              end {
                year,
                month
              }
            },
            fileName,
            fileId,
            status,
            createdAt,
            createdBy,
            userName
            userName,
            notes,
            reason
        }
    }
`
const PreviewGetQuery = ({
    client,
    previewId,
    loadingPreviewDetails,
    onPreviewDetailsLoaded
}) => {
    if (loadingPreviewDetails) {
        client.query({
            query: GetPreviewGetQuery,
            variables: {
                previewId: previewId
            }
        })
            .then(({ data }) => {
                onPreviewDetailsLoaded(data.getPreviewDetailsByPreviewId);
            });
    }
    return '';
}

const mapStateWithProps = ({ preview }) => {
    return {
        previewId: preview.previewId,
        loadingPreviewDetails: preview.loadingPreviewDetails
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onPreviewDetailsLoaded(data) {
            dispatch(onPreviewDetailsLoaded(data))
        }
    };
};

const PreviewWithApollo = withApollo(PreviewGetQuery);
export default connect(mapStateWithProps, mapDispatchToProps)(withRouter(PreviewWithApollo));
