import Actions from './actions';

export function loadPreviewMode(previewId) {
    return {
        type: Actions.LOAD_PREVIEW_MODE,
        data: previewId
    };
};

export function onPreviewDetailsLoaded(data) {
    return {
        type: Actions.ON_PREVIEW_DETAILS_LOADED,
        data: data
    }
}

