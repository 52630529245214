import React from "react";
import { find } from 'ramda';
import './index.scss';
import { CSVLink } from "react-csv";
import ApplicationListSection from "../ApplicationListSection";
import ApplicationFootNoteDetails from '../ApplicationFootNotesDetails';

export default function ApplicationChartOptions({
    exdcludeOptions,
    onClickOption,
    listNames,
    title = '',
    data = '',
    viewType = "chart",
    tabel = false,
    footnotes = null
}) {

    function onClick(e, displayName) {
        e.stopPropagation();
        onClickOption(displayName)
    }
    function msieversion() {
        var ua = window.navigator.userAgent;
        var msie = ua.indexOf("MSIE ");
        if (msie === 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
            return true;
        } else {
            // If another browser,
            return false;
        }
    }
    function onclickCSV(fileName, CSV) {
        if (msieversion()) {
            var IEwindow = window.open();
            IEwindow.document.write('sep=,\r\n' + CSV);
            IEwindow.document.close();
            IEwindow.document.execCommand('SaveAs', true, fileName + ".csv");
            IEwindow.close()
        }

    }
    const options = [
        {
            name: "pie-chart",
            icon: "icon-chart"
        },
        {
            name: "bar-chart",
            icon: "icon-bars"
        },
        {
            name: "refresh",
            icon: "icon-refresh"
        },
        {
            name: "download",
            icon: "icon-download"
        }
    ];

    const filteredOptions = options.reduce((accumlator, option) => {
        const excludedOption = find(excludeOption => excludeOption === option.name)(exdcludeOptions);
        if (!excludedOption) {
            return accumlator.concat([option]);
        } else {
            return accumlator;
        }
    }, [])
    return (
        <div className={tabel ? 'chart-options' : 'chart-options border-top'}>
            <div className={viewType === "data" ? "application-table-options" : "application-chart-options"}>
                {
                    filteredOptions.map(({ icon, name }, index) => {
                        return (

                            tabel ?
                                (name === 'download')
                                    ? <CSVLink
                                        filename={`${data.court}-${title}.csv`}
                                        data={data.csv}
                                        key={index} >
                                        <span
                                            className={`${icon} application-chart-options__option`}
                                            id={name}
                                            value={name}
                                            onClick={e => onclickCSV(`${data.court}-${title}.csv`, data.csv)}>
                                            <ApplicationListSection
                                                listNames={listNames}
                                                viewType={viewType}
                                                onClickOption={onclickCSV} />

                                        </span>
                                    </CSVLink>
                                    : <span
                                        key={index}
                                        className={`${icon} application-chart-options__option`}
                                        id={name}
                                        value={name}
                                        onClick={e => onClickOption(name)}>
                                    </span>

                                : <span
                                    key={index}
                                    className={`${icon} application-chart-options__option`}
                                    id={name}
                                    value={name}
                                    onClick={e => onClick(e, name)}>
                                </span>

                        )
                    })
                }
            </div>
            <div className='foot-notes'>
                <ApplicationFootNoteDetails footnotes={footnotes} />
            </div>
        </div>
    );
};