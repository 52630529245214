import React,{Component} from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import drilldown from "highcharts/modules/drilldown";
import HC_exporting from 'highcharts/modules/exporting';
import ApplicationListSection from '../ApplicationListSection'
import ApplicationChartOptions from "../ApplicationChartOptions";

import './index.scss';

export default class ApplicationPieChart extends Component{
    constructor(props) {
        super(props)
        this.legends = this.legends.bind(this)
        this.drillup = this.drillup.bind(this)
    }
    componentDidUpdate(){
        Highcharts.charts.map((chart)=>{
            if(chart){
                chart.drillUp();
            }
            return ''
       })
    }

    drillup(){
        const {onDrillUp} = this.props;
        Highcharts.charts.map((chart)=>{
            if(chart){
                onDrillUp()
                chart.drillUp();
            }
            return ''
       })
    }

    legends(seriesData) {
        const {type,name}=this.props
        // ((seriesData && seriesData.find(({ name }) => name.length > 20))||
        const consthasMaxCharLimit = (name==='businessRelatedWorkload')?
            false
            :((seriesData && seriesData.length > 20 ))
                ? true 
                : false;
        if (type === 'comparison') {
            return {
                enabled :true,
                align: consthasMaxCharLimit?'center':'left',
                layout: consthasMaxCharLimit?'horizontal':' vertical',
                verticalAlign:consthasMaxCharLimit? 'bottom':'center',
                itemMargintop: 40,
                itemMarginRight:10,
                symbolHeight:10,
                itemStyle: {
                    color: '#676767;',
                    textOverflow: null,
                    width:250
                }
            }
        }
        else {
            return {
                enabled :true,
                align: consthasMaxCharLimit?'center':'left',
                layout: consthasMaxCharLimit?'horizontal':' vertical',
                verticalAlign:consthasMaxCharLimit? 'bottom':'center',
                itemMarginBottom: 10,
                itemMarginRight: 10,
                symbolHeight:10,
                itemStyle: {
                    color: '#676767',
                    textOverflow: null,
                    width:250,
                    // marginRight:10
                },
              
               
                useHTML: true
            }
        }
    }

    onDownload(displayName,options,seriesData){
        let type
        if(displayName==='PNG'){
            type= 'image/png'
        } 
        if(displayName==='JPG'){
            type= 'image/jpeg'
        }
        if(displayName==='PDF'){
            type= 'application/pdf'
        }
        const {title,subTitle,selectedYears,ByTerm ,selectedSubMenuChild} =this.props
        const term = ByTerm? '(ByTerm)': ''
        // const horizontal =  (seriesData && (seriesData.length > 0) )
        const excess = (seriesData && (seriesData.length > 20) )
        const _selectedYears = selectedYears.map ((year)=>{
            if(year.value){
                return year.value
            }
            else {
                return year
            }
        })
        let name = `${subTitle.displayName}-${title} ${term} (${_selectedYears.join(',')})`;
        if(subTitle.name === "selfHelpCenter"){
            name = `${title} ${term} (${_selectedYears.join(',')})`;
            if(selectedSubMenuChild && (selectedSubMenuChild.displayName !== "All")){
                name = `${selectedSubMenuChild.displayName}-${title} ${term} (${_selectedYears.join(',')})`;
            }
        }
        const maxLenght =name.length >60 ?true :false
        Highcharts.charts.map((chart) => {
            if(chart) {   
                if(chart.userOptions.title.text === options.title.text){
                    chart.exportChart({
                        type: type,
                        filename: name,
                    },
                    {   
                        legend:{

                            maxHeight:370,
                            itemStyle: {
                                width:200,
                                fontSize:'10px'
                            },
                            y:!excess ?maxLenght? 50:20:0
                            
                        },
                        title: {
                            text:name,
                            style: {
                                display: 'block'
                            }
                        } ,
                        subtitle: {
                            text: ''
                        },
                    });
                }
            }
            return '';
       })
    }
    
    render(){
        Highcharts.setOptions ({
            colors : ['#8d90ff',
            '#8e2d5a',
            '#8a6e3d',
            '#c5fffe',
            '#0c48bb',
            '#d8696a',
            '#000464',
            '#d9bd05',
            '#c64690',
            '#ff7522',
            '#9d8189',
            '#008c95',
            '#ffadeb',
            '#75e4b3',
            '#ffbfb5'],
            lang: {
                drillUpText: '',
                decimalPoint: '.',
                thousandsSe0p: ',',
            }       
        });
        HC_exporting(Highcharts)
        drilldown(Highcharts)

        const {
            type,
            title,
            viewType,
            chartData, 
            listNames,
            exdcludeOptions,
            footnotes,
            onClickOption,
            percentage,
            stackTitle,
            excessData,
            onDrilldown,
            drilldownData = [], 
            modifiedDrilldownData = []
        }=this.props        
        const seriesData = modifiedDrilldownData.length >  0 ? modifiedDrilldownData : chartData 
        // const consthasMaxCharLimit =((seriesData && seriesData.find(({ name }) => name.length > 80))||(seriesData && (seriesData.length > 20) ))
        //     ? true 
        //     : false;
        // const maxLenght = ((seriesData && seriesData.find(({ name }) => name.length > 80))&&(seriesData &&( seriesData.length > 2) )) ?true :false
        const excess = (seriesData && (seriesData.length > 20) )
        const options = {
            chart: {
                type: "pie",
                // height:maxLenght ||excess ? 400:300,
                // marginLeft:consthasMaxCharLimit? 0 : 250,
                height:excess ? 400:300,
                marginLeft:excess? 0 : 250,
                events: {
                    drilldown: (e) => {
                        if (type === 'comparison') {
                            if(e.seriesOptions){                                
                                onDrilldown(e);
                            }
                        }
                        else{
                            if(e.seriesOptions){                                
                                onDrilldown(e);
                            }
                            else{
                                const seriesOptions={
                                    type:e.type,
                                    seriesOptions:drilldownData.find(drillDown => drillDown.id === e.point.drilldown)
                                }
                                onDrilldown(seriesOptions)
                            }
                        }
                    },
                    drillup: (e) => { 
                        e.preventDefault()
                        if (type === 'comparison') {
                            e.target.setTitle({ text: '' }) 
                        }
                    }
                }
            },
            plotOptions: {
                pie: {
                    animation: true,
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        overflow:"allow",
                        crop:false,
                        format: percentage?'{point.y}%':'{point.y:,.0f}'
                    },
                    point: {
                        events: {
                            click: function() {   
                                if(this.drilldown){
                                    const seriesOptions={
                                        type:"drilldown",
                                        seriesOptions:drilldownData.find(drillDown => drillDown.id === this.drilldown)
                                    }
                                    onDrilldown(seriesOptions)
                                }                         
                                
                            }
                        },
                    },
                    showInLegend: true,
                }
            },
            title: {
                text: title,
                style: {
                    display: 'none'
                }  
            },
            subtitle: {
                text: stackTitle? stackTitle :'',
                style: {
                    fontSize: '18',
                    color: '#333'
                } 
            },
            exporting: {
                enabled: false,
                
            },
            credits: {
                enabled: false
            },
            yAxis: {
                title: {
                    text: ""
                },
            },
            legend: this.legends(seriesData),
    
            xAxis: {
                type: 'category'
            },
            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 500
                    },
                    chartOptions: {
                        chart: {
                            marginLeft:0,
                            height:350
                        },
                        legend: {
                            align: 'center',
                            verticalAlign: 'bottom',
                            layout: 'horizontal',
                            width:null,
                            symbolHeight:10
                        },
    
                    }
                }]
            },
            tooltip: {
                useHTML: true,
                shared: true,
                outside:true,
                distance:60,
                followPointer:true,
                formatter: function () {
                    if(type === 'comparison'){
                        let tool=`<div style="padding-bottom:7px ;font-size:12px">${this.point && this.point.name}</div>`;
                        this.point && this.point.tooltip && this.point.tooltip.map((value)=>{
                            tool = tool+ `<div style="display:flex ; justify-content:space-between; padding:0 3px">
                                <div style="color:#000;text-aling=right ">${value.child}:</div>
                                <span style="color:#000;text-aling=right;padding-left:25px"><b>${value.value}</b></span>
                            </div>`
                            return ''
                        })
                        return tool
                    }
                    else {
                        const y =this.point && this.point.y.toLocaleString() 
                        const yLabel =percentage? `${y}%`:y
                        return `<td style="color:#000;text-aling=center ;padding:15px"><b>${yLabel}</b></td>`
                    }
                },
            },
            series: [
                {
                    colorByPoint: true,
                    data:seriesData
                }
            ],
            drilldown: {
                activeAxisLabelStyle: {
                    textDecoration:modifiedDrilldownData.length >  0 ? "none": "underLine"
                    
                },
                activeDataLabelStyle: {
                    textDecoration: modifiedDrilldownData.length >  0 ? "none": "underLine",
                },
                drillUpButton: {
                    position: {
                        y: 0,
                        x: 50
                    },
                },
                series: [...drilldownData]
            },
            enabled: true
        };
        const downloadClass= excessData ? 'excess-download-chart' : ''
        return (
            <div className = "application-pie-chart">
                {
                    seriesData && seriesData.length > 0 
                    ?   <React.Fragment>
                            <div className= "highchart-container" >
                                <HighchartsReact highcharts={Highcharts} options={options} />
                            </div>
                           
                        </React.Fragment>
                       
                    :   <div className = 'no-results'> No Data Available </div>
                }
                {
                    seriesData && seriesData.length > 0 && 
                    <div className= "chart-option-container"> 
                        <div className={`icon-download download-chart ${downloadClass}`}>
                            <ApplicationListSection 
                                viewType={viewType} 
                                listNames={listNames} 
                                onClickOption={({displayName})=>this.onDownload(displayName,options,seriesData)} />
                        </div>
                        <ApplicationChartOptions
                            listNames={listNames}
                            title={title}
                            footnotes={footnotes}
                            exdcludeOptions={exdcludeOptions}
                            onClickOption={onClickOption} />
                    </div>
                }
                
                {
                    (modifiedDrilldownData.length >0  ) &&
                    <span className="back-button" onClick={e=> this.drillup()}> &lt; Back </span>
                }
            </div>
        );
    }
}

