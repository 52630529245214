import React from 'react';
import './index.scss';

export default function ApplicationVerticalScroller({ children, className = null }) {
    return (
        <div className = {className ? className : "application-vertical-scrollbar"}>
            <div>
                {children}
            </div>
        </div>
    )
}