import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import { onWebsiteSettingsLoaded } from './store/dispatchers';

const getSettingsDetails = gql`
  query getSettingsForWebsite(
    $isPreview: Boolean
  ) {
    getSettingsForWebsite(
      isPreview: $isPreview
    ) {
      menu {
        id,
        name,
        displayName,
        children{
          id,
          name,
          displayName,
          children{
            id,
            name,
            displayName
          }
        }
      },
      countyList {
        countyId,
        displayName
      },
      districtList {
        districtId,
        displayName,
        countyList {
          countyId,
          displayName
        }
      },
      circuitList{
        circuitId,
        displayName,
        countyList {
          countyId,
          displayName
        }
      },
      jurisdictionList {
        jurisdictionId,
        displayName,
        countyList {
          countyId,
          displayName
        }
      } ,
      filterList {
        displayName,
        type,
        categoryId,
        categoryName,
        viewTypeId,
        viewTypeName,
        optionList {
          optionId,
          key,
          displayName
        }
      },
      viewTypeList {
        viewTypeId,
        displayName
      }
    }
  }`;

const SettingsGetQuery = ({
  client,
  loadingWebsiteSettings,
  onWebsiteSettingsLoaded,
  isPreviewMode = false
}) => {
  if (loadingWebsiteSettings === true) {
    client.query({
      query: getSettingsDetails,
      variables: {
        isPreview: isPreviewMode
      }
    })
      .then(details => {
        onWebsiteSettingsLoaded(JSON.parse(JSON.stringify(details.data.getSettingsForWebsite)));
      });
  }
  return '';
};

const mapStateWithProps = ({ setting }) => {
  return {
    loadingWebsiteSettings: setting.loadingWebsiteSettings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onWebsiteSettingsLoaded(details) {
      dispatch(onWebsiteSettingsLoaded(details));
    }
  };
};

export default connect(mapStateWithProps, mapDispatchToProps)(withApollo(SettingsGetQuery));