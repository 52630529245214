import React, { Component } from 'react';
import './index.scss';

export default class HomeMobileDropdownMenuList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        }
        this.onDetailsMenuChange = this.onDetailsMenuChange.bind(this)
    }
    onToggleMenu = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
    onDetailsMenuChange(id){
        const { setSelectedCategory } = this.props;
        setSelectedCategory(id)
        this.onToggleMenu()
    }
    render() {
        const { isOpen } = this.state;
        const { menuList, selectedCategoryId } = this.props;
        const menuTitle = menuList.find(({id}) => id === selectedCategoryId)
        return (
            <React.Fragment>
                <div className = 'Mobile-Dropdown-Menu' onClick = {e => this.onToggleMenu()}> 
                    <div className = "Mobile-Dropdown-Menu__title"> 
                        {menuTitle.displayName} 
                        {
                            isOpen 
                            ? <span className = "icon-chevron-up up-arrow"></span>
                            : <span className = "icon-chevron-down down-arrow"></span> 
                        }
                    </div>
                </div>
                {
                    isOpen ?
                        <div className = 'Mobile-Dropdowns'>
                            {
                                menuList.map(({displayName,id},index)=>{
                                    return (
                                        <div key = {index} 
                                            className = 'Mobile-Dropdown-Menus' 
                                            onClick = {()=> {this.onDetailsMenuChange(id)}}>
                                            {displayName}
                                        </div>
                                    );
                                })
                            }
                        </div>
                    : ''
                }
            </React.Fragment>
        )
    }
}


