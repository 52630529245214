import React from "react";
import Highcharts   from "highcharts";
import proj4 from "proj4";
import mapDataIE from "@highcharts/map-collection/countries/us/us-md-all.geo.json";
import HighchartsReact from "highcharts-react-official";
import highchartsMap from "highcharts/modules/map";
import './index.scss'

highchartsMap(Highcharts); 

export default function ApplicationMapChart({detailBox,chartData}) {
    if (typeof window !== "undefined") {
        window.proj4 = window.proj4 || proj4;
    }
   
    const options = {  
        
        chart: {
            map: "countries/us/us-md-all",
            backgroundColor: null,
            height : 600
        },
        legend: {
            enabled: false,
        },
        title: {
            text: ''
        },
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 900
                },
                chartOptions: {
                    chart: {
                        height: 500
                    },
                }
            },{
                condition: {
                    maxWidth: 768
                },
                chartOptions: {
                    chart: {
                        height: 300,
                    },
                }
            }]
        },
        mapNavigation: {
            enabled: false
        },
        colorAxis: {
            min: 0
        },
        credits: {
            enabled: false
        },
        exporting: { enabled: false },
        series: [{
            data: chartData,
            mapData:mapDataIE,
            states: {
                
            },
            dataLabels: {
                enabled: true,
                style: {
                    
                    fill:'white',
                    color:'white',
                    textTransform:'Uppercase',
                    textShadow :false,
                    fontFamily: 'Open Sans, sans-serif ',
                    fontSize:  '9px'
                },
                y : -5,
                x : 1,
                format: '{point.displayName}'
            },
            point: {
                events: {
                    click: function (e) {
                        detailBox(e.point.displayName,e.point.countyId)
                    }
                }
            },
            
        }], 
        
        tooltip: {
            enabled : true,
            style:{
                // color:'#ffffff'
            },
            borderWidth : 0,
            // backgroundColor:'rgba(0,0,0,0.75)',

            formatter: function() {
                const filings =this.point.totalFilings ? this.point.totalFilings : '-'
                const dispositions = this.point.totalDispositions ?this.point.totalDispositions : '-'
                var s = this.point.displayName + '<br/>';
                s += 'Filings: ' + parseInt(filings).toLocaleString()  + '<br/>';
                s += 'Dispositions: ' + parseInt(dispositions).toLocaleString() ;
                return s;
            },
        }
    }
    return (
        <div className='map-chart'>
            <HighchartsReact  highcharts = {Highcharts} options = {options}
            constructorType = { 'mapChart' }/>
        </div>
        
    )
}