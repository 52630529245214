import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { onDashboardDataLoaded, resetActivities } from '../Dashboard/store/dispatchers';
import gql from 'graphql-tag';

const getFilingsByFiltersQuery = gql`
    query GetFilingsByFilters (
        $categoryId: Int!,
        $valueId: Int,
        $fiscalYear: Int,
        $fiscalYears: [Int],
        $months:[String]
        $comparisonBy: String,
        $viewTypeId: Int!,
        $countyIds: [Int],
        $filingBy: String,
        $selectBy: String,
        $monthId: Int,
        $previewId: Int
    ) {
        getFilingsByFilters(
            filters: {
                months:$months,
                valueId: $valueId,
                monthId: $monthId,
                filingBy: $filingBy,
                selectBy: $selectBy,
                previewId: $previewId,
                countyIds: $countyIds,
                viewTypeId: $viewTypeId,
                categoryId: $categoryId,
                fiscalYear: $fiscalYear,
                fiscalYears: $fiscalYears,
                comparisonBy: $comparisonBy
            }
        ) {
            categoryId,
            categoryDisplayName,
            valueId,
            valueDisplayName,
            variableList {
                variableName,
                variableId,
                displayName,
                variableName,
                footnotes,
                values {
                    valueId,
                    displayName,
                    value,
                    column1,
                    column2,
                    column3,
                    highlightRow,
                    children {
                        valueId,
                        displayName,
                        value,
                        countyList{
                            countyId,
                            displayName,
                            value
                        }
                    },
                    countyList{
                        countyId,
                        displayName,
                        value
                    }
                },
                    stackList {
                        displayName,
                        value,
                        children {
                          valueId,
                          displayName,
                          value
                        }
                        stackList {
                            displayName,
                            value,
                            children {
                                valueId,
                                displayName,
                                value
                            }
                        }
                    }
                }
        }
    }
`

const FilingsByFiltersGetQuery = ({
    client,
    selectedMenu,
    selectedSubMenu,
    previewId,
    viewTypeId,
    selectedFilters,
    resetActivities,
    selectedSubMenuChild,
    dashboardFiltersList,
    settingsDetailsLoaded,
    onDashboardDataLoaded,
    loadingDashboardDetails,
}) => {
    function getSelectedValues(filterType, options, selectedFilters) {
        let value;
        switch (filterType) {
            case 'fiscalYear':
                value = parseInt(selectedFilters.find((filter) => filter.type === 'fiscalYear').value[0]);
                return { type: 'fiscalYear', value: value ? value : null };
            case 'caseType':
                const caseTypeValue = selectedFilters && selectedFilters.find((filter) => filter.type === 'caseType').value[0];
                value = options.find((value) => value.displayName === caseTypeValue).optionId;
                return { type: filterType, value: value ? value : null };
            case 'county':
                const countyValues = selectedFilters && selectedFilters.find((filter) => filter.type === 'county').value;
                value = countyValues && countyValues.map((counties) => counties.countyId);
                return { type: filterType, value: value }
            case 'fiscalYears':
                const fiscalYearsValues = selectedFilters && selectedFilters.find((filter) => filter.type === 'fiscalYears').value;
                value = fiscalYearsValues && fiscalYearsValues.map((years) => parseInt(years.value));
                return { type: filterType, value: value }
            case 'months':
                const monthsValues = selectedFilters && selectedFilters.find((filter) => filter.type === 'months').value;
                value = monthsValues && monthsValues.map((months) => (months.value));
                return { type: filterType, value: value }
            case 'comparisonBy':
                value = selectedFilters.find((filter) => filter.type === 'comparisonBy').value[0];
                return { type: filterType, value: value ? value.toLowerCase() : null };
            case 'program':
                const programValue = selectedFilters && selectedFilters.find((filter) => filter.type === 'program').value[0];
                value = options.find((value) => value.displayName === programValue).optionId;
                return { type: filterType, value: value ? value : null };
            case 'filingBy':
                value = selectedFilters && selectedFilters.find((filter) => filter.type === 'filingBy').value[0];
                return { type: filterType, value: value ? value.toLowerCase() : null };
            case 'selectBy':
                value = selectedFilters && selectedFilters.find((filter) => filter.type === 'selectBy').value[0];
                return { type: 'selectBy', value: value ? value.toLowerCase() : null };
            case 'month':
                const selectedMonth = selectedFilters && selectedFilters.find((filter) => filter.type.toLowerCase() === 'month').value[0];
                value = options.find((filter) => filter.key.toLowerCase() === selectedMonth.toLowerCase()).optionId;
                return { type: 'month', value: value ? value : null };
            default:
                return null
        }

    }
    if (settingsDetailsLoaded === true && loadingDashboardDetails === true) {
        resetActivities();
        const selectedValues = dashboardFiltersList.reduce((accum, filter) => {
            const selectedFiltersValues = getSelectedValues(filter.type, filter.optionList, selectedFilters);
            if (selectedFiltersValues) {
                accum[selectedFiltersValues.type] = selectedFiltersValues.type ? selectedFiltersValues.value : null;
            }
            return accum;
        }, []);
        let months = selectedValues.months;
        let countyIds = selectedValues.county && selectedValues.county.length === 24 ? [] : selectedValues.county;
        let selectBy = selectedValues.selectBy;
        let filingBy = selectedValues.filingBy;
        let fiscalYear = selectedValues.fiscalYear;
        let fiscalYears = selectedValues.fiscalYears;
        let comparisonBy = selectedValues.comparisonBy;
        let monthId = selectBy === "month" ? selectedValues.month : null;
        let valueId = selectedValues.caseType || selectedValues.program || null;

        let categoryId = null;
        if (selectedSubMenuChild && selectedSubMenuChild.id) {
            categoryId = selectedSubMenuChild.id
        } else if (selectedSubMenu && selectedSubMenu.id) {
            categoryId = selectedSubMenu.id;
        } else {
            if (selectedMenu && selectedMenu.children && selectedMenu.children.length > 0) {
                categoryId = selectedMenu.children[0].id;
            }
        }

        client.query({
            query: getFilingsByFiltersQuery,
            variables: {
                categoryId,
                viewTypeId,
                valueId,
                fiscalYear,
                fiscalYears,
                months,
                countyIds,
                monthId,
                previewId,
                filingBy,
                comparisonBy
            }
        })
            .then(details => {
                onDashboardDataLoaded(details.data.getFilingsByFilters);
            });
    }
    return '';
};

const mapStateWithProps = ({ setting, dashboard, preview }) => {
    return {
        previewId: preview.previewId,
        selectedMenu: dashboard.selectedMenu,
        selectedSubMenu: dashboard.selectedSubMenu,
        selectedSubMenuChild: dashboard.selectedSubMenuChild,
        selectedFilters: dashboard.selectedFilters,
        settingsDetailsLoaded: setting.settingsDetailsLoaded,
        dashboardFiltersList: dashboard.dashboardFiltersList,
        loadingDashboardDetails: dashboard.loadingDashboardDetails,
        viewTypeId: dashboard.selectedDashboardViewOption.viewTypeId
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onDashboardDataLoaded(data = null) {
            dispatch(onDashboardDataLoaded(data))
        },
        resetActivities() {
            dispatch(resetActivities())
        }
    };
};

export default connect(mapStateWithProps, mapDispatchToProps)(withApollo(FilingsByFiltersGetQuery));