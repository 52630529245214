import React, { Component } from 'react';
import { connect } from 'react-redux';
import MobileView from './mobileView';
import DesktopView from './desktopView';
import { onFiscalYearChange } from '../../store/dispatchers';
import { closeModal } from '../../../../helpers/ApplicationModal';
import { setCountiesAndJurisdictionValues } from '../../../Dashboard/store/dispatchers';
import ApplicationDeviceIdentifier from '../../../../helpers/ApplicationDeviceIdentifier';
import './index.scss';

class Filings extends Component {
    constructor(props) {
        super(props)
        this.state = {
            title: '',
            stateInfoModal: false
        }
        this.openModal = this.openModal.bind(this)
        this._closeModal = this._closeModal.bind(this)
        this.onViewClicked = this.onViewClicked.bind(this)
    }
    _closeModal() {
        this.setState({
            stateInfoModal: false
        })
        closeModal();
    }
    openModal(displayName, countyId) {
        let county = [];
        const { counties, setCountiesAndJurisdictionValues } = this.props;
        if (!(displayName === 'STATE WIDE')) {
            if (!countyId) {
                const _county = counties.find((county) => county.displayName === displayName)
                county.push({
                    displayName: _county.displayName,
                    countyId: _county.countyId
                })
            }
            else {
                county.push({
                    displayName: displayName,
                    countyId: countyId
                })
            }
            setCountiesAndJurisdictionValues(county);
        }
        else {
            setCountiesAndJurisdictionValues(county);
        }
        this.setState({
            stateInfoModal: true,
            title: `${displayName} County`
        })
    }
    onViewClicked(type) {
        const { history } = this.props;
        let submenuIndex;
        if (type === "District Court") {
            submenuIndex = 7
        }
        if (type === "Circuit Court") {
            submenuIndex = 8
        }
        if (type === "special-appeals") {
            submenuIndex = 9
        }
        if (type === "appeals") {
            submenuIndex = 10
        }
        closeModal();
        history.push(`/menus/4/sub-menu/${submenuIndex}/activity`)
    }
    render() {
        const { stateInfoModal, title } = this.state;
        const {
            appeals,
            counties,
            chartData,
            fiscalYearOptions,
            selectedFiscalYear,
            onFiscalYearChange,
        } = this.props;

        return (
            <React.Fragment>
                <ApplicationDeviceIdentifier>
                    <DesktopView
                        title={title}
                        appeals={appeals}
                        counties={counties}
                        chartData={chartData}
                        openModal={this.openModal}
                        closeModal={this._closeModal}
                        stateInfoModal={stateInfoModal}
                        onViewClicked={this.onViewClicked}
                        fiscalYearOptions={fiscalYearOptions}
                        onFiscalYearChange={onFiscalYearChange}
                        selectedFiscalYear={selectedFiscalYear}
                    />
                </ApplicationDeviceIdentifier>

                <ApplicationDeviceIdentifier isMobile={true}>
                    <MobileView
                        title={title}
                        appeals={appeals}
                        counties={counties}
                        openModal={this.openModal}
                        closeModal={this._closeModal}
                        stateInfoModal={stateInfoModal}
                        onViewClicked={this.onViewClicked}
                        onFiscalYearChange={onFiscalYearChange}
                        selectedFiscalYear={selectedFiscalYear}
                        fiscalYearOptions={fiscalYearOptions}
                    />
                </ApplicationDeviceIdentifier>
            </React.Fragment>
        )
    }
}

const mapStateToProps = ({ home }) => {
    return {
        chartData: home.mapChartData,
        appeals: home.filings.appeals,
        counties: home.filings.counties,
        fiscalYearOptions: home.filings.fiscalYears,
        selectedFiscalYear: home.filings.selectedFiscalYear
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onFiscalYearChange(year) {
            dispatch(onFiscalYearChange(year))
        },
        setCountiesAndJurisdictionValues(value) {
            dispatch(setCountiesAndJurisdictionValues(value))
        }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)((Filings));