import React from "react";
import "./index.scss";

export default function ApplicationTableHeader({ tableHeader, menuName }) {
  let rows = [tableHeader];
  const secondRow = tableHeader.reduce((accumlator, row) => {
    if (row && row.cols) {
      accumlator = accumlator.concat(row && row.cols);
    }
    return accumlator;
  }, []);
  rows =
    menuName === "districtCourt" || menuName === "circuitCourt"
      ? [tableHeader, secondRow]
      : rows;
  return (
    <thead>
      {rows.map((row, rowIndex) => {
        return (
          <tr key={rowIndex}>
            {row.map((column, colIndex) => {
              return rowIndex === 0 && colIndex === 0 ? (
                <th
                  key={colIndex}
                  rowSpan={rows.length}
                  className={`application-table-header  application-table-container__table__first-column
                                                    ${
                                                      rows.length === 1
                                                        ? "application-table-header__first-single-column "
                                                        : "application-table-header__first-column"
                                                    }
                                                `}
                >
                  <div className="first-head-column">
                    {column && column.value}
                  </div>
                </th>
              ) : (
                <th
                  key={colIndex}
                  colSpan={column && column.cols && column.cols.length}
                  className={` 
                                                ${
                                                  !(rowIndex === 0)
                                                    ? colIndex === 0
                                                      ? "application-table-header extra-column"
                                                      : "application-table-header  application-table-container__table__column"
                                                    : "application-table-header"
                                                }`}
                >
                  {column && column.value}
                </th>
              );
            })}
          </tr>
        );
      })}
    </thead>
  );
}
