import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ApplicationSubMenu from '../ApplicationSubMenu';
import ApplicationAccordian from '../ApplicationAccordian';
import ApplicationPageLayout from '../ApplicationPageLayout';
import ApplicationSubmenuBody from '../ApplicationSubmenuBody';
import DashboardActionDropdown from '../../screens/Dashboard/DashboardActionDropdown';
import {
    resetState,
    isOpenSubmenu,
    initializeDashboard,
} from '../../screens/Dashboard/store/dispatchers';
import './index.scss';

class ApplicationPageTitle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: this.props.length === 1 ? true : false
        }
    }
    onClick({ viewOption, menuId, subMenuId, subMenuChildId }) {
        const _viewOption = viewOption.viewOption
            ? viewOption.viewOption : viewOption.toLowerCase();
        const { selectedMenu, selectedDashboardViewOption } = this.props;

        subMenuChildId = subMenuChildId ? subMenuChildId : null;
        const selectedViewOption = _viewOption
            ? _viewOption : selectedDashboardViewOption.displayName;
        const selectedMenuId = menuId ? menuId : selectedMenu.id;
        const {
            history,
            previewId,
            resetState,
            initializeDashboard,
            previewDetailsLoaded,
        } = this.props;

        resetState();
        initializeDashboard({
            subCategoryId: subMenuId,
            categoryId: selectedMenuId,
            viewOption: selectedViewOption,
            subCategoryChildId: subMenuChildId
        });

        if (previewDetailsLoaded) {
            return history && history.push(`/preview/${previewId}/${selectedViewOption}`);
        } else {
            if (subMenuChildId) {
                return history && history.push(`/menus/${selectedMenuId}/sub-menu/${subMenuId}/children/${subMenuChildId}/${selectedViewOption}`);
            } else {
                return history && history.push(`/menus/${selectedMenuId}/sub-menu/${subMenuId}/${selectedViewOption}`);
            }
        }
    }
    onToggleMenu = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
    onClickChange({ menu, subTitle }) {
        const subMenu = subTitle ? subTitle.id : null;
        const viewOption = 'activity';
        this.onToggleMenu();
        this.onClick({
            viewOption,
            menuId: menu.id,
            subMenuId: subMenu
        });
    }

    render() {
        const { isOpen } = this.state;
        const {
            isSticky,
            menuList,
            selectedMenu,
            isOpenSubmenu,
            selectedSubMenu,
            selectedSubMenuChild,
            dashboardViewOptions,
            previewDetailsLoaded,
            previewSubCategoryId,
            selectedDashboardViewOption } = this.props;

        const selectedMenuDisplayName = selectedMenu.displayName;
        const selectedSubMenuId = selectedSubMenu
            ? selectedSubMenu.id
            : selectedMenu.children[0].id;

        const selectedSubmenuChildId = selectedSubMenuChild
            ? selectedSubMenuChild.id
            : selectedSubMenu.children.length > 0
                ? selectedSubMenu.children[0].id
                : null;
        const selectedSubMenuDisplayName = selectedSubmenuChildId
            ? `( ${selectedSubMenu.displayName} )` : ''
        const selectedSubmenuOption = selectedSubMenuChild
            ? selectedSubMenuChild.displayName
            : selectedSubMenu && selectedSubMenu.displayName
        const disableOtherSubmenus = !previewSubCategoryId ? false : true;

        return (
            <React.Fragment>
                <div className='application-page-title'>
                    <ApplicationPageLayout>
                        {
                            !isSticky
                                ? <div>
                                    <div className="application-page-title__content"
                                        onClick={this.onToggleMenu.bind(this)}>
                                        <div className="application-page-title__header">
                                            <div className='application-page-title__header__title'>
                                                {selectedMenuDisplayName} {selectedSubMenuDisplayName}
                                                {
                                                    isOpen
                                                        ? <span className="icon-chevron-up application-page-title__header__title__up-arrow"></span>
                                                        : <span className="icon-chevron-down application-page-title__header__title__down-arrow"></span>
                                                }
                                            </div>
                                            <div className='application-page-title__header__subTitle'>
                                                {selectedSubmenuOption}
                                            </div>
                                        </div>
                                        <div className="dropdown">
                                            <DashboardActionDropdown
                                                title={selectedMenu}
                                                onClick={(viewOption) => this.onClick({
                                                    menuId: selectedMenu.id,
                                                    subMenuId: selectedSubMenuId,
                                                    subMenuChildId: selectedSubmenuChildId,
                                                    viewOption: viewOption
                                                })}
                                                dashboardViewOptions={dashboardViewOptions}
                                                selectedDashboardViewOption={selectedDashboardViewOption}
                                            />
                                        </div>
                                    </div>
                                    <div className='desktop-submenu'>
                                        {
                                            menuList.map((menu, index) => {

                                                return (
                                                    (menu.displayName === selectedMenuDisplayName)
                                                        ? <ApplicationSubMenu
                                                            title={menu}
                                                            key={index}
                                                            subMeunList={menu.children}
                                                            selectedSubMenuId={selectedSubMenuId}
                                                            selectedSubmenuChildId={selectedSubmenuChildId}
                                                            onClick={(subMenuId, subMenuChildId) => this.onClick({
                                                                menuId: selectedMenu.id,
                                                                subMenuId,
                                                                subMenuChildId,
                                                                viewOption: selectedDashboardViewOption.displayName
                                                            })}
                                                            disableOtherSubmenus={disableOtherSubmenus} />
                                                        : ''
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                : <div className="application-page-title__sticky-header">
                                    <div className='desktop-submenu'>
                                        {
                                            menuList.map((menu, index) => {
                                                return (
                                                    (menu.displayName === selectedMenuDisplayName)
                                                        ? <ApplicationSubMenu
                                                            title={menu}
                                                            key={index}
                                                            subMeunList={menu.children}
                                                            selectedSubMenuId={selectedSubMenuId}
                                                            disableOtherSubmenus={disableOtherSubmenus}
                                                            selectedSubmenuChildId={selectedSubmenuChildId}
                                                            onClick={(subMenuId, subMenuChildId) => this.onClick({
                                                                menuId: selectedMenu.id,
                                                                subMenuId,
                                                                subMenuChildId,
                                                                viewOption: selectedDashboardViewOption.displayName
                                                            })} />
                                                        : ''
                                                )
                                            })
                                        }
                                    </div>
                                    <div className="dropdown">
                                        <DashboardActionDropdown
                                            title={selectedMenu}
                                            onClick={viewOption => this.onClick({
                                                menuId: selectedMenu.id,
                                                subMenuId: selectedSubMenu.id,
                                                viewOption
                                            })}
                                            dashboardViewOptions={dashboardViewOptions}
                                            selectedDashboardViewOption={selectedDashboardViewOption}
                                        />
                                    </div>
                                </div>
                        }
                    </ApplicationPageLayout>
                    <div className='application-page-menu'>
                        {
                            isOpen
                                ? <div className="mobile-submenu">
                                    {
                                        menuList && menuList.map((menu, index) => {
                                            const className = "";
                                            return (
                                                menu.children && menu.children.length > 0 ?
                                                    <div key={index}>
                                                        <div key={index}
                                                            className={previewDetailsLoaded && !menu.isSelected
                                                                ? 'mobile-submenu__options disabled' : 'mobile-submenu__options'}>
                                                            {
                                                                menu.isSelected ? <span className="selected"></span> : ''
                                                            }
                                                            <ApplicationAccordian
                                                                title={menu}
                                                                className={className}
                                                                isOpen={menu.isOpen}
                                                                isOpenSubmenu={(title) => isOpenSubmenu({ title, subMenuChild: null })}
                                                                body={
                                                                    <ApplicationSubmenuBody
                                                                        title={menu}
                                                                        child={menu.children}
                                                                        SubTitle={selectedSubMenu}
                                                                        isOpenSubmenu={isOpenSubmenu}
                                                                        onClick={this.onClick.bind(this)}
                                                                        subMenuChild={selectedSubMenuChild}
                                                                        previewSubCategoryId={previewSubCategoryId}
                                                                        previewDetailsLoaded={previewDetailsLoaded}
                                                                        dashboardViewOptions={dashboardViewOptions}
                                                                    />
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    :
                                                    <div key={index}
                                                        className={
                                                            previewDetailsLoaded
                                                                ? 'mobile-submenu__options disabled' : 'mobile-submenu__options'}>
                                                        {
                                                            menu.isSelected
                                                                ? <span className="selected"></span>
                                                                : ''
                                                        }
                                                        <div className={`accordian `}
                                                            onClick={e => this.onClickChange({ menu: menu, subTitle: menu.children })}>
                                                            <div className={`accordian__header`}>
                                                                {menu.displayName}
                                                            </div>
                                                        </div>
                                                    </div>
                                            )
                                        })
                                    }
                                </div>
                                : null
                        }
                    </div>
                </div>

            </React.Fragment>
        );
    };
}

const mapStateWithProps = ({ dashboard, preview }) => {
    return {
        menuList: dashboard.menuList,
        previewId: preview.previewId,
        selectedMenu: dashboard.selectedMenu,
        selectedSubMenu: dashboard.selectedSubMenu,
        previewSubCategoryId: preview.subCategoryId,
        previewDetailsLoaded: preview.previewDetailsLoaded,
        selectedSubMenuChild: dashboard.selectedSubMenuChild,
        dashboardViewOptions: dashboard.dashboardViewOptions,
        selectedDashboardViewOption: dashboard.selectedDashboardViewOption
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        resetState() {
            dispatch(resetState())
        },
        initializeDashboard(details) {
            dispatch(initializeDashboard(details));
        },
        isOpenSubmenu(values) {
            dispatch(isOpenSubmenu(values))
        }
    };
};

export default connect(mapStateWithProps, mapDispatchToProps)(withRouter(ApplicationPageTitle));

