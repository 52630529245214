import React, { Component } from 'react';
import { connect } from 'react-redux';
import Filings from '../components/filings';
import { setSelectedCategory } from '../store/dispatchers';
import AccessToJustice from '../components/accessToJustice';
import LanguageService from '../components/languageService';
import CommissionerData from '../components/commissionerData';
import HomeMobileDropdownMenuList from '../HomeMobileDropdownMenuList';
import ApplicationPageLayout from '../../../helpers/ApplicationPageLayout';
import './index.scss';

class HomeDetailsSection extends Component {
    getSelectedCategoryComponent(selectedCategoryId, history){
        const { menuList } = this.props;
        const selectedCategory = menuList.find(({id})=> id === selectedCategoryId)
        switch (selectedCategory.displayName) {
            case 'Filings': return <Filings history = {history}/>
            case 'Language Service': return <LanguageService history = {history}/>
            case 'Access To Justice': return <AccessToJustice history = {history}/>
            case 'Commissioner Data': return <CommissionerData history = {history}/>
            default:
                break;
        }
    }
    render() {
        const { 
            history,
            menuList, 
            selectedCategoryId, 
            setSelectedCategory
        } = this.props;

        return (
            <ApplicationPageLayout>
                <div className = 'home-desktop-view'> 
                   <ul>
                    {
                        menuList.map((menu, index) => {
                            return (
                                <li key = {index} 
                                className = {menu.id === selectedCategoryId ? "menu menu-active" : "menu"} 
                                onClick = {_ => setSelectedCategory(menu.id)}>
                                    {menu.displayName}
                                </li>
                            )
                        })
                    }
                    </ul>
                </div>
                <HomeMobileDropdownMenuList 
                    menuList = {menuList} 
                    selectedCategoryId = {selectedCategoryId}
                    setSelectedCategory = {setSelectedCategory} />
                {
                   this.getSelectedCategoryComponent(selectedCategoryId,history) 
                }
            </ApplicationPageLayout>
        )
    }
}
const mapStateToProps = ({ home }) => {
    return {
        menuList: home.categoryMenuList,
        selectedCategoryId: home.selectedCategoryId,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setSelectedCategory(id){
            dispatch(setSelectedCategory(id))
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeDetailsSection);
