import React from 'react';
import { connect } from 'react-redux';
import DashboardModalCountyContent from './DashboardModalCountyContent';
import { onDashboardDistrictTypeFilterChange } from '../store/dispatchers';
import DashboardModalFiltersSection from '../DashboardModalFiltersSection';
import DashboardDistrictTypeFilters from '../DashboardDistrictTypeFilters';
import DashboardModalFilterSubSection from '../DashboardModalFilterSubSection';
import DashboardModalCountyContentBody from './DashboardModalCountyContentBody';
import ApplicationModal, { closeModal } from '../../../helpers/ApplicationModal';
import DashboardModalCountyContentFooter from './DashboardModalCountyContentFooter';
import DashboardModalCountyContentHeader from './DashboardModalCountyContentHeader';
import ApplicationHorizontalScroller from '../../../helpers/ApplicationHorizontalScroller';
import './index.scss';

class DashboardCountiesSelectionModal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            filters: [],
            disabled: false,
            states: [],
            isAllOptionsSelected: false,
            selectedStateValue: []
        };
        this.selectedStateValue = []
        this.onClose = props.onClose;
        this.onModalClose = this.onModalClose.bind(this);
        this.onModalClear = this.onModalClear.bind(this);
        this.onModalApply = this.onModalApply.bind(this);
        this.onChangeFilterOption = this.onChangeFilterOption.bind(this);
        this.selectDiselectOptionsButton = this.selectDiselectOptionsButton.bind(this);
        this.onDashboardChangeDistrictOption = this.props.onDashboardChangeDistrictOption;
    }
    _getCountiesName(counties) {
        return counties.map(({ displayName }) => displayName);
    }
    getFormatedCountyList(counties) {
        const _counties = [...counties]
        const sortedCounties = this.sortingOfcounties(_counties);
        const formattedCounties = []
        const formatedStates = [];
        let index = 0;
        let count = 0;
        sortedCounties && sortedCounties.map((value) => {
            if ((value.displayName !== "Not Reported") && (value.displayName !== "Out of State")) {
                if (formattedCounties.length === 0) {
                    formattedCounties.push({
                        displayName: value.displayName[0].toUpperCase(),
                        countyList: [value]
                    })
                    count++;
                }
                else {
                    if (count === 5) {
                        formattedCounties.push({
                            displayName: value.displayName[0].toUpperCase(),
                            countyList: [value]
                        })
                        index++;
                        count = 1;
                    } else {
                        formattedCounties[index].countyList.push(value);
                        count++;
                    }
                }
            }
            else {
                formatedStates.push({
                    displayName: value.displayName[0].toUpperCase(),
                    countyList: [value]
                })
            }
            return 0
        })
        return {
            counties: [...formattedCounties],
            states: formatedStates
        }
    }
    sortingOfcounties(counties) {
        return counties.sort(function (a, b) {
            var A = a.displayName.toUpperCase();
            var B = b.displayName.toUpperCase();
            if (A < B) {
                return -1;
            }
            if (A > B) {
                return 1;
            }
            return 0;
        });
    }
    _getFormattedFilters({
        filters,
        selectedFilters
    }) {
        const selectedCounties = selectedFilters.find((filter) => { return filter.type === 'county' }).value;
        const jurisdictionType = selectedFilters.find((filter) => { return filter.type === 'jurisdiction' }).value;
        // if all jurisdiction selected, it does mean all counties are selected
        const hasAllJurisdiction = jurisdictionType.find(({ value }) => value.toLowerCase() === 'all jurisdiction');
        const formattedFilters = filters.map(filter => {
            const props = { ...filter };
            const counties = props.countyList.map(({ displayName }) => displayName);
            if (hasAllJurisdiction) {
                // make all counties selected
                return { ...props, selectedOptions: this._getCountiesName(filter.countyList) }
            } else {
                // map counties from district from selectedCounties
                const selectedOptions = counties.reduce((accumlator, county) => {
                    const foundCounty = selectedCounties.find(({ displayName }) => {
                        return county === displayName;
                    });
                    if (foundCounty) {
                        accumlator.push(county);
                    }
                    return accumlator;
                }, []);
                return { ...props, selectedOptions };
            }
        });
        return {
            isAllOptionsSelected: hasAllJurisdiction,
            formattedFilters
        };
    }
    _formatSelectedOptions(selectedOptions) {
        return selectedOptions && selectedOptions.map(({ value }) => value);
    }
    onChangeFilterOption(sectionName, option) {
        let arr = [];
        // sections are in format [ [{},  {}], [{}, {}, {}] ]
        let { filters: sections } = this.state;
        const { modifiedFilters, isAllOptionsSelected } = sections.reduce((accumlator, section) => {
            const { modifiedSections, isAllOptionsSelected } = section.reduce((accumlator, filter) => {
                // copy the object since map is mutable
                const _filter = { ...filter };
                if (_filter.displayName === sectionName) {
                    _filter.selectedOptions = this._formatSelectedOptions(option.value.selectedOptions);
                }
                // if any of the value is unselected then isAllOptionsSelected becomes false
                // we have use '<' operator since the headercheckbox is also added in the list
                // e.g. 1st Judicial court option will also get added in the list
                // so (headercheckbox)  +  (counties belonging to it)

                const isAllOptionsSelected = _filter.selectedOptions.length < _filter.countyList.length
                    ? false
                    : accumlator.isAllOptionsSelected;

                accumlator.modifiedSections.push(_filter);
                accumlator.isAllOptionsSelected = isAllOptionsSelected;
                return accumlator;
            }, {
                modifiedSections: [],
                isAllOptionsSelected: accumlator.isAllOptionsSelected
            });
            accumlator.modifiedFilters.push(modifiedSections);
            accumlator.isAllOptionsSelected = isAllOptionsSelected;
            return accumlator;
        }, {
            isAllOptionsSelected: true,
            modifiedFilters: []
        });

        //for getting the total counties selected length
        const _totalSelectedCounties = modifiedFilters.map((section) => {
            return section.reduce((accumlator, selectedValues) => {
                accumlator.push(...selectedValues.selectedOptions)
                return accumlator
            }, [])
        })
        const _modalSelectedCountiesLength = arr.concat(..._totalSelectedCounties);
        const _disabled = _modalSelectedCountiesLength.length === 0 ? true : false;

        this.setState({
            filters: modifiedFilters,
            disabled: _disabled,
            isAllOptionsSelected
        });
    }
    onModalClose() {
        closeModal();
        this.onClose();
    }
    onModalApply() {
        // send all selected counties
        // loop through all sections and make one counties object
        // by concatenating all counties from a section
        const { filters } = this.state;
        const { onDashboardDistrictTypeFilterChange } = this.props;
        let selectedCounties = filters.reduce((accumlator, section) => {
            const selectedCounties = section.map(({ countyList, selectedOptions }) => {
                // we just have option name in selectedOptions
                // we need countyId and name of the county both
                // we are fetching this information from countyList by mapping selectedOptions with it
                return selectedOptions.reduce((selectedCounties, option) => {
                    const selectedCounty = countyList.find(county => county.displayName === option);
                    // here we might get header checkbox value as well
                    // as it won't be found in the county list, we need to exclude them
                    if (selectedCounty) {
                        selectedCounties.push(selectedCounty);
                    }
                    return selectedCounties;
                }, []);
            });
            accumlator = accumlator.concat(...selectedCounties);
            return accumlator;
        }, []);
        onDashboardDistrictTypeFilterChange(selectedCounties);
        this.onModalClose();
    }
    onModalClear() {
        // deselect all counties
        const { filters } = this.state;
        const modifiedDistrictFilters = filters.map((sections) => {
            return sections.map((filter) => ({ ...filter, selectedOptions: [] }));
        });
        this.setState({
            filters: modifiedDistrictFilters,
            disabled: true,
            isAllOptionsSelected: false
        });
    }
    selectDiselectOptionsButton() {
        // if all counties selected make them selected else deselect all counties
        const { filters } = this.state;
        const isAllOptionsSelected = !this.state.isAllOptionsSelected;
        if (isAllOptionsSelected) {
            // map through all sections and add all counties belonging to section in selectedOptions
            const modifiedDistrictFilters = filters.map((sections) => {
                return sections.map((filter) => {
                    return filter = { ...filter, selectedOptions: this._getCountiesName(filter.countyList) };
                })
            });
            // all counties selected
            this.setState({
                filters: modifiedDistrictFilters,
                disabled: false,
                isAllOptionsSelected
            });
        } else {
            // this deselect all counties and  modifies state
            this.onModalClear();
        }
    }
    componentDidMount() {
        const { counties, selectedMenu, circuit, selectedFilters, districts, dashboardFiltersList } = this.props;
        //if jurisdiction type filters are present then circuits filters will be shown inside the modal else districts
        const isJurisdictionFiltersPresent = dashboardFiltersList &&
            dashboardFiltersList.find((value) => value.type === "jurisdiction") ? true : false;
        const _counties = this.getFormatedCountyList([...counties])
        const _filters = selectedMenu.id === 2
            ? [..._counties.counties, ..._counties.states]
            : !isJurisdictionFiltersPresent ? districts : circuit;

        let { formattedFilters, isAllOptionsSelected } = this._getFormattedFilters({
            filters: _filters,
            selectedFilters
        });
        const modifiedFormattedFilters = selectedMenu.id === 2
            ? [
                [formattedFilters[0]],
                [formattedFilters[1]],
                [formattedFilters[2]],
                [formattedFilters[3]],
                [formattedFilters[4]],
                [formattedFilters[5]],
                [formattedFilters[6]]
            ]
            : !isJurisdictionFiltersPresent
                ? [
                    [formattedFilters[0], formattedFilters[1]],
                    [formattedFilters[2]],
                    [formattedFilters[3], formattedFilters[4]],
                    [formattedFilters[5], formattedFilters[6], formattedFilters[7]],
                    [formattedFilters[8], formattedFilters[9]],
                    [formattedFilters[10], formattedFilters[11]]
                ]
                : [
                    [formattedFilters[0]],
                    [formattedFilters[1]],
                    [formattedFilters[2], formattedFilters[3]],
                    [formattedFilters[4], formattedFilters[5]],
                    [formattedFilters[6], formattedFilters[7]],
                ]

        this.setState({
            filters: [...modifiedFormattedFilters],
            isAllOptionsSelected
        });
    }

    render() {
        const { selectedMenu } = this.props;
        const {
            filters,
            disabled,
            isAllOptionsSelected } = this.state;
        const showSectionHeader = selectedMenu.id === 2 ? false : true;
        return (
            <ApplicationModal
                closeOnBackdropClick={true}
                showModal={true}
                onClose={this.onModalClose}
                modalBackgroundOpacity={false}>
                <DashboardModalCountyContent>
                    <DashboardModalCountyContentHeader
                        title={selectedMenu.id === 2 ? "select locations" : "select counties"}
                        buttonName={isAllOptionsSelected ? 'deselect all' : 'select all'}
                        selectDiselectOptions={this.selectDiselectOptionsButton} />
                    <ApplicationHorizontalScroller>
                        <DashboardModalCountyContentBody>
                            {
                                filters.map((sections, index) => {
                                    return (
                                        <DashboardModalFiltersSection key={index}>
                                            {
                                                sections.map(({ displayName, countyList, selectedOptions = [] }, index) => {
                                                    if (displayName === "O" || displayName === "N") {
                                                        return ''
                                                    } else {
                                                        return (
                                                            <DashboardModalFilterSubSection key={index}
                                                                customClass={selectedMenu.id === 2
                                                                    ? 'access-to-justice-modal' : ''}>
                                                                <DashboardDistrictTypeFilters
                                                                    options={countyList}
                                                                    displayName={displayName}
                                                                    selectedOptions={selectedOptions}
                                                                    showSectionHeader={showSectionHeader}
                                                                    onChange={
                                                                        counties => this.onChangeFilterOption(displayName, counties)
                                                                    } />
                                                            </DashboardModalFilterSubSection>
                                                        );
                                                    }
                                                })
                                            }
                                        </DashboardModalFiltersSection>
                                    );
                                })
                            }
                        </DashboardModalCountyContentBody>
                        {
                            selectedMenu.id === 2
                                ? <div className="dashboard-location-option">
                                    {
                                        filters.map((sections, index) => {
                                            // conso
                                            return (

                                                <DashboardModalFiltersSection key={index}>
                                                    {
                                                        sections.map(({ displayName, countyList, selectedOptions = [] }, index) => {
                                                            if (displayName !== "O" && displayName !== "N") {

                                                                return ''
                                                            } else {

                                                                return (
                                                                    <DashboardModalFilterSubSection key={index}
                                                                        customClass={selectedMenu.id === 2
                                                                            ? 'access-to-justice-modal' : ''}>
                                                                        <DashboardDistrictTypeFilters
                                                                            options={countyList}
                                                                            displayName={displayName}
                                                                            selectedOptions={selectedOptions}
                                                                            showSectionHeader={showSectionHeader}
                                                                            onChange={
                                                                                counties => this.onChangeFilterOption(displayName, counties)
                                                                            } />
                                                                    </DashboardModalFilterSubSection>
                                                                );
                                                            }
                                                        })
                                                    }
                                                </DashboardModalFiltersSection>
                                            );
                                        })
                                    }
                                </div>

                                : null
                        }
                    </ApplicationHorizontalScroller>
                    <DashboardModalCountyContentFooter
                        disabled={disabled}
                        onCancel={this.onModalClose}
                        onClear={this.onModalClear}
                        onApply={this.onModalApply} />
                </DashboardModalCountyContent>
            </ApplicationModal>
        )
    }
}

const mapStateToProps = ({ dashboard }) => {
    return {
        circuit: dashboard.circuit,
        counties: dashboard.counties,
        districts: dashboard.districts,
        selectedMenu: dashboard.selectedMenu,
        selectedFilters: dashboard.selectedFilters,
        dashboardFiltersList: dashboard.dashboardFiltersList
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onDashboardDistrictTypeFilterChange(filters) {
            dispatch(onDashboardDistrictTypeFilterChange(filters))
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(DashboardCountiesSelectionModal);

