import React from 'react';
import ApplicationCheckBoxButtons from '../../../helpers/ApplicationCheckBoxButtons';
import './index.scss';

export default function DashboardDistrictTypeFilters({
    options,
    onChange,
    displayName,
    selectedOptions,
    showSectionHeader
}) {
    let _options = options.map(({ countyId, displayName }) => {
        return { value: displayName, name: displayName, id: countyId }
    })
    options = showSectionHeader
        ? [{ value: displayName, name: displayName }, ..._options]
        : [..._options]

    function _onChange(value) {
        onChange({
            Category: 'District Type values',
            value
        })
    }
    return (
        <div className='dashboard-district-type-filter'>
            <ApplicationCheckBoxButtons
                options={options}
                headerCheckbox={showSectionHeader ? options[0].value : null}
                showHeaderAsSection={showSectionHeader}
                selectedOptions={selectedOptions}
                onChange={value => _onChange(value)} />
        </div>
    );
}