import Actions from './actions';

export function updateHomeDetails(status, values) {
    return {
        type: Actions.ON_UPDATE_HOME_DETAILS,
        data : values,
        status: status
    }
}

export function setSelectedCategory(id){
    return {
        type: Actions.ON_UPDATE_SELECTED_CATEGORYID,
        data : id,
    }
}
///For Filings
export function onFiscalYearChange(year){
    return {
        type: Actions.ON_UPDATE_SELECTED_FISCALYEAR,
        data : year,
    }
}
///For Language Service
///For Access To Justice
///For Commissioner Data