import React, { Component } from 'react';
import ApplicationModal from '../../../../helpers/ApplicationModal';
import ApplicationOutsideClick from'../../../../helpers/ApplicationOutsideClick';
import ApplicationContentBlock from '../../../../helpers/ApplicationContentBlock';
import './index.scss';

export default class DashboradPublicAndIntialAppearanceModal extends Component {
    render() {
        const {onClose,title,modalPosition,onViewClicked} = this.props;
        return (
            <ApplicationModal showModal = {true} modalBackgroundOpacity = {true} modalPosition = {modalPosition}>
                <ApplicationOutsideClick onOutsideClick = {onClose}> 
                    <div className = "dashborad-public-appearance-modal">
                        <div>
                            <div className = "dashborad-appearance-modal">
                                <div className = "dashborad-appearance-modal__header">
                                   
                                        <span> {title} </span>
                                    
                                        <span className = "icon-cancel close" onClick = {e => onClose()}> </span>
                                    
                                </div>
                            </div>
                        </div>
                        <div className = "dashborad-public-appearance-modal__body">
                            <div className = "content">
                                <ApplicationContentBlock title = "District Court" OnClickView = {onViewClicked}
                                    content={`The District Court was created in 1970 by a constitutional amendment proposed by the 
                                    legislature in 1969 and ratified in 1970. It is the trial court of limited jurisdiction in Maryland. 
                                    The Court handles all landlord and tenant cases, and has concurrent jurisdiction with circuit courts 
                                    for other case types. The Court handles civil claims for amounts up to $30,000, motor vehicle violations,
                                    misdemeanors, certain felonies and peace and protective orders. Commissioners in the District Court 
                                    determine probable cause and establish bail and conditions of release. Commissioners also issue interim 
                                    peace and protective orders during hours when the Court is closed.`}/>
                            </div>
                            <div className = "content">
                                <ApplicationContentBlock title = "Circuit Court" OnClickView = {onViewClicked}
                                    content = {`In Maryland, each county and Baltimore City has a circuit court. Circuit courts are the 
                                    trial courts of general jurisdiction. The 162 judges handle major civil cases, serious criminal matters, 
                                    and all family matters including juvenile. Circuit court judges also decide appeals from the 
                                    District Court and certain administrative agencies. Judicial magistrates primarily hear family and 
                                    juvenile matters and make recommendations based on their findings to the judge. In addition to judicial 
                                    functions, the courts are responsible for recording the State’s land record transactions, as well as 
                                    issuing a number of business licenses. Civil marriage ceremonies are often performed by judges and clerks, 
                                    and the clerks of court also issue marriage licenses.`}/>
                            </div>
                        </div>
                    </div>
                </ApplicationOutsideClick> 
            </ApplicationModal>
        )
    }
}