import React from "react";
import youtubeimg from "../../assets/images/youtubeimg.png";
import ApplicationPageLayout from "../ApplicationPageLayout";
import mdcourtsimg from "../../assets/images/mdcourtsimg.png";
import "./index.scss";

function ApplicationFooter() {
  return (
    <div className="application-footer">
      <ApplicationPageLayout>
        <div className="application-footer__content">
          <div className="application-footer__content__text">
            <div className="application-footer__content__text__mdcourts-link">
              <span className="a">www.mdcourts.gov</span>
            </div>
            <div className="application-footer__content__text__copyright-text">
              Copyright &copy; 2016 Maryland Judiciary. All rights reserved.
            </div>
            <div className="application-footer__content__terms-text desktop-terms-text">
              <span className="a">Term of Use/Disclaimer</span>
            </div>
          </div>

          <div className="mobile-terms-text-container">
            <div className="application-footer__content__terms-text mobile-terms-text">
              <span className="a">Term of Use/Disclaimer</span>
            </div>
            <div className="application-footer__content__images">
              <div className="application-footer__content__images__youtube-img">
                <a href="https://www.youtube.com/channel/UC0W3o0c4J-UgHS_RG8nqMCQ">
                  <img src={youtubeimg} alt="youtubeimg" />
                </a>
              </div>
              <div className="application-footer__content__images__mdcourts-img">
                <a href="http://www.mdcourts.gov/rss_xml.html">
                  <img src={mdcourtsimg} alt="mdcourtsimg" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </ApplicationPageLayout>
    </div>
  );
}

export default ApplicationFooter;
