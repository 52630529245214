import React from 'react';
import HomeAppealsSection from '../HomeAppealsSection'
import './index.scss';

export default function HomeAppealsContainer({onViewClicked,appeals}) {
    return (  
        <div className = 'appeals-container'> 
            {
                appeals && appeals.map((appeal,index)=>{
                    return (
                        <HomeAppealsSection 
                            key={index}
                            title = {appeal.displayName} 
                            type = {appeal.type} 
                            onViewClicked = {onViewClicked}>
                                {appeal.contents.map((content,index)=>{
                                   return( 
                                    <p className='AppealLineBreak' key={index}>  
                                        {content}
                                    </p>)
                                })}
                        </HomeAppealsSection>
                    )
                })
            }
        </div>
    )
}