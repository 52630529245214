import React, { Component } from 'react';
import ApplicationMapChart from '../../../../../helpers/ApplicationMapChart';
import HomeAppealsContainer from '../../../shared/HomeAppealsContainer';
import HomePublicAndIntialAppearanceModal from '../../../shared/HomeCountyDetailsModal';
import HomeFiscalYearFilingsAndTerminations from '../../../shared/HomeFiscalYearFilingsAndTerminations';
import './index.scss';

export default class DesktopView extends Component {
    constructor(props) {
        super(props)
        this.state = {}
        this.getChartData = this.getChartData.bind(this)
    }
    getChartData(chartData, counties, selectedFiscalYear) {
        return chartData.reduce((acc, chartValue) => {
            const county = counties.find(({ displayName }) => displayName === chartValue.displayName)
            const selectedYear = county && county.fiscalYears.find(({ displayName }) => displayName === selectedFiscalYear.displayName)

            acc.push({
                ...chartValue,
                totalFilings: selectedYear && selectedYear.filings,
                totalDispositions: selectedYear && selectedYear.dispositions
            })

            return acc;
        }, [])
    }
    render() {
        const {
            title,
            appeals,
            counties,
            chartData,
            openModal,
            closeModal,
            onViewClicked,
            stateInfoModal,
            fiscalYearOptions,
            selectedFiscalYear,
            onFiscalYearChange
        } = this.props;

        return (
            <div className='filings-desktop-view'>
                <HomeFiscalYearFilingsAndTerminations
                    selectedYear={selectedFiscalYear}
                    fiscalYearOptions={fiscalYearOptions}
                    onFiscalYearChange={onFiscalYearChange} />
                <div className='filings-desktop-view__view'>
                    <div className='filings-desktop-view__view-section'>
                        <div className='view-section-statement'>
                            Click on a county to view its performance measures
                        </div>
                        <div className='dropdown-options'>
                            <select className='action-buttons' onChange={e => openModal(e.target.value)}>
                                <option> choose county </option>
                                {
                                    counties.map(({ displayName }, index) =>
                                        <option key={index}
                                        > {displayName} </option>)
                                }
                            </select>
                            <span> or </span>
                            <div className='action-buttons extra-side-padding' onClick={e => openModal('STATE WIDE')}>
                                view statewide data
                            </div>
                        </div>
                    </div>
                    <ApplicationMapChart
                        detailBox={openModal}
                        chartData={this.getChartData(chartData, counties, selectedFiscalYear)}
                    />
                </div>
                <HomeAppealsContainer onViewClicked={onViewClicked} appeals={appeals} />
                {
                    stateInfoModal ?
                        <div className='desktop-modal'>
                            <HomePublicAndIntialAppearanceModal
                                title={title}
                                onClose={closeModal}
                                onViewClicked={onViewClicked} />
                        </div>
                        : null
                }
            </div>
        )
    }
}


