import React from 'react';
import ApplicationRadioButtons from '../../../helpers/ApplicationRadioButtons';
import './index.scss';

export default function DashboardSelectByFilters({ 
    options,
    onClose,
    onChange,
    selectedOption = null
}) {    
    const _selectedOption = selectedOption.find((value) => value);
    options = options.map(({ displayName, optionId }) => ({ name: 'selectBy', value: displayName, id: optionId }));

    return (
        <div className = 'dashboard-selectBy-filters'>             
            <ApplicationRadioButtons 
                options = {options} 
                onClose = {onClose}
                selectedOption = {_selectedOption} 
                onChange = {onChange} />
        </div>
      
    );
}