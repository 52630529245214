import React, { Component } from 'react';
import ApplicationAccordian from '../ApplicationAccordian';
import './index.scss';

class ApplicationSubmenuBody extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSelected: false,
            selectedTitle: ''
        }
    }
    _getSelectedSubMenu(menutitle, title, parent) {
        const { onClick } = this.props;
        const subTitle = !parent ? menutitle ? menutitle.id : null : parent.id;
        const subMenuChildId = parent ? menutitle.id : null
        const viewOption = 'activity';
        onClick({
            viewOption,
            menuId: title.id,
            subMenuId: subTitle,
            subMenuChildId: subMenuChildId
        });
        this.setState({
            isSelected: true,
            selectedTitle: menutitle.displayName
        })
    }
    displaySubMenu(children, index, parent) {
        const { title, SubTitle, subMenuChild, previewDetailsLoaded } = this.props;
        const selectedId = parent ? subMenuChild && subMenuChild.id : SubTitle && SubTitle.id
        const subMenuDisable = previewDetailsLoaded
            && (!(SubTitle.id === 3))
            && (!(SubTitle.id === 18))
            ? true : false
        return (
            <div key={index}
                className={subMenuDisable
                    ? 'mobile-submenu__options disabled'
                    : 'mobile-submenu__options'}>
                {
                    selectedId === children.id ? <span className="selected"></span> : ''
                }

                {
                    <div className={`accordian `}>
                        <div className={`accordian__header`}
                            onClick={(e) => this._getSelectedSubMenu(children, title, parent)}>
                            {children.displayName}
                        </div>
                    </div>
                }
            </div>
        )
    }

    render() {
        const {
            title,
            child = [],
            isOpenSubmenu,
            previewSubCategoryId,
            previewDetailsLoaded
        } = this.props;

        return (
            <div className="application-submenu-body">
                {

                    child.map((children, index) => {
                        const className = "mobile-submenu__options";
                        if (children.children.length === 0) {
                            return this.displaySubMenu(children, index)
                        }
                        else {
                            return <div key={index} className={
                                (previewDetailsLoaded && previewSubCategoryId !== children.id)
                                    ? 'disabled' : ''}>
                                {
                                    children.isSelected ? <span className="selected" ></span> : ""
                                }
                                <ApplicationAccordian
                                    key={index}
                                    title={children}
                                    className={className}
                                    isOpen={children.isOpen}
                                    isOpenSubmenu={(child) => isOpenSubmenu({ title, subMenuChild: child })}
                                    body={
                                        <div>
                                            {
                                                children.children.map((child, index) => {
                                                    return this.displaySubMenu(child, index, children)
                                                })
                                            }
                                        </div>

                                    }
                                />
                            </div>

                        }
                    })
                }
            </div>
        )
    }
}


export default ApplicationSubmenuBody;