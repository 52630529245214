import React, { Component } from 'react';
import { connect } from 'react-redux';
import DashboardProgramFilters from '../../screens/Dashboard/DashboardProgramFilters';
import ApplicationModalMobileFilterFooter from '../ApplicationModalMobileFilterFooter';
import DashboardSelectByFilters from '../../screens/Dashboard/DashboardSelectByFilters';
import DashboardCaseTypeFilters from '../../screens/Dashboard/DashboardCaseTypeFilters';
import DashboardFilingsByFilters from '../../screens/Dashboard/DashboardFilingsByFilters';
import DashboardMonthWiseFilters from '../../screens/Dashboard/DashboardMonthWiseFilters';
import DashboardFiscalYearFilters from '../../screens/Dashboard/DashboardFiscalYearFilters';
import DashboardComparsionByFilters from '../../screens/Dashboard/DashboardComparsionByFilters';
import DashboardDistrictTypeFilters from '../../screens/Dashboard/DashboardDistrictTypeFilters';
import DashboardMultipleMonthsFilters from '../../screens/Dashboard/DashboardMultipleMonthsFilters';
import DashboardJurisdictionTypeFilters from '../../screens/Dashboard/DashboardJurisdictionTypeFilters';
import DashboardMultipleFiscalYearsFilters from '../../screens/Dashboard/DashboardMultipleFiscalYearsFilters';
import {
    onDashboardMonthFilterChange,
    onDashboardProgramFilterChange,
    onDashboardCaseTypeFilterChange,
    onDashboardSelectByFilterChange,
    onDashboardMultipleMonthsChange,
    onDashboardFilingsByFilterChange,
    onDashboardFiscalYearFilterChange,
    onDashboardDistrictTypeFilterChange,
    onDashboardComparsionByFilterChange,
    onDashboardMultipleFiscalYearsChange,
    onDashboardJurisdictionTypeFilterChange } from '../../screens/Dashboard/store/dispatchers';
import './index.scss';

class ApplicationModalMobileTabs extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            filters: [],
            disabled : false,
            selectedFiscalYearsOptions: [],
            selectedMonthsOptions :[],
            fiscalYearsDisabled : false,
            monthsDisabled : false,
            selectedFilterName: props.dashboardFiltersList[0].displayName==="Month" ? 
                props.dashboardFiltersList[1].displayName : 
                props.dashboardFiltersList[0].displayName,
            isAllOptionsSelected: false
        }
        this.onClear = this.onClear.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onTabClick = this.onTabClick.bind(this);
    }
    getFormatedCountyList(counties){
        const _counties = [...counties]
        const sortedCounties = this.sortingOfcounties(_counties)
        const fomatedCounties = []
        const formatedStates = []
        sortedCounties && sortedCounties.map((value)=> {
            if((value.displayName !== "Not Reported")&& (value.displayName !== "Out of State")){
                if(fomatedCounties.length=== 0){
                    fomatedCounties.push({
                        displayName: value.displayName[0].toUpperCase(),
                        countyList:[value]
                    })
                }
                else{
                    const foudnIndex = fomatedCounties.findIndex(({ displayName }) => displayName === value.displayName[0].toUpperCase());
                    if (foudnIndex === -1) {
                        fomatedCounties.push({
                            displayName: value.displayName[0].toUpperCase(),
                            countyList:[value]
                        })
                    } else {
                        fomatedCounties[foudnIndex].countyList.push( value );
                    }
                }
            }
            else {
                formatedStates.push({
                    displayName: value.displayName[0].toUpperCase(),
                    countyList:[value]
                })
            }
            return 0
        })
        return {
            counties: fomatedCounties,
            states: formatedStates
        }
    }
    sortingOfcounties(counties){
        return counties.sort(function(a, b) {
            var A = a.displayName.toUpperCase();
            var B = b.displayName.toUpperCase(); 
            if (A < B) {
              return -1;
            }
            if (A > B) {
              return 1;
            }
            return 0;
          });
    }
    onClear() {
        const { filters, selectedFilterName } = this.state;
        if(selectedFilterName === 'Fiscal Years') {
            this.setState({
                selectedFiscalYearsOptions: [],
                fiscalYearsDisabled: true,
            });
        } else if(selectedFilterName === 'Months') {
            this.setState({
                selectedMonthsOptions: [],
                monthsDisabled: true,
            });
        }  {
            const _modifiedFilters = filters.map((filter) => ({ ...filter, selectedOptions: [] }) );
            this.setState({
                filters: _modifiedFilters,
                disabled : true,
                isAllOptionsSelected: true
            });
        }
    }

    onSubmit() {
        const { filters } = this.state;
        let arr = [];
        const { onDashboardDistrictTypeFilterChange, onClose } = this.props;
        const selectedCounties = filters.map( ({ countyList, selectedOptions }) => {
            return selectedOptions.reduce((accumlator, option) => {
                const selectedCounty = countyList.find(county => county.displayName === option);
                if(selectedCounty) {
                    accumlator.push(selectedCounty);
                }
                return accumlator;
            }, []);
        });
        onDashboardDistrictTypeFilterChange(arr.concat(...selectedCounties));
        onClose();
    }

    _formatSelectedOptions(selectedOptions) {
        return selectedOptions.map(({ value }) => value);
    }

    onChangeFilterOption(sectionName, option) {
        if(sectionName !== 'fiscalYears' && sectionName !== 'months' ) {
            let { filters: sections } = this.state;
            const { modifiedFilters, isAllOptionsSelected } = sections.reduce((accumlator, section) => {
                const _filter = { ...section };  
                if (_filter.displayName === sectionName) {
                    _filter.selectedOptions = this._formatSelectedOptions(option.value.selectedOptions);
                }
                const isAllOptionsSelected = _filter.selectedOptions.length < _filter.countyList.length 
                ? false
                : accumlator.isAllOptionsSelected;
                accumlator.modifiedFilters.push(_filter);
                accumlator.isAllOptionsSelected = isAllOptionsSelected;
                return accumlator;
            }, {
                isAllOptionsSelected: true,
                modifiedFilters: []
            });
    
            //for getting the total selected counties length in modal
            const _totalSelectedCounties = modifiedFilters.reduce((accumlator, selectedValues) => {
                accumlator.push(...selectedValues.selectedOptions)
                return accumlator
            }, [])
            const _disabled = _totalSelectedCounties.length === 0 ? true : false;
            this.setState({
                filters: modifiedFilters,
                disabled: _disabled,
                fiscalYearsDisabled: true,
                monthsDisabled:true,
                isAllOptionsSelected
            });
        }
        else if(sectionName === 'fiscalYears') {
            const _disabled = option.selectedOptions.length === 0 ? true : false;
            this.props.onDashboardMultipleFiscalYearsChange(option)
            this.setState({
                disabled: true,
                selectedFiscalYearsOptions: option.selectedOptions,
                fiscalYearsDisabled: _disabled
            })
        }
        else if(sectionName === 'months') {
            const _disabled = option.selectedOptions.length === 0 ? true : false;
            this.props.onDashboardMultipleMonthsChange(option)
            this.setState({
                disabled: true,
                selectedMonthsOptions: option.selectedOptions,
                monthsDisabled: _disabled
            })
        }
    }

    _getCountiesName(counties) {
        return counties.map(({ displayName }) => displayName);
    }

    _getFormattedFilters({
        filters,
        selectedFilters
    }) {
        const selectedCounties = this._getSelectedFilterValue(selectedFilters, 'county');
        const jurisdictionType = this._getSelectedFilterValue(selectedFilters, 'jurisdiction');
        const hasAllJurisdiction = jurisdictionType.find(({ value }) => value.toLowerCase() === 'all jurisdiction');
        const formattedFilters = filters.map(filter => {
            const props = { ...filter };
            const counties = props.countyList.map(({ displayName }) => displayName);
            if (hasAllJurisdiction) {
                return { ...props, selectedOptions: this._getCountiesName(filter.countyList) }
            } else {
                const selectedOptions = counties.reduce((accumlator, county) => {
                    const foundCounty = selectedCounties.find(({ displayName }) => {
                        return county === displayName;
                    });
                    if (foundCounty) {
                        accumlator.push(county);
                    }
                    return accumlator;
                }, []);
                return { ...props, selectedOptions };
            }
        });
        return {
            isAllOptionsSelected:  hasAllJurisdiction,
            formattedFilters
        };
    }
    _getSelectedFilterValue(selectedFilters, type) {
        const _selectedOption = selectedFilters.find((selectedFilter) => selectedFilter.type === type);
        switch (type) {
            case 'month':
            case 'months': 
            case 'county':
            case 'program':
            case 'caseType': 
            case 'filingBy': 
            case 'selectBy':             
            case 'fiscalYear': 
            case 'fiscalYears':  
            case 'jurisdiction': 
            case 'comparisonBy': 
                return _selectedOption.value
            default : 
                return null;
        }
    }

    componentDidMount() {
        const {counties, selectedMenu, circuit, selectedFilters, districts, dashboardFiltersList} = this.props;
        const _selectedFiscalYearsOptions = this._getSelectedFilterValue(selectedFilters, 'fiscalYears');
        const _selectedMonthsOptions = this._getSelectedFilterValue(selectedFilters, 'months');

        //if jurisdiction type filters are present then circuits filters will be shown inside the modal else districts
        const isJurisdictionFiltersPresent = dashboardFiltersList && 
            dashboardFiltersList.find((value) => value.type === "jurisdiction") ? true : false;
        const _counties = this.getFormatedCountyList([...counties])
        const _filters = selectedMenu.id===2 
            ?   _counties.counties
            : !isJurisdictionFiltersPresent ? districts : circuit;
        let { formattedFilters, isAllOptionsSelected } = this._getFormattedFilters({
            filters: _filters,
            selectedFilters
        });
        
        this.setState({
            filters: formattedFilters,
            selectedFiscalYearsOptions: _selectedFiscalYearsOptions,
            selectedMonthsOptions :_selectedMonthsOptions,
            isAllOptionsSelected: isAllOptionsSelected
        })
    }
    getDashboardSelectedFilterValue(option) {
        const { onDashboardCaseTypeFilterChange, onDashboardFilingsByFilterChange } = this.props;
        if(option.name === "caseType" && option.value !== "term") {
            onDashboardCaseTypeFilterChange(option)
        } else if(option.name === "filingBy") {
            if(option.value === "term") {
                onDashboardCaseTypeFilterChange({name: "caseType", value: "All Cases"})
                onDashboardFilingsByFilterChange(option)
            } else {
                onDashboardFilingsByFilterChange(option)
            }
        }
    }

    getFilterComponent(filterType, selectedFilters, options) {
        const { filters, selectedFiscalYearsOptions ,selectedMonthsOptions } = this.state;
        const isTermSelected = selectedFilters.find((filter) => filter.type === 'filingBy').value[0] === 'term';
        const _options = this.props.subTitle.displayName === "Court Of Special Appeals" ? 
            options.filter(({key}) => key.includes('cosa')) : null
        const caseTypeOptions = _options && _options.filter(({displayName}) => displayName !== "Juvenile");
        
        switch (filterType) {
            case 'fiscalYear':
                return <DashboardFiscalYearFilters
                    options = {options}
                    onClose = {this.props.onClose}
                    onChange = {this.props.onDashboardFiscalYearFilterChange} 
                    selectedOption = {this._getSelectedFilterValue(selectedFilters, 'fiscalYear')}
                />
            case 'caseType':
                return <DashboardCaseTypeFilters
                    options = {isTermSelected ? caseTypeOptions : options}
                    onClose = {this.props.onClose}
                    onChange = {(option) => this.getDashboardSelectedFilterValue(option)}
                    selectedOption = {this._getSelectedFilterValue(selectedFilters, filterType)}
                />
            case 'program':
                return <DashboardProgramFilters
                    options = {options}
                    onClose = {this.props.onClose}
                    onChange = {this.props.onDashboardProgramFilterChange} 
                    selectedOption = {this._getSelectedFilterValue(selectedFilters, filterType)}
                />
            case 'filingBy':
                return <DashboardFilingsByFilters 
                    options = {options} 
                    onClose = {this.props.onClose}
                    onChange = {(option) => this.getDashboardSelectedFilterValue(option)}
                    selectedOption = {this._getSelectedFilterValue( selectedFilters, filterType )}
                />
            case 'county':
                return filters && filters.map(({displayName, countyList, selectedOptions = [] }, index) => {
                    return (
                        <DashboardDistrictTypeFilters 
                            key = {index}
                            options = {countyList}
                            displayName = {displayName}
                            selectedOptions = {selectedOptions}
                            onChange = { counties => this.onChangeFilterOption(displayName, counties)} 
                        />
                    )
                })
            case 'jurisdiction':
                return <DashboardJurisdictionTypeFilters
                    options = {options}
                    selectedOptions = {this._getSelectedFilterValue( selectedFilters, filterType )}
                    onChange = {this.props.onDashboardJurisdictionTypeFilterChange} 
                />
            case 'fiscalYears' :
                return <DashboardMultipleFiscalYearsFilters 
                    options = {options}
                    selectedOptions = {selectedFiscalYearsOptions}
                    onChange = { selectedFiscalYears => this.onChangeFilterOption('fiscalYears',selectedFiscalYears)} 
                />
            case 'months' :
            return <DashboardMultipleMonthsFilters 
                options = {options}
                selectedOptions = {selectedMonthsOptions}
                onChange = { selectedMonths => this.onChangeFilterOption('months',selectedMonths)} 
            />
            case 'comparisonBy': 
                return <DashboardComparsionByFilters 
                    options = {options}
                    onClose = {this.props.onClose}
                    onChange = {this.props.onDashboardComparsionByFilterChange} 
                    selectedOption = {this._getSelectedFilterValue(selectedFilters, filterType )}
                />
            case 'selectBy':
                return <DashboardSelectByFilters 
                    options = {options}
                    onClose = {this.props.onClose}
                    onChange = {this.props.onDashboardSelectByFilterChange}
                    selectedOption = {this._getSelectedFilterValue(selectedFilters, 'selectBy' )}
                />
            case "month":
                return <DashboardMonthWiseFilters 
                    options = {options}
                    onClose = {this.props.onClose}
                    onChange = {this.props.onDashboardMonthFilterChange} 
                    selectedOption = {this._getSelectedFilterValue(selectedFilters, 'month')}
                />
            default:
                return null;
        }
    }
    onTabClick(tabName) {
        this.setState({
            selectedFilterName: tabName
        });
    }
    render() {
        const { selectedFilters, dashboardFiltersList } = this.props;
        const { disabled, selectedFilterName, fiscalYearsDisabled ,monthsDisabled} = this.state;
        const isMonthSelected = this._getSelectedFilterValue(this.props.selectedFilters, 'selectBy')[0].toLowerCase() === 'month';
        const enableMonthsForComparisonView = this._getSelectedFilterValue(selectedFilters, 'comparisonBy')[0].toLowerCase() === 'month';
        let _dashboardFiltersList = isMonthSelected ? dashboardFiltersList 
            : dashboardFiltersList.filter((value) => value.displayName.toLowerCase() !== 'month');
        if(!enableMonthsForComparisonView) {
            _dashboardFiltersList = _dashboardFiltersList.filter(value => {
                return value.displayName.toLowerCase() !== 'months'
            });
            _dashboardFiltersList = _dashboardFiltersList.map(value => {
                if(value.type==="fiscalYear" &&  value.viewTypeId=== 3){
                    value.displayName="Fiscal Years";
                    value.type = "fiscalYears"
                    }
                return value
            });
        }
        if(enableMonthsForComparisonView){
            _dashboardFiltersList = _dashboardFiltersList.map(value => {
                if(value.type==="fiscalYears" &&  value.viewTypeId=== 3){
                    value.displayName="Fiscal Year";
                    value.type = "fiscalYear"
                }
                return value
            });
        }
        return (
            <React.Fragment>
                <div className = "application-modal-mobile-body">
                {
                    _dashboardFiltersList && _dashboardFiltersList.map((filter, index) => {
                        
                        return (
                            <ul className = "application-modal-mobile-body__tabs" key = {index}>
                                <li className = {
                                    filter.displayName.toLowerCase() === selectedFilterName.toLowerCase()
                                        ? "application-modal-mobile-body__tabs__list active" 
                                        : "application-modal-mobile-body__tabs__list"
                                    }
                                    onClick = {e => this.onTabClick(filter.displayName)}> 
                                    {filter.displayName}
                                </li>
                            </ul>
                        )
                    })
                }
                </div>
                <div  className = "application-modal-mobile-content"> 
                    <div className = "application-modal-mobile-filters">
                    {
                        _dashboardFiltersList && _dashboardFiltersList.map((filter, index) => {
                            const className = filter.displayName.toLowerCase() === selectedFilterName.toLowerCase() 
                                ? "tab-content display-block" : "tab-content";
                            return (
                                <div key={index} className={className}>
                                {
                                    this.getFilterComponent (
                                        filter.type, 
                                        selectedFilters, 
                                        filter.optionList
                                    )
                                }
                                </div>
                            )
                        })
                    }
                    </div>
                    
                    <ApplicationModalMobileFilterFooter 
                        disabled = {disabled}   
                        onClear = {this.onClear}
                        onSubmit = {this.onSubmit} 
                        selectedFilterName = {selectedFilterName} 
                        fiscalYearsDisabled = {fiscalYearsDisabled}
                        monthsDisabled = {monthsDisabled} />
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = ({ dashboard }) => {
    return {
        counties: dashboard.counties,
        circuit: dashboard.circuit,
        districts: dashboard.districts,
        subTitle: dashboard.selectedSubMenu,
        selectedFilters: dashboard.selectedFilters,
        selectedMenu : dashboard.selectedMenu,
        dashboardFiltersList: dashboard.dashboardFiltersList
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onDashboardFiscalYearFilterChange(option) {
            dispatch(onDashboardFiscalYearFilterChange({type: option.name , value: [option.value]}));
        },
        onDashboardProgramFilterChange(option) {
            dispatch(onDashboardProgramFilterChange({type: option.name , value: [option.value]}));
        },
        onDashboardCaseTypeFilterChange(option) {
            dispatch(onDashboardCaseTypeFilterChange({type:option.name, value:[option.value]}));
        },
        onDashboardFilingsByFilterChange(option) {
            dispatch(onDashboardFilingsByFilterChange({type:option.name, value:[option.value]}));
        },
        onDashboardComparsionByFilterChange(option) {
            dispatch(onDashboardComparsionByFilterChange({type:option.name, value:[option.value]}))
        },
        onDashboardSelectByFilterChange(option) {
            dispatch(onDashboardSelectByFilterChange({type:option.name, value:[option.value]}))
        },
        onDashboardMonthFilterChange(option) {
            dispatch(onDashboardMonthFilterChange({type:option.name, value:[option.value]}))
        },
        onDashboardDistrictTypeFilterChange(filters) {
            dispatch(onDashboardDistrictTypeFilterChange(filters))
        },
        onDashboardJurisdictionTypeFilterChange(option) {
            dispatch(onDashboardJurisdictionTypeFilterChange({type:option.name, value:option.selectedOptions, isChecked:option.isChecked}))
        }, 
        onDashboardMultipleMonthsChange(option){
            dispatch (onDashboardMultipleMonthsChange({type: option.name, value:option.selectedOptions}))
        }, 
        onDashboardMultipleFiscalYearsChange(option) {
            dispatch(onDashboardMultipleFiscalYearsChange({type:option.name, value:option.selectedOptions }))
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationModalMobileTabs);
