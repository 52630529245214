import React from 'react';
import './index.scss';

export default function accessToJusticeContainer({
    onViewClicked
}) {
    const accessToJusticeContainer = [
        {
            displayName: 'language services',
            content: `The Maryland Judiciary provides language services for court
            users with limited English proficiency.`,
            children: [
                {
                    textUppercase: true,
                    title: 'Courtroom Services (Court Interpreter Program)',
                    content: [`The Maryland Judiciary provides court interpreters 
                    for hearings and proceedings conducted in court, as well as 
                    certain court-related services and events. Interpreters are 
                    provided at no cost for individuals who are parties or witnesses 
                    in civil, criminal, and juvenile proceedings. Only court-appointed 
                    interpreters can serve as official interpreters in the courtroom.
                    The Maryland Judiciary’s Court Interpreter Program recruits, tests,
                    and prepares bilingual individuals to serve as court-appointed 
                    interpreters for in-person interpretation of court proceedings 
                    and court-related events.  ASL interpreters must be certified 
                    by the Registry of Interpreters for the Deaf (RID) in order 
                    to be listed on the Court Interpreter Registry.`]
                },
                {
                    textUppercase: true,
                    title: 'out of courtroom services (telephonic)',
                    content: [`The Maryland Judiciary uses telephonic interpretation, 
                    to provide phone-based interpreter services in all languages to 
                    assist the public at clerk’s counters, on the phone, and in some 
                    court-based services like court help centers. 
                    These services are provided by a telephonic interpretation vendor. 
                    Access to Justice provides materials including posters and desk reference cards 
                    and ongoing support and training to ensure court staff know 
                    how to use telephonic interpretation. In-person interpretation services are 
                    recommended and preferred for in-court events.`]
                }
            ]
        },
        {
            displayName: 'Maryland Court Help centers',
            content: `Maryland Court Help Centers provide free legal help in civil cases for individuals who
            do not have a lawyer. Maryland Court Help Center staff help litigants understand legal
            rights and obligations, complete court forms, learn how to file
            documents, and prepare for court hearings and mediation.`,
            children: [
                {
                    textUppercase: true,
                    title: 'Maryland Court Help Center (Remote Services)',
                    content: [`Self-represented litigants may get help via phone, live chat,
                    videoconferencing, and email. Attorneys assist with all civil case types 
                    including housing and foreclosure, family law, consumer, domestic violence, 
                    and expungement. The MCHC is open weekdays from 8:30 a.m. until 8 p.m. The
                    MCHC serves all, regardless of income. The MCHC is staffed by 
                    attorneys and support staff employed by the Maryland Center for Legal Assistance, 
                    LLC (MCLA), a wholly owned subsidiary of Maryland Legal Aid.`]
                },
                {
                    textUppercase: false,
                    title: 'FAMILY COURT HELP CENTERS (FCHCs)',
                    content: [`Family Court Help Centers (FCHCs) provide aid to self-represented
                    litigants in family law matters throughout Maryland. FCHCs are staffed by a
                    combination of contractual providers, pro bono attorneys, and court employees. 
                    FCHCs are funded via grants from the Administrative Office of the Court’s 
                    Juvenile and Family Services. Family Court Help Centers provide assistance 
                    with all family law issues including divorce, custody, domestic violence, adoption, 
                    and child support.`]
                },
                {
                    textUppercase: false,
                    title: 'District Court Help Centers (DCHCs)/All Civil Centers (Walk-in Services)',
                    content: [
                        `There are currently 10 DCHC locations where self-represented litigants may get help in civil, District Court matters, including landlord and tenant, small claims, expungement, and domestic violence.
                        There are currently two All Civil Centers -- Frederick and La Plata. The All Civil locations assist self-represented litigants with all civil matters across both District and circuit court case types.`
                    ]
                },

            ]
        }
    ];
    return (
        <React.Fragment>
            {
                accessToJusticeContainer && accessToJusticeContainer.map((data, index) => {
                    const { displayName, children, content } = data;

                    return (
                        <div className="access-to-justice-container" key={index}>
                            <h3> {displayName} </h3>
                            <h4> {content} </h4>
                            <div className="sections">
                                {
                                    children.map((child, index) => {
                                        const { title, content, textUppercase } = child;
                                        const sectionHeaderClass = textUppercase
                                            ? "sectionHeader text-uppercase"
                                            : "sectionHeader"
                                        return (
                                            <div className="sectionContainer" key={index}>
                                                <div className="sectionContainer__header">
                                                    <div className={sectionHeaderClass}>
                                                        {title}
                                                    </div>
                                                    <button className="viewReportBtn"
                                                        onClick={e => onViewClicked(title)}>
                                                        view report
                                                    </button>
                                                </div>
                                                <div className="sectionBody">
                                                    <div>
                                                        {
                                                            content.map((value, index) => {
                                                                if (value === "Frederick:" || value === "Note:") {
                                                                    return <strong key={index}> <p> {value} </p> </strong>
                                                                } else {
                                                                    return <span key={index}> {value} </span>
                                                                }
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div className="viewReportBtn-mobile">
                                                    <button className="viewReportBtn"
                                                        onClick={e => onViewClicked(title)}>
                                                        view report
                                                    </button>
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </div>
                    );
                })
            }
        </React.Fragment>
    );
}