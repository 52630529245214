import React from 'react';
import ApplicationRadioButtons from '../../../helpers/ApplicationRadioButtons';
import './index.scss';

export default function DashboardProgramFilters({ 
    options,
    onClose,
    onChange,
    selectedOption = null
}) {    
    const _selectedOption = selectedOption.find((value) => value);
    options = options.map(({ displayName, optionId }) => ({ name: 'program', value: displayName, id: optionId }));

    return (
        <div className='dashboard-program-filters'>             
            <ApplicationRadioButtons 
                options = {options} 
                onClose = {onClose}
                onChange={onChange} 
                selectedOption={_selectedOption} 
            />
        </div>
      
    );
}