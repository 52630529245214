///////////////table Data Functions//////////////////////////////////////

// map existing countyList from data into district
function _mapDistrictWithCounties(countyList, districts) {
    const _districts = countyList && countyList.reduce((accumlator, county) => {
        accumlator = districts.reduce((accumlator, district) => {
            const districtName = district.displayName;
            const districtCounty = district.countyList.find(_county => _county.displayName === county);
            if (districtCounty) {
                const foudnIndex = accumlator.findIndex(({ value }) => value === districtName);
                if (foudnIndex === -1) {
                    // district doesn't exist in accumlator
                    accumlator.push(
                        { value: districtName, districtId: district.districtId, cols: [{ value: districtCounty.displayName, cols: [] }] }
                    );
                } else {
                    // district exist in accumlator, add county
                    accumlator[foudnIndex].cols.push(
                        { value: districtCounty.displayName, cols: [] }
                    );
                }
            }
            return accumlator;
        }, accumlator);
        return accumlator;
    }, []);

    return _districts;
}
///convert data into single row for sections

////give the county header for circuit 
function _getListInCircuitForm(countyList) {
    const list = countyList && countyList.map((value) => {
        return { value: value, cols: [] }
    })
    return { value: "Counties", cols: list }
}
function _getListInSHCForm(countyList) {
    const list = countyList && countyList.map((value) => {
        return { value: value, cols: [] }
    })
    return list
}

////give totla county list
function _getCountyNames(counties) {
    return counties && counties.map(({ displayName }) => displayName);
}

function _getHeaderList(headers) {
    return headers && headers.map(({ displayName }) => {
        return { value: displayName, cols: [] }
    });
}

//////convert data into Table headers
function _getTableHeaders({
    section,
    menuName,
    districts,
    fiscalYear = null,
    unit = null
}) {
    let tableHeaders = [];
    switch (menuName) {
        case 'languageService':
        case 'initialAppearance':
        case 'publicDefenderStatistics':
            let countiesList
            if (menuName === 'languageService') {
                tableHeaders.push({ value: "Program", cols: [] })
                countiesList = _getCountyNames(section.values);
            }
            else {
                tableHeaders.push({ value: "", cols: [] });
                countiesList = _getCountyNames((section.values && section.values[0]) && (section.values[0].countyList))
            }
            const listOfHeader = _getListInSHCForm(countiesList) || [];
            tableHeaders = [...tableHeaders, ...listOfHeader];
            break;

        case 'districtCourt':
            tableHeaders.push({ value: "Case Type", cols: [{}] });
            // get all countyList
            let countyList = _getCountyNames((section.values && section.values[0]) && (section.values[0].countyList));
            const _districts = _mapDistrictWithCounties(countyList, districts);
            tableHeaders = tableHeaders.concat(_districts);
            break;

        case 'circuitCourt':
        case 'selfHelpCenter':
            if (menuName === 'selfHelpCenter') {
                if (section.variableName.includes("methodOfServiceDelivery")) {
                    tableHeaders.push(
                        { value: "Court Help Center Type", cols: [] },
                        { value: "Service Delivery Method", cols: [] },
                    );
                }
                if (section.variableName.includes("caseType") || section.variableName.includes("issuesAddressed")) {
                    tableHeaders.push(
                        { value: "Court Help Center Type", cols: [] },
                        { value: "Case Type", cols: [] },
                        { value: "Case Sub-Type", cols: [] },
                        { value: "Measurement Type", cols: [] }
                    );
                }
                // tableHeaders.push(
                //     { value: "Self Help Center Type", cols: [] },
                //     { value: "Case Type", cols: [] },
                //     { value: "Case Sub-Type", cols: [] }
                // );
            } else {
                tableHeaders.push({ value: "Case Type", cols: [{}] });
            }
            let counties = _getCountyNames((section.values && section.values[0]) && (section.values[0].countyList));
            const listOfCounty = menuName === 'selfHelpCenter'
                ? _getListInSHCForm(counties)
                : _getListInCircuitForm(counties, menuName)
            tableHeaders = menuName === 'selfHelpCenter'
                ? [...tableHeaders, ...listOfCounty]
                : tableHeaders.concat(listOfCounty);
            break;

        case 'courtOfAppeals':
        case 'courtOfSpecialAppeals':
            if (unit === 'DispositionApplicationForLeave') {
                tableHeaders.push({ value: "", cols: [] });
                tableHeaders = tableHeaders.concat({ value: fiscalYear, cols: [] });
            } else {
                tableHeaders.push({ value: unit, cols: [] });
                const listOfHeader = _getHeaderList(section.values)
                tableHeaders = tableHeaders.concat(listOfHeader);
                tableHeaders.push({ value: 'Total', cols: [] });
            }
            break;
        default:
            break;
    }
    return tableHeaders;
}

function _convertDataIntoAppealsSections(dataVariable, fiscalYear = null) {
    const children = dataVariable && dataVariable.values;
    let rows = children && children.reduce((accumlator, variable,) => {
        let add = 0
        variable.children && variable.children.reduce((accumlator, child, index) => {
            add += child.value
            const childIndex = accumlator.findIndex(value => value.displayName === child.displayName);
            if (childIndex === -1) {
                accumlator.push({ displayName: child.displayName, cols: [{ value: child.value }] });
            } else {
                accumlator[childIndex].cols.push({ value: child.value });
            }
            return accumlator;
        }, accumlator);
        const totalValueDisplayName = `Total Cases ${fiscalYear}`;
        const totalIndex = accumlator.findIndex(value => value.displayName === totalValueDisplayName);
        if (totalIndex === -1) {
            accumlator.push({ displayName: totalValueDisplayName, cols: [{ value: add }] });
        } else {
            accumlator[totalIndex].cols.push({ value: add });
        }
        return accumlator;
    }, []);
    rows && rows.reduce((accumlator, child) => {
        let sum = 0
        child.cols.map((value) => {
            return sum += value.value
        })
        child.cols.push({ value: sum })
        return accumlator;
    }, [])
    rows = rows && rows.reduce((accumlator, child, index) => {
        let isChild = false
        if (index === (rows.length - 1)) {
            accumlator.push({
                highlightSectionRow: true,
                makeSectionRowBold: true,
                sectionHighlightTheme: 'light',
                data: _convertDataIntoOneRow({
                    displayName: child.displayName,
                    countyList: child.cols,
                    isChild
                })
            });
        } else {
            accumlator.push({
                data: _convertDataIntoOneRow({
                    displayName: child.displayName,
                    countyList: child.cols,
                    isChild
                })
            });
        }
        return accumlator;
    }, [])
    if (rows) {
        return [{ rows }];
    }
    else {
        return []
    }
}

function _convertDataIntoOneRow({
    menuName = "",
    displayName,
    countyList,
    column2 = "",
    column1 = "",
    column3 = "",
    variableName,
    displayAsItalic = false,
    isChild = false
}) {

    let convertedData = []
    if ((menuName === 'selfHelpCenter')) {
        if (variableName.includes("methodOfServiceDelivery")) {
            convertedData = [
                { isChild, displayAsItalic, value: displayName },
                { isChild: false, value: column1 ? column1 : "-" }
            ];
        }
        if (variableName.includes("caseType") || variableName.includes("issuesAddressed")) {
            convertedData = [
                { isChild, displayAsItalic, value: displayName },
                { isChild: false, value: column1 ? column1 : "-" },
                { isChild: false, value: column2 ? column2 : "-" },
                { isChild: false, value: column3 ? column3 : "-" }

            ];
        }

    } else {
        convertedData = [{ isChild, displayAsItalic, value: displayName }];
    }
    const rows = countyList && countyList.map(({ value }) => {
        return { isChild: false, value: value === null ? "-" : value.toLocaleString() };
    });
    return convertedData.concat(rows);
}
///convert data into sections 
function _convertDataIntoTableSectionsForDistrictAndCircuit(dataVariable, menuName, highlightSectionRow = true, makeSectionRowBold = true) {
    const data = dataVariable.values;
    let totalCountyList = []
    const container = data && data.reduce((accumlator, section) => {
        let headRow
        if ((menuName === 'selfHelpCenter' || menuName === 'initialAppearance')) {
            const highlightRow = (section && section.highlightRow) ? section.highlightRow : false
            headRow = {
                highlightSectionRow: highlightRow,
                makeSectionRowBold: highlightRow,
                data: _convertDataIntoOneRow({
                    variableName: dataVariable.variableName,
                    displayName: section.displayName,
                    countyList: section.countyList,
                    column1: section.column1,
                    column2: section.column2,
                    column3: section.column3,
                    menuName
                })
            }
        }
        else {
            headRow = {
                highlightSectionRow,
                makeSectionRowBold,
                data: _convertDataIntoOneRow({
                    variableName: dataVariable.variableName,
                    displayName: section.displayName,
                    countyList: section.countyList,
                    column1: section.column1,
                    column2: section.column2,
                    column3: section.column3,
                    menuName
                })
            }
        }
        const rows = section.children && section.children.reduce((accumlator, child) => {
            let displayAsItalic = true;
            let isChild = false
            accumlator.push({
                data: _convertDataIntoOneRow({
                    displayName: child.displayName,
                    countyList: child.countyList,
                    displayAsItalic,
                    isChild
                })
            });
            if (child.children && child.children.length > 0) {
                const _child = child.children.map(({ displayName = '', countyList = [] }) => {
                    isChild = true
                    displayAsItalic = false;
                    return {
                        data: _convertDataIntoOneRow({
                            displayName,
                            countyList,
                            displayAsItalic,
                            isChild
                        })
                    }
                });
                accumlator.push(..._child);
            };
            return accumlator;
        }, [])
        if (menuName === 'districtCourt' || menuName === 'publicDefenderStatistics') {
            if (totalCountyList.length === 0) {
                totalCountyList = section.countyList
            }
            else {
                section.countyList && section.countyList.map((county) => {
                    totalCountyList.map((total) => {
                        if (total.countyId === county.countyId) {
                            total.value = total.value + county.value
                        }
                        return ''
                    })
                    return ''
                })
            }

        }
        rows ? accumlator.push({ rows: [headRow, ...rows] }) : accumlator.push({ rows: [headRow] });
        return accumlator;
    }, [])
    const total = {
        highlightSectionRow,
        makeSectionRowBold,
        data: _convertDataIntoOneRow({ displayName: `TOTAL`, countyList: totalCountyList })
    }
    menuName === 'districtCourt' && (container && container.push({ rows: [total] }))
    return container;
}

function _convertDataIntoTableSectionsForLanguageService(dataVariable, selectedProgram, highlightSectionRow = false, makeSectionRowBold = false) {
    const data = dataVariable.values;
    const headRow = {
        highlightSectionRow,
        makeSectionRowBold,
        data: _convertDataIntoOneRow({ displayName: selectedProgram, countyList: data })
    }
    let rows = data && data.reduce((accumlator, section) => {
        let isChild = false;
        let displayAsItalic = true;

        if (accumlator.length === 0) {
            accumlator.push({
                data: [{ isChild, displayAsItalic, value: section.value }]
            });
        }
        else {
            accumlator[0].data.push({
                isChild, displayAsItalic, value: section.value
            });
        }
        return accumlator;
    }, [])
    rows.push({ rows: [headRow] })
    return rows;
}

function _convertDataIntoApplicationForLeave(_data, highlightSectionRow = true, makeSectionRowBold = true) {
    let total = 0
    const variables = _data.values && _data.values.reduce((accumlator, dataVariable) => {
        total += dataVariable.value
        const headRow = {
            highlightSectionRow,
            makeSectionRowBold,
            data: [{ value: dataVariable.displayName }, { value: dataVariable.value }]
        }
        const rows = dataVariable.children && dataVariable.children.reduce((accumlator, child) => {
            accumlator.push({ data: [{ value: child.displayName }, { value: child.value }] });
            if (child.children && child.children.length > 0) {
                const _child = child.children && child.children.map((list) => {
                    return { data: [{ value: list.displayName }, { value: list.value }] }
                });
                accumlator.push(..._child);
            };
            return accumlator;
        }, [])
        accumlator.push({ rows: [headRow, ...rows] });
        return accumlator;
    }, [])
    const totals = {
        rows: [{
            highlightSectionRow,
            makeSectionRowBold,
            sectionHighlightTheme: 'light',
            data: [{ value: "Totals" }, { value: total }]
        }]
    }
    variables && variables.push(totals)
    return variables
}

function _convertDataIntoTableSections({
    section,
    menuName,
    fiscalYear = null,
    unit,
    program = null
}) {
    switch (menuName) {
        case 'districtCourt':
        case 'circuitCourt':
        case 'selfHelpCenter':
        case 'initialAppearance':
        case 'publicDefenderStatistics':
            return _convertDataIntoTableSectionsForDistrictAndCircuit(section, menuName);
        case 'courtOfAppeals':
        case 'courtOfSpecialAppeals':
            if (unit === 'DispositionApplicationForLeave') {
                return _convertDataIntoApplicationForLeave(section);
            }
            else {
                return _convertDataIntoAppealsSections(section, fiscalYear);
            }
        case 'languageService':
            return _convertDataIntoTableSectionsForLanguageService(section, program);
        default:
            break;
    }
}

function _convertToCsvData(head, sections) {
    let csv = [];
    csv.push(head && head.reduce((accumlator, tablHead) => {
        if (tablHead && tablHead.cols && tablHead.cols.length > 0) {
            (tablHead.cols.reduce((acc, col) => {
                if (col.value) {
                    accumlator.push(col.value)
                    return acc
                }
                else {
                    accumlator.push(tablHead.value)
                    return acc
                }
            }, []))
        }
        else {
            accumlator.push(tablHead && tablHead.value)
        }
        return accumlator
    }, []))

    sections && sections.reduce((accumlator, section) => {
        section.rows && section.rows.reduce((acc, cols) => {
            if (cols.data) {
                csv.push(cols.data && cols.data.reduce((accu, col) => {
                    accu.push(col && col.value.toLocaleString())
                    return accu
                }, []))
            }
            return acc
        }, [])
        return accumlator
    }, [])
    return csv
}

//////////////////stack-chart functions////////////////////////////
function GetStackViewSeriesforNextiteration(
    displayName,
    childStatus,
    section,
    accumlator,
    drilldown = false,
    id) {
    let colors = [
        '#8d90ff',
        '#8e2d5a',
        '#8a6e3d',
        '#c5fffe',
        '#0c48bb',
        '#d8696a',
        '#000464',
        '#d9bd05',
        '#c64690',
        '#ff7522',
        '#9d8189',
        '#008c95',
        '#ffadeb',
        '#75e4b3',
        '#ffbfb5'
    ]
    return section.children && section.children.reduce((acc, child, index) => {
        // let colorIndex = index > 9 ? index % 9 : index
        if (!drilldown) {
            ///////////for activity List in stack////////
            accumlator.activities.map((activity) => {
                if (activity.name === child.displayName) {
                    activity.data.push(
                        childStatus
                            ? { name: section.displayName, y: child.value, color: activity.color, }
                            : { name: section.displayName, y: child.value, color: activity.color, drilldown: section.displayName + '-' + child.displayName }
                    )
                }
                else {
                    let flag = true;
                    let colorIndex = (accumlator.activities.length - 1) > 14 ? (accumlator.activities.length - 1) % 14 : (accumlator.activities.length);;
                    const colorCode = displayName === "commissionerTotalPretrialData"
                        ? GetColorCodeForPretrial(child.displayName)
                        : colors[colorIndex]
                    accumlator.activities.map((value) => {
                        if (value.name === child.displayName) {
                            flag = false;
                        }
                        return ''
                    })
                    if (flag) {

                        const newActivity = {
                            name: child.displayName,
                            color: colorCode,
                            data: childStatus
                                ? [{
                                    name: section.displayName,
                                    color: colorCode,
                                    y: child.value
                                }]
                                : [{
                                    name: section.displayName,
                                    color: colorCode,
                                    y: child.value,
                                    drilldown: section.displayName + '-' + child.displayName
                                }]
                        }
                        accumlator.activities.push(newActivity)
                    }
                }
                return ''
            })
            return acc
        }
        else {
            ///////////for drilldown List in stack////////
            accumlator.drilldown.reduce((accu, activity, actIndex) => {
                if ((activity.name === child.displayName)) {
                    if (activity.id === (id.displayName + '-' + child.displayName)) {
                        activity.data.push(
                            [section.displayName, child.value]
                        )
                    }
                    if (section.children.length === 1) {
                        let flag = true;
                        let color = accumlator.activities.find(({ name }) => name === child.displayName);
                        color = color ? color.color : null
                        // const colorCode = displayName === "commissionerTotalPretrialData"
                        //     ? GetColorCodeForPretrial(child.displayName)
                        //     : color
                        accumlator.drilldown.map((value) => {
                            if (value.id === (id.displayName + '-' + child.displayName)) {
                                flag = false
                            }
                            return ''
                        })
                        if (flag) {
                            const newData = {
                                title: id.displayName,
                                name: child.displayName,
                                color: color,
                                id: id.displayName + '-' + child.displayName,
                                data: [[section.displayName, child.value]]
                            }
                            accumlator.drilldown.push(newData)
                        }
                    }
                }
                else {
                    let flag = true;
                    let color = accumlator.activities.find(({ name }) => name === child.displayName);
                    color = color ? color.color : null
                    // const colorCode = displayName === "commissionerTotalPretrialData"
                    //         ? GetColorCodeForPretrial(child.displayName)
                    //         : color
                    accumlator.drilldown.map((value) => {
                        if (value.id === (id.displayName + '-' + child.displayName)) {
                            flag = false
                        }
                        return ''
                    })
                    if (flag) {
                        const newData = {
                            title: id.displayName,
                            name: child.displayName,
                            color: color,

                            id: id.displayName + '-' + child.displayName,
                            data: [[section.displayName, child.value]]
                        }
                        accumlator.drilldown.push(newData)
                    }
                }
                return accu
            }, [])
            return ''
        }
    }, [])
}

function GetStackViewOnInitialLoad(
    displayName,
    childStatus,
    section,
    drilldown = false,
    id) {
    let colors = [
        '#8d90ff',
        '#8e2d5a',
        '#8a6e3d',
        '#c5fffe',
        '#0c48bb',
        '#d8696a',
        '#000464',
        '#d9bd05',
        '#c64690',
        '#ff7522',
        '#9d8189',
        '#008c95',
        '#ffadeb',
        '#75e4b3',
        '#ffbfb5'
    ];
    return section.children && section.children.reduce((acc, child, index) => {
        const colorIndex = index > 14 ? index % 14 : index;
        const colorCode = displayName === "commissionerTotalPretrialData"
            ? GetColorCodeForPretrial(child.displayName)
            : colors[colorIndex];
        acc.push(
            !drilldown ?
                ///////////for activity List in stack////////
                {
                    name: child.displayName,
                    color: colorCode,
                    data: childStatus
                        ? [{
                            name: section.displayName,
                            color: colorCode,
                            y: child.value
                        }]
                        : [{
                            name: section.displayName,
                            y: child.value,
                            color: colorCode,
                            drilldown: section.displayName + '-' + child.displayName
                        }]
                }
                :
                ///////////for drilldown List in stack////////
                {
                    name: child.displayName,
                    title: id.displayName,
                    id: id.displayName + '-' + child.displayName,
                    data: [[section.displayName, child.value]]
                }
        );
        return acc;
    }, [])
}

function _GetTooltipForStackedPie(section) {
    return section.children && section.children.reduce((acc, child) => {
        acc.push({ child: child.displayName, value: child.value.toLocaleString() })
        return acc;
    }, [])
}

function _GetYaxisForStckedPie(section) {
    let total = 0;
    section.children && section.children.map(({ value }) => {
        total = total + value;
        return ''
    })
    return total
}

function GetColorCodeForPretrial(data) {
    const PretrialArray = [
        {
            name: "Held in Default of Bond",
            color: "#ffd965"
        },
        {
            name: "Held Without Bond at Initial Appearance: Fugitive",
            color: "#ffe598"
        },
        {
            name: "Held Without Bond at Initial Appearance: Non-Fugitive",
            color: "#fef2cb"
        },
        {
            name: "Released at Initial Appearance:  Personal Recognizance, No Pre-conditions, No Probable Cause",
            color: "#4472c4"
        },
        {
            name: "Released at Initial Appearance:  Personal Recognizance, With or Without Conditions",
            color: "#8eaadb"
        },
        {
            name: "Released at Initial Appearance: Unsecured Personal Bond",
            color: "#b4c6e7"
        }
    ]
    const color = PretrialArray.find(({ name }) => name === data)
        ? PretrialArray.find(({ name }) => name === data).color
        : null
    return color;
}
/////////////////// exported functions ///////////////////////////

///************* ACTIVITY ***************////
export function convertDataToActivityView(data, chartType = 'bar-chart') {
    if (chartType === 'bar-chart' || chartType === 'pie-chart') {
        const dataVariableList = data && data.variableList;
        return dataVariableList && dataVariableList.map((dataSection) => {
            const data = dataSection.values && dataSection.values.reduce((accumlator, section) => {
                const colorCode = (dataSection.variableName === "commissionerTotalPretrialData")
                    ? GetColorCodeForPretrial(section.displayName)
                    : null
                if (section.children && section.children.length > 0) {
                    accumlator.activities.push(
                        {
                            name: section.displayName,
                            y: section.value,
                            drilldown: section.displayName,
                            color: colorCode
                        }
                    );
                }
                else {
                    accumlator.activities.push(
                        {
                            name: section.displayName,
                            y: section.value,
                            color: colorCode
                        }
                    );
                }

                // if drilldown data available
                if (section.children && section.children.length > 0) {
                    accumlator.drilldown.push(
                        {
                            title: dataSection.displayName,
                            id: section.displayName,
                            data: section.children.map(({ displayName, value }) => [displayName, value])
                        }
                    );
                }
                return accumlator;
            }, {
                activities: [],
                drilldown: []
            });
            const obj = {
                name: dataSection.variableName,
                displayName: dataSection.displayName,
                footnotes: dataSection.footnotes,
                chartType: ((dataSection.variableName === "percentageOfCasesTerminatedWithinStandard") ||
                    (dataSection.displayName === "Out of Courtroom Services (Telephonic) - Event Held By Language") ||
                    (dataSection.displayName === "Courtroom Services (Court Interpreter Program) - Event Held By Language"))
                    ? 'bar-chart' : (
                        ((dataSection.values && dataSection.values.length > 8) ||
                            (dataSection.variableName === 'businessRelatedWorkload'))
                            ? "pie-chart" : chartType
                    ),
                activities: data && data.activities,
                data: {}
            };
            if (data && data.drilldown.length > 0) {
                obj.drilldown = data && data.drilldown;
            }
            return obj;
        });
    }
    return null;
};

///************* TABLE ***************////
export function convertDataToTableView({
    data,
    menuName,
    districts = [],
    fiscalYear = null,
    program = null
}) {

    const dataVariableList = data && data.variableList;
    return dataVariableList && dataVariableList.map((dataSection) => {
        let tableHeaders
        let tableSections
        let csv;
        let unit = null;
        if (dataSection.displayName.includes('- Disposition')) {
            unit = "Disposition"
        } else if (dataSection.displayName.includes('- Origin')) {
            unit = 'origin'
        } else if (dataSection.displayName.includes('Disposition Of Application For Leave')) {
            unit = 'DispositionApplicationForLeave'
        } else if (dataSection.displayName.includes('Petition')) {
            unit = 'Petition'
        }
        if (unit === 'DispositionApplicationForLeave') {
            tableHeaders = _getTableHeaders({
                section: dataSection,
                menuName,
                districts,
                fiscalYear,
                unit
            });

        } else {
            tableHeaders = _getTableHeaders({
                section: dataSection,
                menuName,
                districts,
                unit
            });
        }
        if (unit === 'DispositionApplicationForLeave') {
            tableSections = _convertDataIntoTableSections({
                menuName,
                section: dataSection,
                fiscalYear,
                unit
            });
        } else {
            tableSections = _convertDataIntoTableSections({
                menuName,
                section: dataSection,
                fiscalYear,
                unit,
                program
            });
        }
        csv = _convertToCsvData(tableHeaders, tableSections)
        return {
            unit,
            menuName,
            name: dataSection.variableName,
            displayName: dataSection.displayName,
            tableHeaders,
            tableSections,
            variableName: dataSection.variableName,
            csv
        };
    });
};

///************* STACK ***************////
export function convertDataToComparisionView(data, comparisionByMonth = false, chartType = 'bar-chart') {
    if (chartType === 'bar-chart' || chartType === 'pie-chart') {
        const dataVariableList = data && data.variableList;
        return dataVariableList && dataVariableList.map((dataSection) => {
            const data = dataSection.stackList && dataSection.stackList.reduce((accumlator, section) => {
                // const pieValue = _GetYaxisForStckedPie(section) 
                const pieValue = section.value;
                const tooltip = _GetTooltipForStackedPie(section);

                ////////chartData////
                if (section.stackList && section.stackList.length > 0) {
                    if (accumlator.activities && accumlator.activities.length > 0) {
                        GetStackViewSeriesforNextiteration(dataSection.variableName, false, section, accumlator)
                    } else {
                        accumlator.activities = GetStackViewOnInitialLoad(dataSection.variableName, false, section)
                    }
                    accumlator.pie.push(
                        {
                            name: section.displayName,
                            tooltip: tooltip,
                            y: pieValue,
                            drilldown: section.displayName,
                            color: GetColorCodeForPretrial(section.displayName)
                        }
                    );
                }
                else {
                    if (accumlator.activities && accumlator.activities.length > 0) {
                        GetStackViewSeriesforNextiteration(dataSection.variableName, true, section, accumlator,)
                    } else {
                        accumlator.activities = GetStackViewOnInitialLoad(dataSection.variableName, true, section)
                    }
                    accumlator.pie.push(
                        {
                            name: section.displayName,
                            y: pieValue,
                            tooltip: tooltip,
                            color: GetColorCodeForPretrial(section.displayName)
                        }
                    );
                }

                // if drilldown data available

                if (section.stackList && section.stackList.length > 0) {
                    accumlator.pieDrillDown.push(
                        {
                            id: section.displayName,
                            data: section.stackList.map((value) => {
                                const pieDrill = _GetYaxisForStckedPie(value)
                                const tooltip = _GetTooltipForStackedPie(value)
                                return { name: value.displayName, y: pieDrill, tooltip: tooltip }
                            })
                        }
                    );
                    const children = section.stackList;
                    children.map((child) => {
                        if (accumlator.drilldown && accumlator.drilldown.length > 0) {
                            GetStackViewSeriesforNextiteration(dataSection.variableName, false, child, accumlator, true, section)
                            // accumlator.drilldown = [
                            //     ...accumlator.drilldown,
                            //     ...GetStackViewOnInitialLoad(false, child, true, section)
                            // ]
                        } else {
                            accumlator.drilldown = GetStackViewOnInitialLoad(dataSection.variableName, false, child, true, section);
                        }
                        return ''
                    })
                }
                return accumlator;
            }, {
                activities: [],
                drilldown: [],
                pie: [],
                pieDrillDown: []
            });
            const obj = {
                name: dataSection.variableName,
                displayName: dataSection.displayName,
                chartType: comparisionByMonth ? "bar-chart" : (dataSection.stackList && dataSection.stackList.length > 8 ? "pie-chart" : chartType),
                excessData: comparisionByMonth ? false : (dataSection.stackList && dataSection.stackList.length > 8 ? true : false),
                drilldownStatus: false,
                activities: data && data.activities,
                pie: data && data.pie,
            };
            if (data) {
                if (data.drilldown && data.drilldown.length > 0) {
                    obj.drilldown = data && data.drilldown;
                    obj.pieDrillDown = data && data.pieDrillDown;
                }
            }
            return obj;
        });
    }
    return null;
};


