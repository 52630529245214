import Actions from './actions';

export function loadWebsiteSettings() {
    return {
        type: Actions.LOAD_WEBSITE_SETTINGS
    };
};

export function onWebsiteSettingsLoaded(data) {
    return {
        type: Actions.ON_WEBSITE_SETTINGS_LOADED,
        data
    }
};
