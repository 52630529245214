import React from "react";
import "./index.scss";

export default function ApplicationTableSections({
  tableSections = [],
  menuName,
  unit,
  displayName,
}) {
  return (
    tableSections &&
    tableSections.map((section, sectionIndex) => {
      return (
        <thead key={sectionIndex}>
          {section.rows &&
            section.rows.map((row, rowIndex) => {
              const rowClasses = [];
              let sectionHighlightTheme;
              let ishighlighted = false;
              if (
                row.hasOwnProperty("highlightSectionRow") &&
                row.highlightSectionRow
              ) {
                ishighlighted = row.highlightSectionRow;
                sectionHighlightTheme = row.sectionHighlightTheme || null;
                switch (sectionHighlightTheme) {
                  case "light":
                    rowClasses.push(
                      "application-table-section_background-light"
                    );
                    break;
                  default:
                    rowClasses.push(
                      "application-table-section_background-dark"
                    );
                    break;
                }
              }
              if (
                row.hasOwnProperty("makeSectionRowBold") &&
                row.makeSectionRowBold
              ) {
                // make text bold for entire row
                rowClasses.push("application-table-section_bold");
              }
              return (
                <tr key={rowIndex} className={`${rowClasses.join(" ")}`}>
                  {row.data.map((column, colIndex) => {
                    const columnClasses = [
                      "application-table-section__data",
                      "application-table-container__table__column",
                    ];
                    const spanClasses = [];

                    if (menuName === "selfHelpCenter") {
                      columnClasses.push(
                        "application-table-section__data__self-help-center "
                      );
                      if (ishighlighted && colIndex === 0 && rowIndex === 0) {
                        columnClasses.push("section-first-column__dark ");
                      }
                    }
                    if (menuName === "publicDefenderStatistics") {
                      columnClasses.push(
                        "application-table-section__data__public-defender "
                      );
                    }
                    if (menuName === "initialAppearance") {
                      if (
                        displayName ===
                        " Pretrial Release Conditions at Initial Appearance"
                      ) {
                        columnClasses.push(
                          "application-table-section__data__pretrial-release "
                        );
                      }
                    }
                    if (menuName === "languageService") {
                      if (colIndex === 0 && rowIndex === 0) {
                        columnClasses.push(
                          "application-table-section__data__language-service-first-column"
                        );
                      }
                      columnClasses.push(
                        "application-table-section__data__language-service"
                      );
                    }
                    if (
                      menuName === "districtCourt" ||
                      menuName === "publicDefenderStatistics"
                    ) {
                      columnClasses.push(
                        "application-table-section__data__district-court"
                      );
                    }
                    if (menuName === "circuitCourt") {
                      columnClasses.push(
                        "application-table-section__data__circuit-court"
                      );
                    }
                    if (menuName === "courtOfSpecialAppeals") {
                      columnClasses.push(
                        "application-table-section__data__special-appeals"
                      );
                    }
                    if (menuName === "courtOfAppeals") {
                      columnClasses.push(
                        "application-table-section__data__appeals"
                      );
                    }
                    if (
                      column &&
                      column.hasOwnProperty("isChild") &&
                      column.isChild
                    ) {
                      columnClasses.push(
                        "application-table-section__data__ischild"
                      );
                    }
                    if (
                      column &&
                      column.hasOwnProperty("displayAsItalic") &&
                      column.displayAsItalic
                    ) {
                      columnClasses.push(
                        "application-table-section__data__italic"
                      );
                    }
                    if (colIndex === 0) {
                      spanClasses.push("center-align");
                      columnClasses.push(
                        "application-table-section__first-column "
                      );
                      columnClasses.push(
                        "application-table-container__table__first-column"
                      );
                    }
                    if (sectionHighlightTheme) {
                      columnClasses.push(
                        "application-table-section__background-color "
                      );
                    }
                    if (colIndex === 0 && rowIndex === 0) {
                      if (
                        menuName === "districtCourt" ||
                        menuName === "publicDefenderStatistics" ||
                        menuName === "circuitCourt" ||
                        unit === "DispositionApplicationForLeave"
                      ) {
                        columnClasses.push("section-first-column__dark ");
                      }
                      spanClasses.push("first-column");
                      columnClasses.push(
                        "application-table-container__table__first-column"
                      );
                    }
                    if (
                      sectionIndex === tableSections.length - 1 &&
                      rowIndex === section.rows.length - 1 &&
                      colIndex === 0
                    ) {
                      columnClasses.push(
                        "application-table-section__border-bottom "
                      );
                    }
                    return (
                      <td
                        key={colIndex}
                        className={`${columnClasses.join(" ")}`}
                      >
                        <div className={`${spanClasses.join(" ")}`}>
                          {column &&
                            column.value &&
                            column.value.toLocaleString()}
                        </div>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
        </thead>
      );
    })
  );
}
