import React, { Component } from 'react';
import { connect } from 'react-redux';
import DesktopView from './desktopView';
import MobileView from './mobileView';
import ApplicationDeviceIdentifier from '../../../../helpers/ApplicationDeviceIdentifier'
// import { closeModal } from '../../../../helpers/ApplicationModal';
// import {} from '../../store/dispatchers';
import './index.scss';

class languageService extends Component {
    render() {
        return  (
            <React.Fragment>
                <ApplicationDeviceIdentifier>
                    <DesktopView />
                </ApplicationDeviceIdentifier>
                
                <ApplicationDeviceIdentifier isMobile = {true}>
                    <MobileView />
                </ApplicationDeviceIdentifier>
                
            </React.Fragment>
        )
    }
}

const mapStateToProps = ({home }) => {
    return {
        data:home.accessToJustice
    };
};
// const mapDispatchToProps = (dispatch) => {
//     return {
//     };
// };
export default connect(mapStateToProps, null)((languageService));