import React from 'react';
import ApplicationModal from '../../../helpers/ApplicationModal';
import ApplicationModalMobileTabs from '../../../helpers/ApplicationModalMobileTabs';
import ApplicationModalMobileFilter from '../../../helpers/ApplicationModalMobileFilter';
import ApplicationModalMobileFilterHeader from '../../../helpers/ApplicationModalMobileFilterHeader';
import './index.scss';

export default function DashboardMobileModalFilter({
    showMobileModal,
    onClose
}) {
    return (
        <ApplicationModal showModal = {showMobileModal} modalPosition = "top" onClose = {onClose}>
            <ApplicationModalMobileFilterHeader onClose = {onClose}/>
            <ApplicationModalMobileFilter>
                <ApplicationModalMobileTabs onClose = {onClose}/>
            </ApplicationModalMobileFilter>
        </ApplicationModal>
    )
}