import React, { Component } from "react";
import ReactGA from "react-ga4";
import { connect } from "react-redux";
import Loader from "../../helpers/Loader";
import HomeDetailsSection from "./HomeDetailsSection";
import HomeDetailsGetQuery from "./HomeDetailsGetQuery";
import ApplicationPageLayout from "../../helpers/ApplicationPageLayout";
import ApplicationHomeNavigation from "../../helpers/ApplicationHomeNavigation";
import "./index.scss";

class Home extends Component {
  componentWillMount() {
    //ReactGA.pageview(window.location.pathname);
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Home" });
  }

  render() {
    const { homeDetailsLoaded } = this.props;
    return (
      <React.Fragment>
        <HomeDetailsGetQuery />
        {homeDetailsLoaded ? (
          <React.Fragment>
            <ApplicationHomeNavigation />
            <div className="dashboad-body">
              <ApplicationPageLayout>
                <p className="paragraph">
                  Maryland Judiciary's Annual Statistical Performance Measures
                  is an interactive compilation of trial and appellate courts
                  caseload and performance data. These pages include information
                  about the work of the state's courts and highlight the daily
                  efforts of the Maryland Judiciary's dedicated professionals to
                  provide fair, efficient, and effective justice for all. The
                  data presented herein reflect the operation of the courts,
                  including total incoming and outgoing cases, clearance rates,
                  active caseload volumes, and case processing performance
                  measures.
                </p>
              </ApplicationPageLayout>
              <HomeDetailsSection {...this.props} />
            </div>
          </React.Fragment>
        ) : (
          <Loader className={"page-loader"} />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ home }) => {
  return {
    homeDetailsLoaded: home.homeDetailsLoaded,
  };
};
export default connect(mapStateToProps, null)(Home);
