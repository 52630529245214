import React from "react";

export default class ApplicationDeviceIdentifier extends React.PureComponent {
 constructor() {
   super();
   this.state = {
     isMobile: false,
     isDesktop: false
   };
 }
 
  handleWindowResize() {
    const resolution = window.innerWidth;
    const isDesktop = resolution > 992 ? true : false;
    const isMobile = !isDesktop;
    this.setState({
      isMobile,
      isDesktop
    });
  }

  componentDidMount() {
    this.handleWindowResize();
    window.addEventListener("resize", this.handleWindowResize.bind(this));
  }

  render() {
    const { isMobile, isDesktop } = this.state;
    const { isMobile: propsIsMobile = false } = this.props;
    if ((isDesktop && propsIsMobile === false) || (isMobile && propsIsMobile === true)) {
      return (
        <React.Fragment>
          {this.props.children}
        </React.Fragment>
      );
    }
    return null;
  }
}