import React, { Component } from 'react';
import ApplicationPageTitle from '../ApplicationPageTitle';
import './index.scss';

export default class ApplicationStickyHeader extends Component {
    constructor() {
        super();
        this.state = {
            isSticky: false
        };
    }
    componentDidMount() {
        window.onscroll = () => {
            const top = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
            
            if (top > 350) {
                // state updae isSticky - true
                this.setState({
                    isSticky: true
                })
            } else if (top < 50) {
                // state update isSticky - false
                this.setState({
                    isSticky: false
                })
            }
        };
    }

    render() {
        const { isSticky } = this.state;
        return (
            <div className = {isSticky === true ? 'sticky-header' : ''}>
                <ApplicationPageTitle isSticky = {isSticky} />
                <div>
                    {this.props.children}
                </div>
            </div>
        )
    }
}