import React, { Component } from 'react';
import ApplicationListSection from "../ApplicationListSection";
import './index.scss';

export default class ApplicationNavigationMenu extends Component {
    render() {
        const { 
            onClick, 
            menuList = [], 
            selectedMenuId, 
            disableSubMenus = false,
            enableOnlySelectedMenu = false } = this.props;
        
        function _onClick({menuId,subMenu}){
            const subMenuChildId = subMenu.children && subMenu.children.length > 0 ? subMenu.children[0].id :null
           
            onClick({
                menuId,
                subMenuId:subMenu.id,
                subMenuChildId
            })
        }
        return (
            <div className = "application-navigation-menu" >  
                <ul>
                    {  
                        menuList.map((menu, index) => {
                            const navigationSubMenu = menu.children &&  menu.children.map((child) => child)
                            const subMenu=  menu.children && menu.children[0]
                            
                            if (selectedMenuId === menu.id) {    

                                return <li key = {index} className = "menu menu-active">
                                    {
                                        disableSubMenus ? <div> {menu.displayName} </div>
                                        :   <div 
                                                onClick = {_ => _onClick({ 
                                                menuId: menu.id,
                                                subMenu:subMenu })}> 
                                                {menu.displayName} 
                                            </div>
                                    }
                                    <div className = "sub-menu-list">
                                        <ApplicationListSection 
                                            viewType = {"sub-menu"} 
                                            listNames = {navigationSubMenu} 
                                            disableItems = {disableSubMenus}
                                            onClickOption = {(subMenu) => _onClick({ menuId: menu.id, subMenu: subMenu })} />
                                    </div>
                                </li>
                            } else {
                                return <li key = {index} className = {enableOnlySelectedMenu ? "menu disabled" : "menu"} >
                                    {
                                        enableOnlySelectedMenu ? <div> {menu.displayName} </div>
                                        : <div onClick = {_ => _onClick({ menuId: menu.id ,subMenu: subMenu})}>{menu.displayName}</div>
                                    }
                                    <div className = "sub-menu-list">
                                        <ApplicationListSection 
                                            viewType = {"sub-menu"} 
                                            listNames = {navigationSubMenu} 
                                            onClickOption = {(subMenu) => _onClick({ menuId: menu.id, subMenu: subMenu})} />
                                    </div>
                                </li>
                            }
                        })
                    }
                </ul>
            </div>
        )
    }
}


