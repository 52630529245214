import React, { Component } from 'react';
import { connect } from 'react-redux';
import ApplicationPageLayout from '../ApplicationPageLayout';
import ApplicationNavigationmenu from '../ApplicationNavigationMenu';
import { Link } from 'react-router-dom';
import { resetState, initializeDashboard } from '../../screens/Dashboard/store/dispatchers';
import './index.scss';

class ApplicationNavigation extends Component {
    onClick({ menuId, subMenuId, subMenuChildId }) {
        const {
            history,
            resetState,
            initializeDashboard,
            previewDetailsLoaded,
            selectedDashboardViewOption
        } = this.props;
        const viewOption = selectedDashboardViewOption.displayName;
        subMenuChildId = subMenuChildId ? subMenuChildId : null
        resetState();
        initializeDashboard({
            viewOption,
            categoryId: menuId,
            subCategoryId: subMenuId,
            subCategoryChildId: subMenuChildId
        });
        if (!previewDetailsLoaded) {
            if (subMenuChildId) {
                return history && history.push(`/menus/${menuId}/sub-menu/${subMenuId}/children/${subMenuChildId}/${viewOption}`);
            } else {
                return history && history.push(`/menus/${menuId}/sub-menu/${subMenuId}/${viewOption}`);
            }
        }
    }

    render() {
        const {
            selectedMenu,
            navigationMenuList,
            previewDetailsLoaded,
            previewSubCategoryId,
            selectedDashboardViewOption
        } = this.props;
        return (
            <div className="application-navigation">
                <ApplicationPageLayout>
                    <div className="application-navigation__content">
                        <div className='application-navigation__content__title'>
                            {
                                previewDetailsLoaded
                                    ? <span> MARYLAND JUDICIARY DATA DASHBOARD </span>
                                    : <Link to='/' >MARYLAND JUDICIARY DATA DASHBOARD </Link>
                            }
                        </div>
                        <div className='desktop-navigation-menu'>
                            <ApplicationNavigationmenu
                                menuList={navigationMenuList}
                                selectedMenuId={selectedMenu.id}
                                onClick={this.onClick.bind(this)}
                                enableOnlySelectedMenu={previewDetailsLoaded}
                                disableSubMenus={!previewSubCategoryId ? false : true}
                                selectedDashboardViewOption={selectedDashboardViewOption}
                            />
                        </div>
                    </div>
                </ApplicationPageLayout>
            </div>
        )
    }
}

const mapStateToProps = ({ setting, dashboard, preview }) => {
    return {
        circuit: setting.circuit,
        counties: setting.counties,
        districts: setting.districts,
        selectedMenu: dashboard.selectedMenu,
        navigationMenuList: setting.menuList,
        previewSubCategoryId: preview.subCategoryId,
        previewDetailsLoaded: preview.previewDetailsLoaded,
        selectedDashboardViewOption: dashboard.selectedDashboardViewOption
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        resetState() {
            dispatch(resetState())
        },
        initializeDashboard(details) {
            dispatch(initializeDashboard(details));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationNavigation);