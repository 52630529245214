import React from "react";
import ApplicationBarChart from "../ApplicationBarChart";
import ApplicationPieChart from "../ApplicationPieChart";
import ApplicationStackChart from "../ApplicationStackChart";
import ApplicationTabularData from "../ApplicationTabularData";
import ApplicationChartOptions from "../ApplicationChartOptions";
import ApplicationFootNoteDetails from "../ApplicationFootNotesDetails";
import "./index.scss";

export default class ApplicationChartContainer extends React.PureComponent {
  constructor(props) {
    super();
    this.state = {
      toggle: true,
      onOptionClick: false,
      chartType: props.containerData.chartType,
      modifiedDrilldownData: [],
      modifiedDrillDownStackPie: [],
      priviousChart: null,
      stackTitle: null,
      activityTitle: null,
      containerTitle: null,
      excessData: props.containerData.excessData,
      dilldownId: null,
      colors: [
        "#8d90ff",
        "#8e2d5a",
        "#8a6e3d",
        "#c5fffe",
        "#0c48bb",
        "#d8696a",
        "#000464",
        "#d9bd05",
        "#c64690",
        "#ff7522",
        "#9d8189",
        "#008c95",
        "#ffadeb",
        "#75e4b3",
        "#ffbfb5",
      ],
    };
    this.toggle = this.toggle.bind(this);
    this.onDrillUp = this.onDrillUp.bind(this);
    this.onDrilldown = this.onDrilldown.bind(this);
    this.onClickOption = this.onClickOption.bind(this);
  }
  toggle() {
    this.setState((state) => {
      return {
        ...state,
        toggle: !state.toggle,
      };
    });
  }
  GetColorCodeForPretrial(data, selectedColor = null) {
    const PretrialArray = [
      {
        name: "Held in Default of Bond",
        color: "#ffd965",
      },
      {
        name: "Held Without Bond at Initial Appearance: Fugitive",
        color: "#ffe598",
      },
      {
        name: "Held Without Bond at Initial Appearance: Non-Fugitive",
        color: "#fef2cb",
      },
      {
        name: "Released at Initial Appearance:  Personal Recognizance, No Pre-conditions, No Probable Cause",
        color: "#4472c4",
      },
      {
        name: "Released at Initial Appearance:  Personal Recognizance, With or Without Conditions",
        color: "#8eaadb",
      },
      {
        name: "Released at Initial Appearance: Unsecured Personal Bond",
        color: "#b4c6e7",
      },
    ];
    const color = PretrialArray.find(({ name }) => name === data)
      ? PretrialArray.find(({ name }) => name === data).color
      : selectedColor;
    return color;
  }
  GetStackViewSeriesforNextiteration(section, accumlator) {
    return (
      section &&
      section.tooltip.reduce((accum, child) => {
        accumlator.map((activity) => {
          if (activity.name === child.child) {
            activity.data.push({
              name: section.name,
              y: parseInt(child.value.replace(",", "")),
            });
          } else {
            let flag = true;
            accumlator.map((value) => {
              if (value.name === child.child) {
                flag = false;
              }
              return "";
            });
            if (flag) {
              const newActivity = {
                name: child.child,
                data: [
                  {
                    name: section.name,
                    y: parseInt(child.value.replace(",", "")),
                  },
                ],
              };
              accumlator.push(newActivity);
            }
          }
          return "";
        });
        return accum;
      }, [])
    );
  }
  GetStackViewOnInitialLoad(section) {
    return (
      section &&
      section.tooltip.reduce((accum, child) => {
        accum.push({
          name: child.child,
          color: this.GetColorCodeForPretrial(child.child),
          data: [
            {
              name: section.name,
              y: parseInt(child.value.replace(",", "")),
            },
          ],
        });

        return accum;
      }, [])
    );
  }
  onDrilldown(_data) {
    const data = { ..._data };
    const { containerData, viewType } = this.props;
    const _containerData = JSON.parse(JSON.stringify(containerData));
    const { chartType, colors } = this.state;
    let chart;
    let _priviousChart;
    let modifiedDrilldownData = [];
    let modifiedDrillDownStackPie = [];
    let title = null;
    let activityTitleForSHC = null;
    if (data.seriesOptions) {
      if (viewType === "comparison") {
        if (chartType === "pie-chart") {
          chart = _containerData.pie.length > 9 ? "bar-chart" : chartType;
          _priviousChart = _containerData.pie.length > 9 ? "pie-chart" : null;
          title = data.seriesOptions.id;
          const _data = data.seriesOptions.data ? data.seriesOptions.data : [];
          modifiedDrilldownData = _data.reduce((accumlator, section) => {
            if (accumlator && accumlator.length > 0) {
              this.GetStackViewSeriesforNextiteration(section, accumlator);
            } else {
              accumlator = this.GetStackViewOnInitialLoad(section);
            }

            return accumlator;
          }, []);
          modifiedDrillDownStackPie = data.seriesOptions.data.reduce(
            (acc, _data) => {
              acc.push({
                name: _data.name,
                y: _data.y,
                tooltip: _data.tooltip,
                drilldown: null,
              });
              return acc;
            },
            []
          );
        } else {
          let maxLength = 0;
          _containerData.activities.map(({ data }) => {
            if (data.length > maxLength) {
              maxLength = data.length;
            }
            return "";
          });
          chart = maxLength > 9 ? "bar-chart" : chartType;
          _priviousChart = maxLength > 9 ? "pie-chart" : null;
          const drilldownData = [..._containerData.drilldown];
          const colorId = _containerData.activities.findIndex((activity) => {
            const str = data.seriesOptions.id.replace(
              `${data.seriesOptions.title}-`,
              ""
            );
            return activity.name === (data.seriesOptions && str);
          });
          const colorIndex = colorId > 14 ? colorId % 14 : colorId;
          const defaultDrillDownData =
            drilldownData &&
            drilldownData.find(
              ({ id }) => id === (data.seriesOptions && data.seriesOptions.id)
            );
          const selectedColor =
            chart === "bar-chart" &&
            defaultDrillDownData &&
            defaultDrillDownData.data.length < 9
              ? colors[colorIndex]
              : null;

          modifiedDrilldownData = drilldownData.reduce((accum, value) => {
            if (!data.points) {
              if (
                value.id === data.seriesOptions.id &&
                value.title === data.seriesOptions.title
              ) {
                title = data.seriesOptions.title;
                accum.push({
                  name: value.name,
                  color: this.GetColorCodeForPretrial(
                    value.name,
                    selectedColor
                  ),
                  data: value.data.reduce((accu, _data) => {
                    accu.push({
                      name: _data[0],
                      y: _data[1],
                      color: this.GetColorCodeForPretrial(
                        value.name,
                        selectedColor
                      ),
                      drilldown: null,
                    });
                    return accu;
                  }, []),
                });
              }
            } else {
              if (value.title === data.seriesOptions.title) {
                title = data.seriesOptions.title;
                accum.push({
                  name: value.name,
                  color: this.GetColorCodeForPretrial(
                    value.name,
                    colors[accum.length]
                  ),
                  data: value.data.reduce((accu, _data) => {
                    accu.push({
                      name: _data[0],
                      y: _data[1],
                      // color: selectedColor,
                      drilldown: null,
                    });
                    return accu;
                  }, []),
                });
              }
            }
            return accum;
          }, []);
          const newData = _containerData.pieDrillDown.find(
            ({ id }) => data.seriesOptions.title === id
          );
          modifiedDrillDownStackPie =
            newData &&
            newData.data.reduce((acc, _data) => {
              acc.push({
                name: _data.name,
                y: _data.y,
                tooltip: _data.tooltip,
                drilldown: null,
              });
              return acc;
            }, []);
        }
      } else {
        chart = _containerData.activities.length > 9 ? "bar-chart" : chartType;
        _priviousChart =
          _containerData.activities.length > 9 ? "pie-chart" : null;
        const selectedProgramDisplayName =
          !(viewType === "data") && _containerData.displayName.split(" - ");
        activityTitleForSHC =
          selectedProgramDisplayName[0] === "All Court Help Centers" &&
          data.seriesOptions.id;
        const colorId = _containerData.activities.findIndex((activity) => {
          return (
            activity.name === (data.seriesOptions && data.seriesOptions.id)
          );
        });
        const colorIndex = colorId > 14 ? colorId % 14 : colorId;
        const drilldownData = _containerData.drilldown;
        const defaultDrillDownData =
          drilldownData &&
          drilldownData.find(
            ({ id }) => id === (data.seriesOptions && data.seriesOptions.id)
          );
        const selectedColor =
          chart === "bar-chart" &&
          defaultDrillDownData &&
          defaultDrillDownData.data.length < 9
            ? colors[colorIndex]
            : null;

        modifiedDrilldownData =
          defaultDrillDownData && defaultDrillDownData.data
          ? defaultDrillDownData.data.sort((b, a)  => a[1] - b[1]).map((data) => {
                            return {
                              name: data[0],
                              y: data[1],
                              drilldown: null,
                              color: selectedColor,
                            };
                        })
            : [];
      }
      const drilldownData =
        viewType === "comparison"
          ? modifiedDrilldownData.length > 0
            ? modifiedDrilldownData[0].data
            : modifiedDrilldownData
          : [];
      this.setState((state) => {
        return {
          ...state,
          modifiedDrilldownData: modifiedDrilldownData,
          modifiedDrillDownStackPie: modifiedDrillDownStackPie,
          chartType: drilldownData.length > 8 ? "pie-chart" : chart,
          priviousChart:
            drilldownData.length > 8 ? state.chartType : _priviousChart,
          stackTitle: title,
          activityTitle: activityTitleForSHC,
          dilldownId: data.seriesOptions && data.seriesOptions.id,
          excessData:
            viewType === "comparison"
              ? drilldownData.length > 8
                ? true
                : false
              : false,
        };
      });
    }
  }
  onDrillUp() {
    const { containerData } = this.props;
    this.setState((state) => {
      return {
        ...state,
        modifiedDrilldownData: [],
        modifiedDrillDownStackPie: [],
        chartType:
          containerData.pieDrillDown &&
          containerData.pieDrillDown.length > 0 &&
          containerData.pieDrillDown.length > 8
            ? state.priviousChart
              ? state.priviousChart
              : state.chartType
            : "bar-chart",
        // chartType: state.priviousChart ? state.priviousChart : state.chartType,
        stackTitle: null,
        activityTitle: null,
        colorIndex: null,
        dilldownId: null,
        onOptionClick: false,
        excessData: containerData.excessData,
      };
    });
  }
  onClickOption(option) {
    const {
      modifiedDrilldownData,
      colors,
      dilldownId,
      modifiedDrillDownStackPie,
    } = this.state;
    const { containerData, viewType } = this.props;
    const _containerData = JSON.parse(JSON.stringify(containerData));
    const colorId = _containerData.activities.findIndex((activity) => {
      return activity.name === (dilldownId && dilldownId);
    });
    const colorIndex = colorId > 14 ? colorId % 14 : colorId;
    let modifiedData;

    if (option === "bar-chart" || option === "pie-chart") {
      modifiedData =
        viewType === "activity"
          ? option === "bar-chart"
            ? modifiedDrilldownData.reduce((acc, { name, y, drilldown }) => {
                acc.push({
                  name,
                  y,
                  drilldown,
                  color: colors[colorIndex],
                });
                return acc;
              }, [])
            : modifiedDrilldownData.reduce((acc, { name, y, drilldown }) => {
                acc.push({
                  name,
                  y,
                  drilldown,
                  color: null,
                });
                return acc;
              }, [])
          : modifiedDrilldownData;
      this.setState((state) => {
        return {
          ...state,
          chartType: option,
          onOptionClick: modifiedDrillDownStackPie.length > 0 ? true : false,
          modifiedDrilldownData: modifiedData,
        };
      });
    }
  }
  render() {
    const {
      subTitle,
      subMenuId,
      viewType,
      containerData,
      selectedSubMenuChild,
      selectedFilters = [],
    } = this.props;
    const {
      toggle,
      chartType,
      stackTitle,
      activityTitle,
      containerTitle,
      excessData,
      onOptionClick,
      modifiedDrilldownData,
      modifiedDrillDownStackPie,
    } = this.state;
    //below code is for sorting in descending order for activities
    if(containerData.activities){
      containerData.activities.sort( (a,b)=> (b.y > a.y) ? 1: -1);
    }
    const container = JSON.parse(JSON.stringify(containerData));
    
    const listNames =
      viewType === "data"
        ? [{ displayName: "CSV" }]
        : [
            { displayName: "PNG" },
            { displayName: "JPG" },
            { displayName: "PDF" },
          ];

    const selectedFilter =
      selectedFilters &&
      selectedFilters.find(({ type }) => type === "comparisonBy");
    const seletedComparision = selectedFilter && selectedFilter.value[0];
    const comparisionByMonthForLanguageService =
      parseInt(subMenuId) === 3 &&
      viewType === "comparison" &&
      (seletedComparision === "month" || seletedComparision === "Month")
        ? true
        : false;
    const _chartType =
      viewType === "comparison" &&
      chartType === "pie-chart" &&
      modifiedDrillDownStackPie.length > 0 &&
      onOptionClick === false
        ? "bar-chart"
        : chartType;
    const exdcludeOptions =
      viewType === "data"
        ? ["bar-chart", "refresh", "pie-chart"]
        : _chartType === "bar-chart"
        ? comparisionByMonthForLanguageService
          ? ["bar-chart", "refresh", "pie-chart", "download"]
          : ["bar-chart", "refresh", "download"]
        : excessData
        ? ["bar-chart", "refresh", "pie-chart", "download"]
        : ["pie-chart", "download"];
    const pieChartData =
      viewType === "comparison"
        ? container.pie
        : container.activities && container.activities;
    const pieDrilldownData =
      viewType === "comparison"
        ? container.pieDrillDown
        : container.drilldown && container.drilldown;
    const fiscalYears =
      viewType === "comparison" ? "fiscalYears" : "fiscalYear";
    const selectedYears =
      !(viewType === "data") &&
      selectedFilters &&
      selectedFilters.find(({ type }) => type === fiscalYears).value;
    const ByTerm =
      !(viewType === "data") &&
      selectedFilters &&
      selectedFilters.find(({ type }) => type === "filingBy").value[0] ===
        "term";
    const pieModifideDrillDown =
      viewType === "comparison"
        ? modifiedDrillDownStackPie
        : modifiedDrilldownData;
    const percentage =
      container.name === "clearanceRate" ||
      ((subTitle.name === "circuitCourt" ||
        subTitle.name === "districtCourt" ||
        subTitle.name === "courtOfSpecialAppeals" ||
        subTitle.name === "courtOfAppeals") &&
        (container.name === "percentageOfCasesTerminatedWithinStandard" ||
          container.name === "civilPercentageOfCasesTerminatedWithinStandard" ||
          container.name ===
            "appealsPercentageOfCasesTerminatedWithinStandard"))
        ? true
        : false;
    const taleDownloadOption =
      container.tableHeaders &&
      container.tableHeaders.length > 0 &&
      container.tableSections &&
      container.tableSections.length > 0
        ? true
        : false;
    const selectedCaseType =
      !(viewType === "data") &&
      selectedFilters &&
      selectedFilters.find(({ type }) => type === "caseType").value[0];
    const programSelectedOption =
      !(viewType === "data") &&
      selectedFilters &&
      selectedFilters.find(({ type }) => type === "program").value[0];
    const selectedProgramDisplayName =
      !(viewType === "data") && container.displayName.split(" - ");
    this.setState((state) => {
      return {
        containerTitle: activityTitle
          ? activityTitle
          : selectedProgramDisplayName[0],
      };
    });
    const boldTitleClass =
      (!(viewType === "data") &&
        programSelectedOption.toLowerCase() ===
          selectedProgramDisplayName[0].toLowerCase()) ||
      selectedProgramDisplayName[0] === "All Court Help Centers"
        ? true
        : false;
    return (
      <div className="application-chart-container">
        <div
          className="application-chart-container__chart-header"
          onClick={this.toggle}
        >
          <span className="application-chart-container__chart-header__title">
            {boldTitleClass ? (
              <React.Fragment>
                <span className="bold-title"> {containerTitle} </span>
                <span> - {selectedProgramDisplayName[1]} </span>
              </React.Fragment>
            ) : (
              container.displayName
            )}
          </span>
          <div className="application-chart-container__chart-header__icons">
            {viewType === "data" && taleDownloadOption && (
              <ApplicationChartOptions
                listNames={listNames}
                title={container.displayName}
                exdcludeOptions={exdcludeOptions}
                viewType={viewType}
                tabel={true}
                data={container}
              />
            )}
            <span
              className={
                toggle === true
                  ? "icon-chevron-up application-chart-container__chart-header__icons__down-arrow"
                  : "icon-chevron-down application-chart-container__chart-header__icons__down-arrow"
              }
            ></span>
          </div>
        </div>
        {toggle === true ? (
          !(viewType === "data") ? (
            /// For Activity & Comaprision View
            <React.Fragment>
              <div className={"application-chart-container__chart-body "}>
                <div className="graph" id={`${container.displayName}-title`}>
                  <p id={`${container.displayName}-heading`}> </p>
                  <div id={container.displayName}>
                    {_chartType === "bar-chart" ? (
                      viewType === "comparison" ? (
                        <ApplicationStackChart
                          viewType={viewType}
                          subTitle={subTitle}
                          listNames={listNames}
                          stackTitle={stackTitle}
                          onDrillUp={this.onDrillUp}
                          selectedYears={selectedYears}
                          title={container.displayName}
                          onDrilldown={this.onDrilldown}
                          footnotes={container.footnotes}
                          exdcludeOptions={exdcludeOptions}
                          onClickOption={this.onClickOption}
                          chartData={container.activities}
                          drilldownData={container.drilldown}
                          selectedSubMenuChild={selectedSubMenuChild}
                          modifiedDrilldownData={modifiedDrilldownData}
                        />
                      ) : (
                        <ApplicationBarChart
                          ByTerm={ByTerm}
                          subTitle={subTitle}
                          viewType={viewType}
                          listNames={listNames}
                          percentage={percentage}
                          onDrillUp={this.onDrillUp}
                          title={container.displayName}
                          name={container.name}
                          selectedYears={selectedYears}
                          onDrilldown={this.onDrilldown}
                          footnotes={container.footnotes}
                          chartData={container.activities}
                          exdcludeOptions={exdcludeOptions}
                          onClickOption={this.onClickOption}
                          selectedCaseType={selectedCaseType}
                          selectedSubMenuChild={selectedSubMenuChild}
                          modifiedDrilldownData={modifiedDrilldownData}
                          drilldownData={
                            container.drilldown && container.drilldown
                          }
                        />
                      )
                    ) : (
                      <ApplicationPieChart
                        type={viewType}
                        ByTerm={ByTerm}
                        subTitle={subTitle}
                        viewType={viewType}
                        listNames={listNames}
                        stackTitle={stackTitle}
                        percentage={percentage}
                        excessData={excessData}
                        chartData={pieChartData}
                        onDrillUp={this.onDrillUp}
                        name={container.name}
                        title={container.displayName}
                        selectedYears={selectedYears}
                        onDrilldown={this.onDrilldown}
                        footnotes={container.footnotes}
                        exdcludeOptions={exdcludeOptions}
                        onClickOption={this.onClickOption}
                        drilldownData={pieDrilldownData}
                        selectedSubMenuChild={selectedSubMenuChild}
                        modifiedDrilldownData={pieModifideDrillDown}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="chart-container-foot-notes">
                <ApplicationFootNoteDetails footnotes={container.footnotes} />
              </div>
            </React.Fragment>
          ) : (
            ///For Data View
            <ApplicationTabularData
              data={container}
              SubMenuChild={selectedSubMenuChild && selectedSubMenuChild.id}
            />
          )
        ) : (
          ""
        )}
      </div>
    );
  }
}
