import React from 'react';
import DashboardCountiesSelectionModal from '../DashboardCountiesSelectionModal';
import ApplicationCheckBoxButtons from '../../../helpers/ApplicationCheckBoxButtons';
import './index.scss';

export default class DashboardCountyFilters extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            selectedCountiesCount: this._getSelectedCountiesCount(this.props.selectedFilters),
            showModal: false
        }
        this.onModalClose = this.onModalClose.bind(this);
        this.onSelectCounties = this.onSelectCounties.bind(this);
    }
    _getSelectedCountiesCount(selectedFilters) {
        const _selectedCounties = selectedFilters.find((filter) => { return filter.type === 'county' }).value;
        const _selectedJurisdiction = selectedFilters.find((filter) => { return filter.type === 'jurisdiction' }).value;
        const isAllJurisdictionSelected = _selectedJurisdiction.find(({ value }) => value.toLowerCase() === "all jurisdiction")
        const _count = isAllJurisdictionSelected ? '24' : _selectedCounties.length
        return _count
    }
    onSelectCounties() {
        this.setState({
            showModal: true
        });
    }
    onModalClose() {
        this.setState({
            showModal: false
        });
    }
    componentWillReceiveProps(props) {
        let _count;
        const _selectedCounties = props.selectedFilters.find((filter) => { return filter.type === 'county' }).value;
        const _selectedJurisdictionType = props.selectedFilters.find(
            (filter) => { return filter.type === 'jurisdiction' }).value.map(({ value }) => value).toString();
        if (_selectedJurisdictionType.toLowerCase() === "all jurisdiction") {
            _count = 24
        } else if (_selectedJurisdictionType === '') {
            _count = 0
        }
        this.setState({
            selectedCountiesCount: _count ? _count : _selectedCounties.length
        })
    }

    render() {
        const { selectedMenu } = this.props;
        const options = [{ name: 'allcounties', value: 'All counties' }];
        const { showModal, selectedCountiesCount } = this.state;

        return (
            <div className='dashboard-county-filters'>
                {
                    selectedMenu.id !== 2 && (
                        <ApplicationCheckBoxButtons
                            options={options}
                            showHeaderAsSection={true}
                            headerCheckbox={options[0].value}
                            selectedOptions={selectedCountiesCount < 24 ? ['counties'] : ['All counties']}
                            onChange={this.props.onChange} />
                    )
                }
                <div className='dashboard-county-filters__selected-counties'>
                    {
                        selectedMenu.id !== 2 && (
                            <p className='dashboard-county-filters__selected-counties__label'>
                                Selected {selectedCountiesCount}
                            </p>
                        )
                    }
                    <p className='dashboard-county-filters__selected-counties__action' onClick={this.onSelectCounties}>
                        {selectedMenu.id === 2 ? "Select Locations" : "Select counties"}
                    </p>
                </div>
                {
                    showModal
                        ? <DashboardCountiesSelectionModal onClose={this.onModalClose} />
                        : null
                }
            </div>
        );
    }
}
