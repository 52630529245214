import React from 'react';
import './index.scss';

export default function ApplicationModalMobileFilterFooter({
    onClear,
    disabled,
    onSubmit,
    monthsDisabled,
    selectedFilterName,
    fiscalYearsDisabled
}) {
    function _getDisabledBySelectedFilterName(selectedFilterName) {
        if (selectedFilterName.toLowerCase() === 'county') {
            return disabled ? disabled : null
        } else if (selectedFilterName.toLowerCase() === 'fiscal years') {
            return fiscalYearsDisabled ? fiscalYearsDisabled : null
        } else if (selectedFilterName.toLowerCase() === 'months') {
            return monthsDisabled ? monthsDisabled : null
        }
    }
    function _getClassNameBySelectedFilterName(selectedFilterName) {
        if (selectedFilterName.toLowerCase() === 'county') {
            return (
                disabled
                    ? "application-modal-mobile-filter-footer__disabled"
                    : "application-modal-mobile-filter-footer__button"
            )
        } else if (selectedFilterName.toLowerCase() === 'fiscal years') {
            return (
                fiscalYearsDisabled
                    ? "application-modal-mobile-filter-footer__disabled"
                    : "application-modal-mobile-filter-footer__button"
            )
        } else if (selectedFilterName.toLowerCase() === 'months') {
            return (
                monthsDisabled
                    ? "application-modal-mobile-filter-footer__disabled"
                    : "application-modal-mobile-filter-footer__button"
            )
        }

    }

    return (
        <div className="application-modal-mobile-filter-footer">
            {
                ((selectedFilterName.toLowerCase() === 'county') ||
                    (selectedFilterName.toLowerCase() === 'fiscal years') ||
                    (selectedFilterName.toLowerCase() === 'months'))
                    ? <React.Fragment>
                        <button className="application-modal-mobile-filter-footer__button"
                            onClick={onClear}> clear
                        </button>
                        <button className={_getClassNameBySelectedFilterName(selectedFilterName)}
                            disabled={_getDisabledBySelectedFilterName(selectedFilterName)}
                            onClick={onSubmit}> submit
                        </button>
                    </React.Fragment>
                    : null
            }
        </div>
    )
}
