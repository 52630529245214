import Actions from "./actions";
import {
  convertDataToActivityView,
  convertDataToComparisionView,
} from "../../../utils/ChartService";

const defaultState = {
  selectedCategoryId: null,
  selectedSubCategoryId: null,
  selectedSubCategoryChildId: null,
  viewOption: null,
  menuList: [],
  counties: [],
  districts: [],
  circuit: [],
  jurisdictionList: [],
  filterList: [],
  dashboardFiltersList: [],
  selectedMenu: {},
  selectedSubMenu: {},
  selectedSubMenuChild: {},
  selectedChartType: "bar-chart",
  loadingDashboardDetails: false,
  onDetailsLoaded: false,
  dashboardChartsData: {},
  modifiedFilters: [],
  activityData: [],
  dashboardViewOptions: [],
  clearanceRateCounties: [
    { id: 1, value: "Anne Arundel" },
    { id: 2, value: "Caroline" },
    { id: 3, value: "Cecil" },
    { id: 4, value: "Dorchester" },
    { id: 5, value: "Kent" },
    { id: 6, value: "Queen Anne's" },
    { id: 7, value: "Talbot" },
    { id: 8, value: "Somerset" },
    { id: 9, value: "Wicomico" },
    { id: 10, value: "Worcester" },
  ],

  selectedFilters: [
    {
      type: "fiscalYear",
      value: [],
    },
    {
      type: "fiscalYears",
      value: [],
    },
    {
      type: "caseType",
      value: ["All Cases"],
    },
    {
      type: "filingBy",
      value: ["all"],
    },
    {
      type: "comparisonBy",
      value: ["year"],
    },
    {
      type: "jurisdiction",
      value: [_getDefaultJurisdiction()],
    },
    {
      type: "county",
      value: [],
    },
    {
      type: "selectBy",
      value: ["year"],
    },
    {
      type: "month",
      value: ["january"],
    },
    {
      type: "months",
      value: [],
    },
    {
      type: "months",
      value: [{ id: 1, value: "january" }],
    },
    {
      type: "program",
      value: ["Courtroom Services (Court Interpreter Program)"],
    },
  ],

  selectedDashboardViewOption: { viewTypeId: 1, displayName: "activity" },
};

function _getDefaultJurisdiction() {
  return { name: "jurisdiction", id: 1, value: "All Jurisdiction" };
}

function _getSelectedCountiesForSelectedJurisdictionType(
  selectedJurisdictionTypes,
  jurisdictoinTypeWithCounties
) {
  selectedJurisdictionTypes = selectedJurisdictionTypes.value.filter(
    ({ value }) => {
      return value.toLowerCase() !== "all jurisdiction";
    }
  );
  return selectedJurisdictionTypes.reduce(
    (accumlator, { value: selectedJurisdictionType }) => {
      const { countyList } = jurisdictoinTypeWithCounties.find(
        ({ displayName }) => {
          return displayName === selectedJurisdictionType;
        }
      );
      accumlator = accumlator.concat(countyList);
      return accumlator;
    },
    []
  );
}

// get list of jurisdiction from given counties
function _getSelectedJurisdictionFromCounties({
  selectedCounties,
  counties,
  jurisdictionType,
}) {
  if (selectedCounties.length === counties.length) {
    return [_getDefaultJurisdiction()];
  } else {
    const selectedJurisdiction = jurisdictionType.reduce(
      (accumlator, jurisdiction) => {
        const jurisdictionCounties = selectedCounties.reduce(
          (accumlator, county) => {
            const foundCounty = jurisdiction.countyList.find(
              ({ displayName }) => {
                return displayName === county.displayName;
              }
            );
            if (foundCounty) {
              accumlator.push(county);
            }
            return accumlator;
          },
          []
        );

        // all jurisdiction will have empty county list, hence exclude it
        if (
          jurisdiction.countyList.length > 0 &&
          jurisdictionCounties.length === jurisdiction.countyList.length
        ) {
          // this particular jurisdiction counties matches in the selected counties list
          accumlator.push({
            name: "jurisdiction",
            id: jurisdiction.jurisdictionId,
            value: jurisdiction.displayName,
          });
        }
        return accumlator;
      },
      []
    );
    return selectedJurisdiction;
  }
}

function _getUpdatedFilterData(
  exisitingSelectedFilters,
  currentSelectedFilter,
  currentSelectedCounties
) {
  exisitingSelectedFilters = [...exisitingSelectedFilters];
  const index = exisitingSelectedFilters.findIndex((filter) => {
    return filter.type === currentSelectedFilter.type;
  });
  const _countyIndex = exisitingSelectedFilters.findIndex((filter) => {
    return filter.type === "county";
  });

  if (currentSelectedFilter.type !== "county") {
    exisitingSelectedFilters[index] = currentSelectedFilter;
  }
  if (
    currentSelectedCounties !== undefined &&
    currentSelectedCounties !== null
  ) {
    exisitingSelectedFilters[_countyIndex].value = currentSelectedCounties;
  }
  return exisitingSelectedFilters;
}

function DashboardChartsDataReducer(state, action) {
  switch (action.type) {
    case Actions.ON_ACTIVITY_CALLED:
      return {
        ...state,
        activityData: action.data,
      };
    case Actions.RESET_DASHBOARD_ACTIVITY:
      return {
        ...state,
        activityData: defaultState.activityData,
      };
    case Actions.ON_DASHBOARD_DATA_LOADED:
      let activity;
      if (
        state.selectedDashboardViewOption.displayName.toLowerCase() ===
        "comparison"
      ) {
        const selectedFilter = state.selectedFilters.find(
          ({ type }) => type === "comparisonBy"
        ).value[0];
        const comparisionByMonth =
          selectedFilter === "month" || selectedFilter === "Month"
            ? true
            : false;
        activity = convertDataToComparisionView(
          { ...action.data },
          comparisionByMonth
        );
      } else {
        activity = convertDataToActivityView({ ...action.data });
      }
      return {
        ...state,
        loadingDashboardDetails: false,
        onDetailsLoaded: true,
        dashboardChartsData: action.data,
        activityData: activity,
      };
    default:
      return state;
  }
}

function DashboardFilterReducer(state, action) {
  let modifiedJurisdictionType = [];
  let modifiedSelectedCounties = [];
  let _modifiedJurisdictionType = {};

  switch (action.type) {
    case Actions.ON_DASHBOARD_FISCAL_YEAR_FILTER_CHANGE:
      return {
        ...state,
        loadingDashboardDetails: true,
        selectedFilters: [
          ..._getUpdatedFilterData(state.selectedFilters, action.data),
        ],
      };

    case Actions.ON_DASHBOARD_PROGRAM_FILTER_CHANGE:
      return {
        ...state,
        loadingDashboardDetails: true,
        selectedFilters: [
          ..._getUpdatedFilterData(state.selectedFilters, action.data),
        ],
      };

    case Actions.ON_DASHBOARD_MULTIPLE_FISCAL_YEARS_CHANGE:
      return {
        ...state,
        loadingDashboardDetails: true,
        selectedFilters: [
          ..._getUpdatedFilterData(state.selectedFilters, action.data),
        ],
      };

    case Actions.ON_DASHBOARD_MULTIPLE_MONTHS_CHANGE:
      return {
        ...state,
        loadingDashboardDetails: true,
        selectedFilters: [
          ..._getUpdatedFilterData(state.selectedFilters, action.data),
        ],
      };

    case Actions.ON_DASHBOARD_CASE_TYPE_FILTER_CHANGE:
      return {
        ...state,
        loadingDashboardDetails: true,
        selectedFilters: [
          ..._getUpdatedFilterData(state.selectedFilters, action.data),
        ],
      };

    case Actions.ON_DASHBOARD_FILINGS_BY_FILTER_CHANGE:
      return {
        ...state,
        loadingDashboardDetails: true,
        selectedFilters: [
          ..._getUpdatedFilterData(state.selectedFilters, action.data),
        ],
      };

    case Actions.ON_DASHBOARD_SELECT_BY_FILTER_CHANGE:
      return {
        ...state,
        loadingDashboardDetails: true,
        selectedFilters: [
          ..._getUpdatedFilterData(state.selectedFilters, action.data),
        ],
      };

    case Actions.ON_DASHBOARD_MONTH_FILTER_CHANGE:
      return {
        ...state,
        loadingDashboardDetails: true,
        selectedFilters: [
          ..._getUpdatedFilterData(state.selectedFilters, action.data),
        ],
      };

    case Actions.ON_DASHBOARD_COMPARSION_BY_FILTER_CHANGE:
      return {
        ...state,
        loadingDashboardDetails: true,
        selectedFilters: [
          ..._getUpdatedFilterData(state.selectedFilters, action.data),
        ],
      };

    case Actions.ON_DASHBOARD_COUNTY_TYPE_FILTER_CHANGE:
      let isAllCountiesSelected = {};
      if (action.data.selectedOptions) {
        isAllCountiesSelected = {
          type: "jurisdiction",
          value: [_getDefaultJurisdiction()],
        };
      }
      return {
        ...state,
        loadingDashboardDetails: true,
        selectedFilters: [
          ..._getUpdatedFilterData(
            state.selectedFilters,
            isAllCountiesSelected,
            []
          ),
        ],
      };

    case Actions.ON_DASHBOARD_JURISDICTION_TYPE_FILTER_CHANGE:
      const _modifiedJurisdictionFilters =
        action.data.value.length === 5
          ? { type: action.data.type, value: [_getDefaultJurisdiction()] }
          : action.data;

      modifiedSelectedCounties =
        _getSelectedCountiesForSelectedJurisdictionType(
          _modifiedJurisdictionFilters,
          state.jurisdictionList
        );
      return {
        ...state,
        loadingDashboardDetails: true,
        selectedFilters: [
          ..._getUpdatedFilterData(
            state.selectedFilters,
            _modifiedJurisdictionFilters,
            modifiedSelectedCounties
          ),
        ],
      };

    case Actions.ON_DASHBOARD_SELECTED_FILTER_CLOSE:
      //for selected counties
      const _selectedCounties = state.selectedFilters.find((filter) => {
        return filter.type === "county";
      }).value;
      modifiedSelectedCounties = _selectedCounties.filter(
        (option) => option.displayName !== action.data.title
      );
      //for selected fiscal years
      const _selectedFiscalYears = state.selectedFilters.find((filter) => {
        return filter.type === "fiscalYears";
      }).value;
      // const _selectedMonths = state.selectedFilters.find((filter) => { return filter.type === 'months' }).value;
      const modifiedSelectedFiscalYears = _selectedFiscalYears.filter(
        (option) => option.value !== action.data.title
      );

      modifiedJurisdictionType = _getSelectedJurisdictionFromCounties({
        selectedCounties: modifiedSelectedCounties,
        counties: state.counties,
        jurisdictionType: state.jurisdictionList,
      });
      _modifiedJurisdictionType =
        action.data.typeName === "county"
          ? { type: "jurisdiction", value: modifiedJurisdictionType }
          : { type: "fiscalYears", value: modifiedSelectedFiscalYears };

      return {
        ...state,
        loadingDashboardDetails: true,
        selectedFilters: [
          ..._getUpdatedFilterData(
            state.selectedFilters,
            _modifiedJurisdictionType,
            modifiedSelectedCounties
          ),
        ],
      };

    case Actions.ON_DASHBOARD_DISTRICT_TYPE_FILTER_CHANGE:
      const { counties } = { ...state };
      modifiedJurisdictionType = _getSelectedJurisdictionFromCounties({
        selectedCounties: action.data,
        counties,
        jurisdictionType: state.jurisdictionList,
      });
      // _modifiedJurisdictionType = { type: 'jurisdiction', value: modifiedJurisdictionType }
      _modifiedJurisdictionType = {
        type: "jurisdiction",
        value:
          modifiedJurisdictionType.length === 4
            ? [_getDefaultJurisdiction()]
            : modifiedJurisdictionType,
      };
      return {
        ...state,
        loadingDashboardDetails: true,
        selectedFilters: [
          ..._getUpdatedFilterData(
            state.selectedFilters,
            _modifiedJurisdictionType,
            action.data
          ),
        ],
      };

    default:
      return state;
  }
}

function DashboardMenuReducer(state, action) {
  switch (action.type) {
    case Actions.INITIALIZE_DASHBOARD:
      return {
        ...state,
        viewOption: action.data.viewOption,
        selectedCategoryId: action.data.categoryId,
        selectedSubCategoryId: action.data.subCategoryId,
        selectedSubCategoryChildId: action.data.subCategoryChildId,
      };
    case Actions.ON_TITLE_SUBTITLE_UPDATE:
      return {
        ...state,
        selectedMenu: action.title,
        selectedSubMenu: action.subTitle ? action.subTitle : [],
      };
    case Actions.ON_SUBMENU_OPEN:
      const menuOptions = state.menuList.map((option) => {
        if (option.displayName === action.data.title.displayName) {
          if (action.data.subMenuChild) {
            const child = option.children.map((children) => {
              if (
                children.displayName === action.data.subMenuChild.displayName
              ) {
                return {
                  ...children,
                  isOpen: !children.isOpen,
                  isSelected: true,
                };
              } else {
                return {
                  ...children,
                  isOpen: false,
                  isSelected: false,
                };
              }
            });
            return {
              ...option,
              children: child,
            };
          } else {
            return {
              ...option,
              isOpen: !option.isOpen,
              isSelected: true,
            };
          }
        } else {
          return {
            ...option,
            isOpen: false,
            isSelected: false,
          };
        }
      });
      return {
        ...state,
        menuList: [...menuOptions],
      };
    default:
      return state;
  }
}

export default function DashboardReducer(state = defaultState, action) {
  let dashboardFiltersList = [];
  let selectedFilters = [];

  switch (action.type) {
    case Actions.LOAD_DASHBOARD_DATA:
      const updatedMenuList = action.data.menuList.map((menu) => {
        const isSelected = menu.id === parseInt(state.selectedCategoryId);
        const childrens = menu.children.map((child) => {
          if (child.children.length > 0) {
            return {
              ...child,
              isopen: false,
              isSelected: isSelected,
            };
          } else {
            return child;
          }
        });
        return {
          ...menu,
          children: childrens,
          isopen: false,
          isSelected: isSelected,
        };
      });
      const {
        viewOption,
        selectedCategoryId,
        selectedSubCategoryId,
        selectedSubCategoryChildId,
      } = state;
      const {
        menuList,
        viewOptions,
        isPreviewMode = false,
        previewFiscalYears = null,
      } = action.data;

      const selectedMenu = menuList.find(
        (list) => list.id === parseInt(selectedCategoryId)
      );
      const selectedSubMenu =
        selectedMenu && selectedMenu.children
          ? selectedMenu.children.find(
              (children) => children.id === parseInt(selectedSubCategoryId)
            )
          : null;
      const selectedSubMenuChild =
        selectedSubMenu &&
        selectedSubMenu.children &&
        selectedSubMenu.children.length > 0
          ? selectedSubCategoryChildId
            ? selectedSubMenu.children.find(
                (children) =>
                  children.id === parseInt(selectedSubCategoryChildId)
              )
            : selectedSubMenu.children[0]
          : null;
      const selectedDashboardViewOption = viewOption
        ? viewOptions.find(
            (option) =>
              option.displayName.toLowerCase() === viewOption.toLowerCase()
          )
        : viewOptions[0].viewTypeId;
      dashboardFiltersList = [...action.data.filterList];

      const selectedId = selectedSubMenuChild
        ? selectedSubMenuChild.id
        : selectedSubCategoryId;

      if (isPreviewMode) {
        const fiscalYearOptionIndex = dashboardFiltersList.findIndex(
          ({ type, categoryId, viewTypeId }) =>
            (type === "fiscalYear" || type === "fiscalYears") &&
            categoryId === selectedId &&
            viewTypeId === selectedDashboardViewOption.viewTypeId
        );

        const previewFiscalYearOption = previewFiscalYears;

        if (fiscalYearOptionIndex !== -1) {
          if (previewFiscalYearOption && previewFiscalYearOption.length > 0) {
            let modifiedFiscalYearOptions = [];
            const fiscalYearOptions =
              dashboardFiltersList[fiscalYearOptionIndex].optionList;

            modifiedFiscalYearOptions = fiscalYearOptions.filter(
              ({ displayName }) => {
                return previewFiscalYearOption.find(
                  (value) => parseInt(displayName) === parseInt(value)
                );
              }
            );
            dashboardFiltersList[fiscalYearOptionIndex].optionList = [
              ...modifiedFiscalYearOptions,
            ];
          }
        }
      }

      dashboardFiltersList = dashboardFiltersList.filter(
        (filter) =>
          filter.categoryId === parseInt(selectedId) &&
          filter.viewTypeId === selectedDashboardViewOption.viewTypeId
      );
      let _selectedFiscalYearValue1 = [
        ...state.selectedFilters.filter(({ type }) => type === "fiscalYear"),
      ];
      let _selectedFiscalYearsValues = [
        ...state.selectedFilters.filter(({ type }) => type === "fiscalYears"),
      ];
      selectedFilters = dashboardFiltersList.reduce(
        (accum, value) => {
          if (
            value.type !== "jurisdiction" &&
            value.type !== "county" &&
            value.type !== "program" &&
            value.type !== "selectBy"
          ) {
            const type = value.type;
            const valueIndex = accum.findIndex((value) => value.type === type);
            if (valueIndex !== -1) {
              if (type === "fiscalYears") {
                const _filterSelectedFiscalYearsValues =
                  value.optionList.reduce((acc, years) => {
                    let filterValues =
                      _selectedFiscalYearsValues[0].value.filter(
                        (filter) => filter.value === years.key
                      );
                    acc = acc.concat(filterValues);
                    return acc;
                  }, []);
                accum[valueIndex].value =
                  _filterSelectedFiscalYearsValues.length > 0
                    ? _filterSelectedFiscalYearsValues
                    : value.optionList && value.optionList.length > 0
                    ? [
                        {
                          id: value.optionList[0].optionId,
                          value: value.optionList[0].displayName,
                        },
                      ]
                    : [];
              } else if (type === "months") {
                accum[valueIndex].value =
                  value.optionList && value.optionList.length > 0
                    ? [
                        {
                          id: value.optionList[0].optionId,
                          value: value.optionList[0].displayName,
                        },
                      ]
                    : [];
              } else if (type === "fiscalYear") {
                const _filterSelectedFiscalValue =
                  value.optionList &&
                  value.optionList.filter(
                    (filter) =>
                      filter.key === _selectedFiscalYearValue1[0].value[0]
                  );
                accum[valueIndex].value =
                  _filterSelectedFiscalValue &&
                  _filterSelectedFiscalValue.length === 0
                    ? [value.optionList[0].displayName]
                    : [_selectedFiscalYearValue1[0].value[0]];
              } else {
                accum[valueIndex].value =
                  value.optionList && value.optionList.length > 0
                    ? [value.optionList[0].displayName]
                    : [];
              }
            }
          }
          return accum;
        },
        [...state.selectedFilters]
      );

      return {
        ...state,
        loadingDashboardDetails: true,
        onDetailsLoaded: false,
        menuList: [...updatedMenuList],
        filterList: [...action.data.filterList],
        counties: [...action.data.counties],
        districts: [...action.data.districts],
        circuit: [...action.data.circuit],
        jurisdictionList: [...action.data.jurisdictionList],
        dashboardViewOptions: action.data.viewOptions,
        dashboardFiltersList: [...dashboardFiltersList],
        selectedMenu,
        selectedSubMenu,
        selectedSubMenuChild,
        selectedDashboardViewOption,
        selectedFilters: [...selectedFilters],
      };

    //set selected counties and jurisdiction values
    case Actions.SET_COUNTIES_AND_JURISDICTION_VALUES:
      if (action.data.length > 0) {
        selectedFilters = [...action.data].reduce(
          (accum, value) => {
            const countyIndex = accum.findIndex(
              (value) => value.type === "county"
            );
            const jurisdictionIndex = accum.findIndex(
              (value) => value.type === "jurisdiction"
            );
            if (countyIndex !== -1) {
              accum[countyIndex].value = [value];
              accum[jurisdictionIndex].value = [];
            }
            return accum;
          },
          [...state.selectedFilters]
        );
      } else {
        selectedFilters = [...state.selectedFilters];
        const countyIndex = selectedFilters.findIndex(
          (value) => value.type === "county"
        );
        const jurisdictionIndex = selectedFilters.findIndex(
          (value) => value.type === "jurisdiction"
        );
        selectedFilters[countyIndex].value = action.data;
        selectedFilters[jurisdictionIndex].value = [_getDefaultJurisdiction()];
      }
      return {
        ...state,
        selectedFilters: [...selectedFilters],
      };
    case Actions.SET_PROGRAM_VALUES:
      selectedFilters = [...state.selectedFilters];
      const programIndex = selectedFilters.findIndex(
        (value) => value.type === "program"
      );
      selectedFilters[programIndex].value = action.data;
      return {
        ...state,
        selectedFilters: [...selectedFilters],
      };
    case Actions.INITIALIZE_DASHBOARD:
    case Actions.ON_SUBMENU_OPEN:
    case Actions.ON_TITLE_SUBTITLE_UPDATE:
      return DashboardMenuReducer(state, action);
    case Actions.ON_DASHBOARD_SUB_MENU_CHANGE:
    case Actions.ON_DASHBOARD_MONTH_FILTER_CHANGE:
    case Actions.ON_DASHBOARD_PROGRAM_FILTER_CHANGE:
    case Actions.ON_DASHBOARD_SELECTED_FILTER_CLOSE:
    case Actions.ON_DASHBOARD_MULTIPLE_MONTHS_CHANGE:
    case Actions.ON_DASHBOARD_SELECT_BY_FILTER_CHANGE:
    case Actions.ON_DASHBOARD_CASE_TYPE_FILTER_CHANGE:
    case Actions.ON_DASHBOARD_FILINGS_BY_FILTER_CHANGE:
    case Actions.ON_DASHBOARD_COUNTY_TYPE_FILTER_CHANGE:
    case Actions.ON_DASHBOARD_FISCAL_YEAR_FILTER_CHANGE:
    case Actions.ON_DASHBOARD_DISTRICT_TYPE_FILTER_CHANGE:
    case Actions.ON_DASHBOARD_COMPARSION_BY_FILTER_CHANGE:
    case Actions.ON_DASHBOARD_MULTIPLE_FISCAL_YEARS_CHANGE:
    case Actions.ON_DASHBOARD_JURISDICTION_TYPE_FILTER_CHANGE:
    case Actions.ON_DASHBOARD_COMMISSIONER_DATA_FISCAL_YEAR_CHANGE:
      return DashboardFilterReducer(state, action);
    case Actions.ON_DASHBOARD_CHART_TYPE_CLICK:
    case Actions.ON_DASHBOARD_DATA_LOADED:
    case Actions.ON_ACTIVITY_CALLED:
    case Actions.ON_DRILLUP_CLICKED:
    case Actions.ON_DRILLDOWN_CLICKED:
    case Actions.RESET_DASHBOARD_ACTIVITY:
      return DashboardChartsDataReducer(state, action);
    case Actions.RESET_DASHBOARD_STATE:
      const selectedCountyAndJurisdiction = [
        ...state.selectedFilters.filter(
          ({ type }) => type === "county" || type === "jurisdiction"
        ),
      ];
      let selectedFiscalYearValue = [
        ...state.selectedFilters.filter(({ type }) => type === "fiscalYear"),
      ];
      let selectedFiscalYearsValue = [
        ...state.selectedFilters.filter(({ type }) => type === "fiscalYears"),
      ];
      let selectedProgramValue = [
        ...state.selectedFilters.filter(({ type }) => type === "program"),
      ];
      let selectedSelectByValue = [
        ...state.selectedFilters.filter(({ type }) => type === "selectBy"),
      ];

      selectedFilters = [
        ...defaultState.selectedFilters.filter(
          ({ type }) =>
            type !== "county" &&
            type !== "jurisdiction" &&
            type !== "fiscalYear" &&
            type !== "fiscalYears" &&
            type !== "program" &&
            type !== "selectBy"
        ),
        ...selectedCountyAndJurisdiction,
        ...selectedFiscalYearValue,
        ...selectedFiscalYearsValue,
        ...selectedProgramValue,
        ...selectedSelectByValue,
      ];
      return {
        ...defaultState,
        selectedFilters,
      };
    default:
      return state;
  }
}
