import React from 'react';
import { connect } from 'react-redux';
import { onDashboardSelectedFilterClose } from '../store/dispatchers';
import ApplicationPageLayout from '../../../helpers/ApplicationPageLayout';
import './index.scss';

function DashboardSelectedFilters ({    
    selectedFilters,
    dashboardFiltersList,
    onDashboardSelectedFilterClose,
    isPreviewMode = false
}) {
    function getSelectedFiscalYearOption(selectedYear) {        
        return { title: selectedYear, showCloseOption:false }    
    }
    function getSelectedCaseTypeOptions(selectedCaseType) {
        return { title: selectedCaseType, showCloseOption:false };
    }

    function getSelectedFilingByOptions(selectedFilingBy) {
        return { title: selectedFilingBy, showCloseOption: false };
    }

    function getSelectedSelectByOptions(selectedSelectByValue) {
        return { title: selectedSelectByValue, showCloseOption: false };
    }

    function getSelectedMonthOption(selectedMonth) {
        return { title: selectedMonth, showCloseOption: false };
    }

    function getSelectedProgramOption(selectedProgram) {
        return { title: selectedProgram, showCloseOption: false };
    }

    function getselectedFiscalYearsOptions(selectedFilters) {
        const selectedFiscalYears = selectedFilters.find((value) => value.type === 'fiscalYears').value;
        if(selectedFiscalYears.length === 1) {
            return selectedFiscalYears.map(({value}) => ( { title: value, showCloseOption: false, typeName: 'fiscalYears'}))
        } else {
            return selectedFiscalYears.map(({value}) => ( { title: value, showCloseOption: true, typeName: 'fiscalYears' }))
        }
    }

    function getselectedMonthsOptions(selectedFilters) {
        const selectedMonths = selectedFilters.find((value) => value.type === 'months').value;
        if(selectedMonths.length === 1) {
            return selectedMonths.map(({value}) => ( { title: value, showCloseOption: false, typeName: 'months'}))
        } else {
            return selectedMonths.map(({value}) => ( { title: value, showCloseOption: true, typeName: 'months' }))
        }
    }

    function getSelectedCounties(jurisdictionType, counties) {
        const hasAllJurisdiction = jurisdictionType.find(({ value }) => value.toLowerCase() === 'all jurisdiction');
        if(hasAllJurisdiction) {
            return [ {title: "all counties", showCloseOption:false} ];
        } else {
            if(counties.length === 1) {
                return counties.map(({ displayName }) => ( { title: displayName, showCloseOption: false, typeName: 'county' } ) );
            } else {
                return counties.map(({ displayName }) => ( { title: displayName, showCloseOption: true, typeName: 'county' } ) );
            }
        }        
    }
    //new code
    function _getSelectedValues(selectedFilters, type) {
        const  _selectedValues = selectedFilters.find((value) => value.type === type);
        const _value = _selectedValues.value.find((value) => value);
        return _value
    }

    function _getSelectedCountiesObject(selectedFilters) {
        const _selectedCounties = selectedFilters.find((filter) => { return filter.type === 'county' }).value;
        const _jurisdictionType = selectedFilters.find((filter) => { return filter.type === 'jurisdiction' }).value;
        return getSelectedCounties(_jurisdictionType, _selectedCounties)
    }

    function getSelectedDashboardValues (
        filterName, 
        selectedProgramOption,
        selectedCountiesOject, 
        selectedCaseTypeOption,
        selectedMonthsOptions,
        selectedFilingByOption, 
        selectedSelectByOption, 
        selectedFiscalYearOption, 
        selectedFiscalYearsOptions,
    ) {   
        switch(filterName) {
            case 'county': 
                return selectedCountiesOject
            case 'caseType':
                return [selectedCaseTypeOption];
            case 'filingBy':
                return [selectedFilingByOption];
            case 'fiscalYear':
                return [selectedFiscalYearOption];
            case 'fiscalYears':
                return selectedFiscalYearsOptions;
            case 'months':
                return selectedMonthsOptions;
            case 'selectBy':
                return [selectedSelectByOption];
            case 'program':
                return [selectedProgramOption];
            default:
                return null
        }
    }

    const selectedMonthsOptions = getselectedMonthsOptions(selectedFilters);
    const selectedCountiesOject = _getSelectedCountiesObject(selectedFilters);
    const selectedFiscalYearsOptions = getselectedFiscalYearsOptions(selectedFilters);
    const selectedMonthOption = getSelectedMonthOption(_getSelectedValues(selectedFilters, 'month'));
    const selectedProgramOption = getSelectedProgramOption(_getSelectedValues(selectedFilters, 'program'));
    const selectedFilingByOption = getSelectedFilingByOptions(_getSelectedValues(selectedFilters, 'filingBy'));
    const selectedSelectByOption = getSelectedSelectByOptions(_getSelectedValues(selectedFilters, 'selectBy'));
    const selectedCaseTypeOption =  getSelectedCaseTypeOptions(_getSelectedValues(selectedFilters, 'caseType'));
    const selectedFiscalYearOption = getSelectedFiscalYearOption(_getSelectedValues(selectedFilters, 'fiscalYear'));
    const _selectedSelectByOption = selectedSelectByOption.title.toLowerCase() === "month" ? selectedMonthOption : selectedSelectByOption;

    // get selected Dashboard filters list
    const selectedDashboardFilters = dashboardFiltersList && dashboardFiltersList.reduce((accum, {type}) => {
        const selectedDashboardValues = getSelectedDashboardValues(
            type, 
            selectedProgramOption,
            selectedCountiesOject, 
            selectedCaseTypeOption,
            selectedMonthsOptions,
            selectedFilingByOption,
            _selectedSelectByOption, 
            selectedFiscalYearOption, 
            selectedFiscalYearsOptions
        );
        if(selectedDashboardValues) {
            accum = accum.concat(selectedDashboardValues);
        }
        return accum;
    }, []);
    return selectedDashboardFilters.length > 0 ? 
        (
           <React.Fragment>
               {
                    isPreviewMode ? <div className = 'preview-heading'> Preview </div> : null
               }
                <div className = "dashboard-selected-filters">
                    <ApplicationPageLayout>
                        <div className = "dashboard-selected-filters__container">
                            {
                                selectedDashboardFilters.map((filter, index) => {
                                 
                                    const { showCloseOption = true, title } = filter;
                                    const closeOptionContainer = showCloseOption === true ? (
                                        <span 
                                            onClick = {(e) => onDashboardSelectedFilterClose(filter)} 
                                            className = "icon-cancel dashboard-selected-filters__close">
                                        </span>
                                    ) : '';

                                    return (
                                        <div key = {index} className = "dashboard-selected-filters__title">
                                            <span className = "dashboard-selected-filters__title-content"> {title} </span>
                                            {closeOptionContainer}
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </ApplicationPageLayout>
                </div>
           </React.Fragment>        
    ) : null;
};

const mapStateToProps = ({ dashboard }) => {
    return {
        selectedFilters: dashboard.selectedFilters,
        dashboardFiltersList: dashboard.dashboardFiltersList
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onDashboardSelectedFilterClose(event) {
            dispatch(onDashboardSelectedFilterClose(event))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardSelectedFilters);