import React, { Component } from 'react';
import { connect } from 'react-redux';
import DashboardCountyFilters from '../DashboardCountyFilters';
import DashboardProgramFilters from '../DashboardProgramFilters';
import DashboardSelectByFilters from '../DashboardSelectByFilters';
import DashboardCaseTypeFilters from '../DashboardCaseTypeFilters';
import DashboardFilingsByFilters from '../DashboardFilingsByFilters';
import DashboardMonthWiseFilters from '../DashboardMonthWiseFilters';
import DashboardFiscalYearFilters from '../DashboardFiscalYearFilters';
import DashboardComparsionByFilters from '../DashboardComparsionByFilters';
import DashboardMultipleMonthsFilters from '../DashboardMultipleMonthsFilters';
import DashboardJurisdictionTypeFilters from '../DashboardJurisdictionTypeFilters';
import ApplicationDesktopFilters from '../../../helpers/ApplicationDesktopFilters';
import DashboardMultipleFiscalYearsFilters from '../DashboardMultipleFiscalYearsFilters';
import ApplicationDesktopFilterSection from '../../../helpers/ApplicationDesktopFilterSection';
import {
    onDashboardMonthFilterChange,
    onDashboardProgramFilterChange,
    onDashboardMultipleMonthsChange,
    onDashboardCaseTypeFilterChange,
    onDashboardSelectByFilterChange,
    onDashboardFilingsByFilterChange,
    onDashboardFiscalYearFilterChange,
    onDashboardCountyTypeFilterChange,
    onDashboardComparsionByFilterChange,
    onDashboardMultipleFiscalYearsChange,
    onDashboardJurisdictionTypeFilterChange
} from '../store/dispatchers';
import './index.scss';

class DashboardDesktopFilters extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dropdownTitle: { id: 1, displayName: 2018 }
        }
        this.onFiscalYearDropdownChange = this.onFiscalYearDropdownChange.bind(this);
    }
    onFiscalYearDropdownChange(option) {
        this.props.onDashboardFiscalYearFilterChange({ name: 'fiscalYear', value: option });
        this.setState({
            dropdownTitle: { id: option, displayName: option }
        });
    }

    _getSelectedFilterValue(selectedFilters, type) {
        const _selectedOption = selectedFilters.find((selectedFilter) => selectedFilter.type === type);
        switch (type) {
            case 'month':
            case 'months':
            case 'program':
            case 'caseType':
            case 'filingBy':
            case 'selectBy':
            case 'fiscalYear':
            case 'fiscalYears':
            case 'jurisdiction':
            case 'comparisonBy':
                return _selectedOption.value
            default:
                return null;
        }
    }

    getCaseTypeFilterChangeValue(option) {
        const { onDashboardCaseTypeFilterChange, onDashboardFilingsByFilterChange } = this.props;
        if (option.name === "caseType" && option.value !== "term") {
            onDashboardCaseTypeFilterChange(option)
        } else if (option.name === "filingBy") {
            if (option.value === "term") {
                onDashboardCaseTypeFilterChange({ name: "caseType", value: "All Cases" })
                onDashboardFilingsByFilterChange(option)
            } else {
                onDashboardFilingsByFilterChange(option)
            }
        }

    }

    getFilterComponent(filterType, options, selectedFilters, selectedMenu) {
        //for COSA options
        const isFilingsByTermSelected = selectedFilters.find((filter) => filter.type === 'filingBy').value[0] === 'term';
        const _options = this.props.subTitle && this.props.subTitle.displayName === "Court Of Special Appeals" && options ? options.filter(({ key }) => key.includes('cosa')) : null
        const caseTypeOptions = _options && _options.filter(({ displayName }) => displayName !== "Juvenile");

        switch (filterType) {
            case 'fiscalYear':
                return (
                    <DashboardFiscalYearFilters
                        options={options}
                        onChange={this.props.onDashboardFiscalYearFilterChange}
                        selectedOption={this._getSelectedFilterValue(selectedFilters, 'fiscalYear')}
                    />
                );
            case 'fiscalYears':
                return (
                    <DashboardMultipleFiscalYearsFilters
                        options={options}
                        onChange={this.props.onDashboardMultipleFiscalYearsChange}
                        selectedOptions={this._getSelectedFilterValue(selectedFilters, filterType)}
                    />
                );
            case 'months':
                return (
                    <DashboardMultipleMonthsFilters
                        options={options}
                        onChange={this.props.onDashboardMultipleMonthsChange}
                        selectedOptions={this._getSelectedFilterValue(selectedFilters, filterType)}
                    />
                );
            case 'caseType':
                return (
                    <DashboardCaseTypeFilters
                        options={isFilingsByTermSelected ? caseTypeOptions : options}
                        onChange={(option) => this.getCaseTypeFilterChangeValue(option)}
                        selectedOption={this._getSelectedFilterValue(selectedFilters, filterType)}
                    />
                );
            case 'program':
                return (
                    <DashboardProgramFilters
                        options={options}
                        onChange={this.props.onDashboardProgramFilterChange}
                        selectedOption={this._getSelectedFilterValue(selectedFilters, filterType)}
                    />
                );
            case 'filingBy':
                return (
                    <DashboardFilingsByFilters
                        options={options}
                        onChange={(option) => this.getCaseTypeFilterChangeValue(option)}
                        selectedOption={this._getSelectedFilterValue(selectedFilters, filterType)}
                    />
                );
            case 'comparisonBy':
                return (
                    <DashboardComparsionByFilters
                        options={options}
                        onChange={this.props.onDashboardComparsionByFilterChange}
                        selectedOption={this._getSelectedFilterValue(selectedFilters, filterType)}
                    />
                );
            case 'county':
                return (
                    <DashboardCountyFilters
                        selectedMenu={selectedMenu}
                        selectedFilters={selectedFilters}
                        onChange={this.props.onDashboardCountyTypeFilterChange}
                    />
                );
            case 'jurisdiction':
                return (
                    <DashboardJurisdictionTypeFilters
                        options={options}
                        onChange={this.props.onDashboardJurisdictionTypeFilterChange}
                        selectedOptions={this._getSelectedFilterValue(selectedFilters, filterType)}
                    />
                );
            case 'selectBy':
                return (
                    <DashboardSelectByFilters
                        options={options}
                        filterType={filterType}
                        onChange={this.props.onDashboardSelectByFilterChange}
                        selectedOption={this._getSelectedFilterValue(selectedFilters, 'selectBy')}
                    />
                );
            case "month":
                return (
                    <DashboardMonthWiseFilters
                        options={options}
                        onChange={this.props.onDashboardMonthFilterChange}
                        selectedOption={this._getSelectedFilterValue(selectedFilters, 'month')}
                    />
                )
            default:
                return null;
        }
    }
    render() {
        const { selectedMenu, selectedFilters, dashboardFiltersList } = this.props;
        const enableMonthOptionForActivityView = this._getSelectedFilterValue(selectedFilters, 'selectBy')[0].toLowerCase() === 'month';
        const enableMonthsForComparisonView = this._getSelectedFilterValue(selectedFilters, 'comparisonBy')[0].toLowerCase() === 'month';
        let _dashboardFiltersList = [...dashboardFiltersList];
        if (!enableMonthOptionForActivityView) {
            _dashboardFiltersList = _dashboardFiltersList.filter(value => {
                return value.displayName.toLowerCase() !== 'month'
            });
        }
        if (!enableMonthsForComparisonView) {
            _dashboardFiltersList = _dashboardFiltersList.filter(value => {
                return value.displayName.toLowerCase() !== 'months'
            });
            _dashboardFiltersList = _dashboardFiltersList.map(value => {
                if (value.type === "fiscalYear" && value.viewTypeId === 3) {
                    value.displayName = "Fiscal Years";
                    value.type = "fiscalYears"
                }
                return value
            });
        }
        if (enableMonthsForComparisonView) {
            _dashboardFiltersList = _dashboardFiltersList.map(value => {
                if (value.type === "fiscalYears" && value.viewTypeId === 3) {
                    value.displayName = "Fiscal Year";
                    value.type = "fiscalYear"
                }
                return value
            });
        }
        return (
            <React.Fragment>
                <div className='dashboard-desktop-filters'>
                    <ApplicationDesktopFilters title='Filters'>
                        {
                            _dashboardFiltersList && _dashboardFiltersList.map((filter, index) => {
                                return (
                                    <ApplicationDesktopFilterSection title={filter.displayName} key={index} >
                                        {
                                            this.getFilterComponent(
                                                filter.type,
                                                filter.optionList,
                                                selectedFilters,
                                                selectedMenu
                                            )
                                        }
                                    </ApplicationDesktopFilterSection>
                                );
                            })
                        }
                    </ApplicationDesktopFilters>
                </div>
            </React.Fragment>
        );
    }
};

const mapStateToProps = ({ dashboard }) => {
    return {
        title: dashboard.selectedMenu,
        subTitle: dashboard.selectedSubMenu,
        selectedMenu: dashboard.selectedMenu,
        selectedFilters: dashboard.selectedFilters,
        dashboardFiltersList: dashboard.dashboardFiltersList
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onDashboardProgramFilterChange(option) {
            dispatch(onDashboardProgramFilterChange({ type: option.name, value: [option.value] }));
        },
        onDashboardCaseTypeFilterChange(option) {
            dispatch(onDashboardCaseTypeFilterChange({ type: option.name, value: [option.value] }));
        },
        onDashboardFilingsByFilterChange(option) {
            dispatch(onDashboardFilingsByFilterChange({ type: option.name, value: [option.value] }));
        },
        onDashboardFiscalYearFilterChange(option) {
            dispatch(onDashboardFiscalYearFilterChange({ type: option.name, value: [option.value] }));
        },
        onDashboardComparsionByFilterChange(option) {
            dispatch(onDashboardComparsionByFilterChange({ type: option.name, value: [option.value] }));
        },
        onDashboardSelectByFilterChange(option) {
            dispatch(onDashboardSelectByFilterChange({ type: option.name, value: [option.value] }));
        },
        onDashboardMonthFilterChange(option) {
            dispatch(onDashboardMonthFilterChange({ type: option.name, value: [option.value] }));
        },
        onDashboardJurisdictionTypeFilterChange(option) {
            dispatch(onDashboardJurisdictionTypeFilterChange({ type: option.name, value: option.selectedOptions, isChecked: option.isChecked }))
        },
        onDashboardMultipleFiscalYearsChange(option) {
            dispatch(onDashboardMultipleFiscalYearsChange({ type: option.name, value: option.selectedOptions }))
        },
        onDashboardMultipleMonthsChange(option) {
            dispatch(onDashboardMultipleMonthsChange({ type: option.name, value: option.selectedOptions }));
        },
        onDashboardCountyTypeFilterChange(option) {
            dispatch(onDashboardCountyTypeFilterChange(option));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardDesktopFilters);
