import React, { Component } from 'react';
import './index.scss';

export default class DesktopView extends Component {
    constructor(props) {
        super(props)
        this.onClickViewMore = this.onClickViewMore.bind(this)
    }

    onClickViewMore(type) {
        this.props.onViewMore(type)
    }
    createMarkup(value) {
        return { __html: value };
    };
    render() {
        const commisionarData = [{
            displayName: 'COMMISSIONER STATISTICS',
            content: `For many people, <a target = "blank" href ='https://www.mdcourts.gov/district/selfhelp/whodoeswhat'> commissioners </a> are the first point of contact with the Maryland Judiciary. Commissioners are judicial officers, appointed by the Administrative Judge of each District with approval of the Chief Judge of the District Court of Maryland. There are just under 300 District Court commissioners around the state, available 24 hours a day, 365 days a year. Commissioners have four primary responsibilities as they relate to the criminal justice system :`,
            responsibiltyList: [
                'Reviewing Applications for Statement of Charges to determine whether probable cause exists to issue charging documents;',
                'Conducting initial appearance hearings on arrested individuals, determine probable cause on warrantless arrests, advise the defendant of the charges against them and their rights in court and determine conditions of pretrial release;',
                'Issuing interim peace and protective orders in response to domestic violence and peace order petitions; and',
                'Determining eligibility of applicants for Office of the Public Defender services in all new criminal and traffic charges. Commissioners do not handle any post-conviction applications for the public defender, nor juveniles.'
            ],
            children: [

                {
                    displayName: 'Appointed Attorneys Program and Pretrial Release Statistics',
                    content: ['Appointed Attorneys Program and Pretrial Statistics represent the frequency of occurrences in which defendants, at the time of initial appearance, were represented by an appointed attorney, the public defender, a private attorney, chose to waive their right to an attorney, or in which a commissioner made a preliminary determination in instances in which an attorney wasn’t available. This dashboard also includes frequency of various pretrial release conditions imposed at initial appearance.',
                        'The District Court of Maryland Appointed Attorneys Program provides attorney representation to indigent criminal defendants during initial appearances. The program provides compensation to attorneys who contribute their time and legal expertise.',
                        `The program was created in May 2014 in response to a <a target = "blank " href = 'https://govt.westlaw.com/mdc/Document/NFC6E8D60FCC411E39061EA59213A2019?viewType=FullText&amp%3BoriginationContext=documenttoc&amp%3BtransitionType=CategoryPageItem&amp%3BcontextData=%28sc.Default%29&amp%3Bbhcp=1&transitionType=Default&contextData=%28sc.Default%29&bhcp=1'> ruling </a> issued by the Maryland Court of Appeals that held that indigent criminal defendants have a state constitutional right to appointed counsel during initial appearances before a judicial officer.`,
                        `See <a target = 'blank' href = 'https://govt.westlaw.com/mdc/Document/NFC6E8D60FCC411E39061EA59213A2019?viewType=FullText&amp%3BoriginationContext=documenttoc&amp%3BtransitionType=CategoryPageItem&amp%3BcontextData=%28sc.Default%29&amp%3Bbhcp=1&transitionType=Default&contextData=%28sc.Default%29'> DeWolfe v. Richmond, 434 Md. 444 (2013) </a>`,

                    ]
                }, {
                    displayName: 'Eligibility for Public Defender by Commissioner',
                    content: ['Eligibility for Public Defender by Commissioner statistics provide the number of public defender applications completed and processed at initial appearance following an arrest and for walk-in appearances before a commissioner and in which a decision regarding the application was entered.',
                        'Walk-in include individuals that chose to decline eligibility determination at the initial appearance, but have decided to apply; individuals charged with incarcerable criminal and traffic charges by citation or criminal summons; and individuals charged with incarcerable charges that exceed $500 fine, by criminal summons or citation.',
                        'The data on this dashboard is further broken-down by those applications that were determined to be eligible or ineligible based on federal poverty guidelines or other eligibility criteria.']
                }]
        }]
        return (
            <React.Fragment>
                {
                    commisionarData && commisionarData.map((data, index) => {
                        const { displayName, children, content, responsibiltyList } = data;
                        return (
                            <div className="commisionar-data-container" key={index}>
                                <h3> {displayName} </h3>
                                <h4 dangerouslySetInnerHTML={this.createMarkup(content)}></h4>
                                <div className="responsibility-list">
                                    <ul>
                                        {
                                            responsibiltyList.map((list, index) => {
                                                return (
                                                    <li key={index}>{list}</li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                                <h4>
                                    <a target="blank" href='https://mdcourts.gov/sites/default/files/import/courtoperations/pdfs/aappdsdataterms.pdf'>
                                        Commissioner Statistics – Glossary of Terms
                                    </a>
                                </h4>
                                <div className="sections">
                                    {
                                        children.map((child, index) => {
                                            const { displayName, content } = child;
                                            return (
                                                <div className="sectionContainer" key={index}>
                                                    <div className="sectionContainer__header">
                                                        <div className="sectionHeader"> {displayName} </div>
                                                        <button className="viewReportBtn" onClick={e => this.onClickViewMore(displayName)}> view report </button>
                                                    </div>
                                                    <div className="sectionBody">
                                                        <div>
                                                            {
                                                                content.map((value, index) => {
                                                                    return <p key={index} dangerouslySetInnerHTML={this.createMarkup(value)}></p>
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="viewReportBtn-mobile">
                                                        <button className="viewReportBtn" onClick={e => this.onClickViewMore(displayName)}>
                                                            view report
                                                        </button>
                                                    </div>
                                                </div>
                                            );
                                        })
                                    }

                                </div>
                            </div>
                        )
                    })
                }
            </React.Fragment >
        )
    }
}