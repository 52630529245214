import React, { Component } from 'react';
import { connect } from 'react-redux';
import ApplicationChartContainers from  '../../../helpers/ApplicationChartContainers'
import './index.scss';

class DashboardActivities extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    render() {
        const { 
            activityData, 
            selectedSubMenuChild,
            viewType,
            subTitle,
            subMenuId,
            selectedFilters ,
            // dashboardFiltersList
        } = this.props;
        return (
            <div className = "dashboard-activities">
                {
                    viewType.toLowerCase() === 'activity'
                        ? activityData && activityData.map((container, index) => {
                            return (
                                <ApplicationChartContainers 
                                    key = {index}
                                    subMenuId={subMenuId}
                                    selectedSubMenuChild={selectedSubMenuChild}
                                    containerData={JSON.parse(JSON.stringify(container))}
                                    subTitle = {subTitle}
                                    selectedFilters={selectedFilters}
                                    viewType ={viewType.toLowerCase()} />
                            );
                        }) : null
                }
            </div>
        );
    }
};

const mapStateWithProps = ({ dashboard }) => {
    return {
        selectedSubMenuChild :dashboard.selectedSubMenuChild,
        subTitle: dashboard.selectedSubMenu,
        activityData: dashboard.activityData,
        viewType: dashboard.selectedDashboardViewOption.displayName,
        selectedFilters:dashboard.selectedFilters,
        dashboardFiltersList: dashboard.dashboardFiltersList
    };
};

export default connect(mapStateWithProps, null)(DashboardActivities);
