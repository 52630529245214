import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ApplicationHeader from "./helpers/ApplicationHeader"
import ApplicationFooter from "./helpers/ApplicationFooter"
import Routes from './routes';
import './App.scss';

function App() {
  return (
    <React.Fragment>
      <ApplicationHeader/>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
      <ApplicationFooter/>
    </React.Fragment>
    
  )
}

export default App;
