import React from 'react';
import { connect } from 'react-redux';
import { convertDataToTableView } from '../../../utils/ChartService';
import ApplicationChartContainers from '../../../helpers/ApplicationChartContainers';
import './index.scss';

function DashboardTableView({
    dashboardChartsData,
    subMenuId,
    viewType,
    subTitle,
    districts,
    selectedFilters,
    selectedSubMenuChild
}) {
    const convertedData = viewType.toLowerCase() === 'data' && convertDataToTableView({
        data: JSON.parse(JSON.stringify(dashboardChartsData)),
        menuName: subTitle.name,
        districts,
        fiscalYear: parseInt(selectedFilters.find((filter) => filter.type === 'fiscalYear').value[0]),
        program: selectedFilters.find((filter) => filter.type === 'program').value[0]
    });

    return (
        <div className = "dashboard-table-view">
            {
                viewType.toLowerCase() === 'data'
                    ? convertedData && convertedData.map((container, index) => {
                        return (
                            <ApplicationChartContainers  
                                key = {index}
                                subMenuId = {subMenuId}
                                containerData={container}
                                subTitle = {subTitle}
                                selectedSubMenuChild={selectedSubMenuChild}
                                viewType ={viewType.toLowerCase()}/>
                        )
                    }) : null
            }
        </div>
    );
};

const mapStateWithProps = ({ dashboard }) => {
    return {
        districts: dashboard.districts,
        subTitle: dashboard.selectedSubMenu,
        selectedSubMenuChild :dashboard.selectedSubMenuChild,
        selectedFilters: dashboard.selectedFilters,
        dashboardChartsData: dashboard.dashboardChartsData,
        viewType:dashboard.selectedDashboardViewOption.displayName,
    };
};

export default connect(mapStateWithProps, null)(DashboardTableView);