import React from 'react';
import ApplicationOutsideClick from '../ApplicationOutsideClick';
import './index.scss';

export default class ApplicationCustomDropdown extends React.PureComponent {
    constructor(props) {
        super(props);
        this.customDropdownOptions = props.customDropdownOptions;
        this.state = {
            isDropdownOpen: false
        }
        this.onDropdownClick = this.onDropdownClick.bind(this);
        this.onDropdownOptionChange = this.onDropdownOptionChange.bind(this);
        this.onBackdropClick = this.onBackdropClick.bind(this);
    }
    onDropdownClick() {
        this.setState(state => {
            return {
                isDropdownOpen: !state.isDropdownOpen
            };
        });
    }
    
    onBackdropClick(){
        this.setState({
            isDropdownOpen: false
        })
    }
  
    onDropdownOptionChange(selectedViewOption, title, subTitle,subTitleChild) {
        const subMenu = subTitle ? subTitle.id : null;
        this.setState({
            isDropdownOpen: false
        });
        const viewOption = selectedViewOption.toLowerCase();
        this.props.onClick({
            menuId: title.id,
            subMenuId: subMenu,
            subCategoryChildId:subTitleChild && subTitleChild.id,
            viewOption
        }); 
    }
    
    render() {
        const { isDropdownOpen } = this.state;
        const { title, subTitle, subTitleChild,dropdownTitle, className} = this.props;
        return (
            <ApplicationOutsideClick  onOutsideClick = {this.onBackdropClick}>
                <div className = 'application-custom-dropdown '>
                    <div className = {`${className}`} onClick = {this.onDropdownClick}>
                        <div className = {`${className}__title`}>
                            {dropdownTitle.displayName}
                        </div>
                        <div className = {`icon-chevron-down ${className}__icon`}></div>
                    </div>
                    <div className = {isDropdownOpen === true ? `${className}__list` : `${className}__list-hide`} >
                        <ul className = {`${className}__list__options`}>
                            {this.customDropdownOptions.map(({ displayName, viewTypeId  }, index) => {
                                return <li
                                    key = {index}
                                    id = {viewTypeId}
                                    value = {displayName}
                                    onClick = {_ => this.onDropdownOptionChange(displayName, title, subTitle,subTitleChild)}> 
                                        {displayName}
                                </li>
                            })}
                        </ul>
                    </div>
                </div>
            </ApplicationOutsideClick>
        )
    }
}
