import React from 'react';
import ApplicationRadioButtons from '../../../helpers/ApplicationRadioButtons';
import './index.scss';

export default function DashboardCaseTypeFilters({ 
    options,
    onClose,
    selectedOption = null,
    onChange
}) {    
    const _selectedOption = selectedOption.find((value) => value);
    //we have added the ? to bypass the null values
    //https://infojiniconsulting.atlassian.net/browse/MDS-83
    options = options?.map(({ displayName, optionId }) => ({ name: 'caseType', value: displayName, id: optionId }));
    options = options?.sort((a, b) => a.value  !== b.value ? a.value < b.value ? -1 : 1 : 0);
    return (
        <div className='dashboard-casetype-filters'>             
            <ApplicationRadioButtons 
                options = {options} 
                onClose = {onClose}
                selectedOption={_selectedOption} 
                onChange={onChange} />
        </div>
      
    );
}