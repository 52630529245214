import React from 'react';
import './index.scss';

export default function ApplicationDesktopFilterSection({
    title,
    children
}) {
    return(
        <div className='application-filter-section'>
            <div className='application-filter-section__title'>
                {title}
            </div>
            {children}
        </div>
    );
}