import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { closeModal } from '../../../helpers/ApplicationModal';
import DashboardMobileModalFilter from '../DashboardMobileModalFilter';
import ApplicationCustomDropdown from '../../../helpers/ApplicationCustomDropdown';
import { resetState, initializeDashboard } from '../store/dispatchers';
import './index.scss';

class DashboardMobileFilters extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showMobileModal: false
        }
        this.onMobilefilters = this.onMobilefilters.bind(this);
        this.onMobileFiltersClose = this.onMobileFiltersClose.bind(this);
    }

    onMobilefilters() {
        this.setState({
            showMobileModal: true
        })
    }

    onMobileFiltersClose() {
        closeModal();
        this.setState({
            showMobileModal: false
        });
    }

    onClick({
        viewOption,
        menuId,
        subMenuId,
        subCategoryChildId
    }) {
        const { resetState, initializeDashboard, history, previewDetailsLoaded, previewId } = this.props;
        resetState();
        subCategoryChildId = subCategoryChildId ? subCategoryChildId :null
        initializeDashboard({ viewOption, categoryId: menuId, subCategoryId: subMenuId ,subCategoryChildId});
        if(previewDetailsLoaded) {
            return history && history.push(`/preview/${previewId}/${viewOption}`);
        } else {
            if(subCategoryChildId){
                return history && history.push(`/menus/${menuId}/sub-menu/${subMenuId}/children/${subCategoryChildId}/${viewOption}`);                        
            } else{
                return history && history.push(`/menus/${menuId}/sub-menu/${subMenuId}/${viewOption}`);                        
            }
        }
    }  

    renderContent = () => {
        const { showMobileModal } = this.state;
        const { 
            title,
            subTitle,
            subTitleChild,
            customDropdownOptions, 
            selectedDashboardViewOption 
        } = this.props;
        return (
            <div className = 'dashboard-mobile-filters'>
                <div className = 'dashboard-mobile-filters__activity-section'>
                    <ApplicationCustomDropdown  
                        title = {title}
                        subTitle = {subTitle}
                        subTitleChild={subTitleChild}
                        onClick = {this.onClick.bind(this)}
                        dropdownTitle = {selectedDashboardViewOption}
                        customDropdownOptions = {customDropdownOptions} 
                        className = 'application-custom-dropdown-content'/>
                </div>
                <div className = 'dashboard-mobile-filters__filter-section' onClick = {this.onMobilefilters}>
                    <div className = 'icon-filter dashboard-mobile-filters__filter-section__icon'> </div>
                    <div  onClick = {this.onMobilefilters}>Filter </div>
                </div>
                <DashboardMobileModalFilter 
                    showMobileModal = {showMobileModal} 
                    onClose = {this.onMobileFiltersClose} />
            </div>
        );
    }
    render() {
        return this.renderContent();
    }
}

const mapStateToProps = ({ dashboard, preview }) => {
    return {
        previewId: preview.previewId,
        title: dashboard.selectedMenu,
        subTitle: dashboard.selectedSubMenu,
        subTitleChild:dashboard.selectedSubMenuChild,
        previewDetailsLoaded: preview.previewDetailsLoaded,
        customDropdownOptions: dashboard.dashboardViewOptions,
        selectedDashboardViewOption : dashboard.selectedDashboardViewOption
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        resetState() {
            dispatch(resetState())
        },
        initializeDashboard(details) {
            dispatch(initializeDashboard(details));
        },
    };
};

export default connect( mapStateToProps, mapDispatchToProps)(withRouter(DashboardMobileFilters));