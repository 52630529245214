import Actions from './actions';

const defaultState = {
    categoryId: null,
    subCategoryId: null,
    loadingPreviewDetails: false,
    previewDetailsLoaded: false,
    previewId: null,
    // durationValue: {},
    fiscalYears: []
}

export default function PreviewReducer(state = defaultState, action) {
    switch (action.type) {
        case Actions.LOAD_PREVIEW_MODE:
            return {
                ...state,
                loadingPreviewDetails: true,
                previewDetailsLoaded: false,
                previewId: action.data
            };

        case Actions.ON_PREVIEW_DETAILS_LOADED:
            return {
                ...state,
                loadingPreviewDetails: false,
                previewDetailsLoaded: true,
                categoryId: action.data.categoryId,
                subCategoryId: action.data.subCategoryId,
                previewId: action.data.dataUploadId,
                fiscalYears: action.data.fiscalYears,
                // durationValue: {...action.data.durationValue}
            };

        default:
            return state;

    }
}