import React, { Component } from 'react';
import HomeMobileCountyView from '../../../shared/HomeMobileCountyView';
import HomeAppealsContainer from '../../../shared/HomeAppealsContainer';
import HomePublicAndIntialAppearanceModal from '../../../shared/HomeCountyDetailsModal';
import HomeFiscalYearFilingsAndTerminations from '../../../shared/HomeFiscalYearFilingsAndTerminations';
import './index.scss';

export default class MobileView extends Component {
    getTotalFilings(county, selectedFiscalYear) {
        const selectedYear = county.fiscalYears.find(({ displayName }) => displayName === selectedFiscalYear.displayName)
        return selectedYear.filings.toLocaleString()
    }
    getTotalDispositions(county, selectedFiscalYear) {
        const selectedYear = county.fiscalYears.find(({ displayName }) => displayName === selectedFiscalYear.displayName)
        return selectedYear.dispositions.toLocaleString()
    }
    render() {
        const {
            title,
            appeals,
            counties,
            openModal,
            closeModal,
            onViewClicked,
            stateInfoModal,
            fiscalYearOptions,
            onFiscalYearChange,
            selectedFiscalYear,
        } = this.props;

        const detailsOnCountyView = [
            {
                displayName: 'Filling',
                getDetails: this.getTotalFilings
            },
            {
                displayName: 'Disposition',
                getDetails: this.getTotalDispositions
            }
        ];

        return (
            <React.Fragment>
                <div className='filings-mobile-view'>
                    <HomeFiscalYearFilingsAndTerminations
                        selectedYear={selectedFiscalYear}
                        fiscalYearOptions={fiscalYearOptions}
                        onFiscalYearChange={onFiscalYearChange} />
                    <HomeMobileCountyView
                        counties={counties}
                        openModal={openModal}
                        detailsOnCountyView={detailsOnCountyView}
                        selectedFiscalYear={selectedFiscalYear} />
                    <HomeAppealsContainer onViewClicked={onViewClicked} appeals={appeals} />
                </div>
                {
                    stateInfoModal ?
                        <div className='mobile-modal'>
                            <HomePublicAndIntialAppearanceModal
                                title={title}
                                modalPosition={'top'}
                                onClose={closeModal}
                                onViewClicked={onViewClicked} />
                        </div>
                        : null
                }
            </React.Fragment>
        )
    }
}
