import React, { Component } from 'react';
import { connect } from 'react-redux';
import CommissionerDataContainer from './commissionerDataContainer';
import './index.scss';

class commisionarData extends Component {
    constructor(props) {
        super(props);
        this.onViewMore = this.onViewMore.bind(this);
    }

    onViewMore(type) {
        const { history } = this.props
        let submenuIndex;
        if (type === "Appointed Attorneys Program and Pretrial Release Statistics") {
            submenuIndex = 6;
        }
        if (type === "Eligibility for Public Defender by Commissioner") {
            submenuIndex = 5;
        }
        history.push(`/menus/1/sub-menu/${submenuIndex}/activity`)
    }
    render() {
        return (
            <CommissionerDataContainer onViewMore={this.onViewMore} />
        )
    }
}

const mapStateToProps = ({ home }) => {
    return {
        data: home.commissionerData
    };
};
export default connect(mapStateToProps, null)((commisionarData));