import React, { Component } from "react";
import Dashboard from "../Dashboard";
import { connect } from "react-redux";
import PreviewGetQuery from "./PreviewGetQuery";
import { loadPreviewMode } from "./store/dispatchers";
import { initializeDashboard } from "../Dashboard/store/dispatchers";

class Preview extends Component {
  componentDidMount() {
    const { match, loadPreviewMode } = this.props;
    // check if it is a preview mode
    const { previewId } = match.params;
    loadPreviewMode(parseInt(previewId));
  }

  componentDidUpdate() {
    const {
      match,
      categoryId,
      subCategoryId,
      initializeDashboard,
      settingsDetailsLoaded,
      previewDetailsLoaded,
    } = this.props;
    const { viewOption } = match.params;

    if (settingsDetailsLoaded === true && previewDetailsLoaded === true) {
      initializeDashboard({
        viewOption,
        categoryId,
        subCategoryId,
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <PreviewGetQuery />
        <Dashboard isPreviewMode={true} {...this.props} />
      </React.Fragment>
    );
  }
}

const mapStateWithProps = ({ preview, dashboard, setting }) => {
  return {
    previewDetailsLoaded: preview.previewDetailsLoaded,
    categoryId: preview.categoryId,
    subCategoryId: preview.subCategoryId,
    settingsDetailsLoaded: setting.settingsDetailsLoaded,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadPreviewMode(previewId) {
      dispatch(loadPreviewMode(previewId));
    },
    initializeDashboard(details) {
      dispatch(initializeDashboard(details));
    },
  };
};

export default connect(mapStateWithProps, mapDispatchToProps)(Preview);
