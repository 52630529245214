import React from 'react';
import './index.scss';

export default function ApplicationSubMenu({
    onClick,
    subMeunList,
    selectedSubMenuId,
    selectedSubmenuChildId,
    disableOtherSubmenus = false
}) {
    function displayChildren(child, index, parent) {
        const childId = parent ? child.id : null;
        const parentId = parent ? parent.id : child.id;
        const subMenuDisable = disableOtherSubmenus &&
            (!(parentId === 3)) && (!(parentId === 18)) ? true : false;
        const selectedId = selectedSubmenuChildId
            ? selectedSubmenuChildId : selectedSubMenuId;

        if (selectedId === child.id) {
            return <li key={index} className="sub-menu active"
                onClick={_ => onClick(parentId, childId)}>
                {child.displayName}
            </li>
        } else {
            return <li key={index} className={subMenuDisable ? "sub-menu disabled" : "sub-menu"}
                onClick={_ => onClick(parentId, childId)}>
                {child.displayName}
            </li>
        }
    }
    return (
        <div className="application-sub-menu">
            <ul>
                {
                    subMeunList && subMeunList.map((subMenu, index) => {
                        if (subMenu.children.length > 0) {
                            if (subMenu.id === selectedSubMenuId) {
                                return subMenu && subMenu.children.map((child, index) => {
                                    return displayChildren(child, index, subMenu);
                                })
                            }
                        } else {
                            return displayChildren(subMenu, index);
                        }
                        return '';
                    })
                }
            </ul>
        </div>
    )
}