import React from 'react';
import courtimg from '../../assets/images/court-img.png';
import ApplicationPageLayout from '../ApplicationPageLayout';
import './index.scss';

export default function ApplicationHomeNavigation() {
    return (
        <div className = "application-home-navigation">
            <div className = "application-home-navigation__content">
                <div className = 'application-home-navigation__content__court-image'> 
                    <ApplicationPageLayout>
                        <img src = {courtimg} alt = 'court-img'/>
                    </ApplicationPageLayout>
                </div> 
                <div className = 'application-home-navigation__content__title'> 
                    <ApplicationPageLayout>
                        MARYLAND JUDICIARY DATA DASHBOARD 
                    </ApplicationPageLayout>
                </div>
            </div>
        </div>
    )
}