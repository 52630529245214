import React, { Component } from "react";
import Highcharts from "highcharts";
import drilldown from "highcharts/modules/drilldown";
import HighchartsReact from "highcharts-react-official";
import HC_exporting from 'highcharts/modules/exporting'
import ApplicationListSection from '../ApplicationListSection'
import ApplicationChartOptions from "../ApplicationChartOptions";

import './index.scss';

export default class ApplicationBarChart extends Component {
    constructor(props) {
        super(props)
        this.drillup = this.drillup.bind(this)
        this.events = this.events.bind(this)
        this.onDownload = this.onDownload.bind(this)
    }
    componentDidMount(){
        Highcharts.setOptions({
            colors: ['#8d90ff',
                '#8e2d5a',
                '#8a6e3d',
                '#c5fffe',
                '#0c48bb',
                '#d8696a',
                '#000464',
                '#d9bd05',
                '#c64690',
                '#ff7522',
                '#9d8189',
                '#008c95',
                '#ffadeb',
                '#75e4b3',
                '#ffbfb5'],
            lang: {
                drillUpText: '',
                decimalPoint: '.',
                thousandsSep: ',',
            }
        });

        HC_exporting(Highcharts)
        drilldown(Highcharts)
    }
    componentDidUpdate() {
        Highcharts.charts.map((chart) => {
            if (chart) {
                chart.drillUp()
            }
            return ''
        })
    }
    rotation(data) {
        let status = false;
        data && data.map(({ name }) => {
            name.split(" ").map((value) => {
                if (value.length > 15) {
                    status = true
                }
                return '';
            })
            return '';
        })
        if (status) {
            return -45
        }
    }
    onDownload(displayName, options) {
        
        let type;
        if (displayName === 'PNG') {
            type = 'image/png';
        }
        if (displayName === 'JPG') {
            type = 'image/jpeg';
        }
        if (displayName === 'PDF') {
            type = 'application/pdf';
        }
        const { title, subTitle, selectedYears, ByTerm ,selectedSubMenuChild} = this.props;
        const term = ByTerm ? '(ByTerm)' : '';
        let name = `${subTitle.displayName}-${title} ${term} (${selectedYears.join(',')})`;
        if(subTitle.name === "selfHelpCenter"){
            name = `${title} ${term} (${selectedYears.join(',')})`;
            if(selectedSubMenuChild && (selectedSubMenuChild.displayName !== "All")){
                name = `${selectedSubMenuChild.displayName}-${title} ${term} (${selectedYears.join(',')})`;
            }
        }
        let _filename = name.includes('/') ? name.replace("/", " ") : name; 
        
        Highcharts.charts.map((chart) => {
            if (chart) {
                if (chart.userOptions.title.text === options.title.text) {
                    chart.exportChart({
                        type: type,
                        filename: _filename,
                        sourceHeight: 350
                    },
                    {
                        title: {
                            text: name,
                            style: {
                                display: 'block'
                            }
                        }
                    });
                }
            }
            return '';
        })
    }
    drillup() {
        const { title, onDrillUp } = this.props;
        Highcharts.charts.map((chart) => {
            if (chart) {
                onDrillUp(title)
                chart.drillUp();
                chart.drillUp();
            }
            return '';
        })
    }

    events() {
        const { onDrilldown, drilldownData } = this.props;
        return {
            drilldown: function (e) {
                e.preventDefault()
                if (e.seriesOptions) {
                    onDrilldown(e)
                }
                else {
                    const seriesOptions = {
                        type: e.type,
                        seriesOptions: drilldownData.find(drillDown => drillDown.id === e.point.drilldown)
                    }
                    onDrilldown(seriesOptions)
                }
            },
        }
    }
    render() {
        const {
            title,
            chartData,
            drilldownData,
            listNames,
            exdcludeOptions,
            footnotes,
            viewType,
            percentage,
            onClickOption,
            modifiedDrilldownData
        } = this.props;
        const seriesData = modifiedDrilldownData.length > 0 ? modifiedDrilldownData : (chartData)
        const labelFontSize = seriesData && seriesData.length > 9 ? 7 : 11
        // const name = `${subTitle.displayName}-${title} ${term} (${selectedYears.join(',')})`;

        const options = {
            chart: {
                type: "column",
                height: 300,
                events: this.events(),
            },
            title: {
                text: title,
                style: {
                    display: 'none'
                }
            },
            credits: {
                enabled: false
            },
            paddingTop: 10,
            yAxis: {
                title: {
                    text: ""
                },
                labels: {
                    format: '{value:,.0f}'
                },
                plotLines: (this.props.name === "percentageOfCasesTerminatedWithinStandard" && this.props.subTitle.name === "districtCourt") ?
                    [{
                        color: 'red',
                        dashStyle: 'Solid',
                        value: 98,
                        width: 1,
                        zIndex: 10
                    }] : (this.props.name === "appealsPercentageOfCasesTerminatedWithinStandard" && this.props.subTitle.name === "courtOfSpecialAppeals") ?
                        [{
                            color: 'red',
                            dashStyle: 'Solid',
                            value: 80,
                            width: 1,
                            zIndex: 10
                        }] : (this.props.name === "appealsPercentageOfCasesTerminatedWithinStandard" && this.props.subTitle.name === "courtOfAppeals") ? [
                            {
                                color: 'red',
                                dashStyle: 'Solid', 
                                value: 100,
                                width: 1,
                                zIndex: 10
                            }
                        ] : [],

            },
            legend: {
                enabled: false,
                align: 'left',
                layout: 'vertical',
                verticalAlign: 'top',
            },
            plotOptions: {
                column: {
                    borderWidth: 1,
                    minPointLength: 3,
                    borderColor: '#dddddd',

                    dataLabels: {
                        enabled: true,
                        overflow: "allow",
                        crop: false,
                        style: {
                            // fontSize:'8px'
                        },
                        format: percentage
                            ? '<td style="color:#00000;font-weight:400;">{point.y}%</td>'
                            : '<td style="color:#00000;font-weight:400;">{point.y:,.0f}</td>'
                    },

                    events: {
                        click: function (e) {
                            if (this.drilldown) {
                                const seriesOptions = {
                                    type: "drilldown",
                                    seriesOptions: drilldownData.find(drillDown => drillDown.id === this.drilldown)
                                }
                                this.onDrilldown(seriesOptions)
                            }

                        }
                    },

                },

            },
            enabled: true,
            xAxis: {
                type: 'category', 
                // labels: {
                //     distance: 50,
                // },
                plotBands: this.props.name === "percentageOfCasesTerminatedWithinStandard" && this.props.subTitle.name === "circuitCourt" && this.props.selectedCaseType === "All Cases" 
                ? [{
                    color: '#dddddd',
                    from: -1,
                    to: 5.5,
                    label: {
                        align: 'center',
                        rotation: 0,
                        style: {
                            color: "red",
                            zIndex: 1,
                            fontSize: '10'
                        },
                        text: "____ 98% Goal ____",
                        textAlign: 'center',
                        verticalAlign: 'top',
                        x: 0,
                        y: 20,
                    },
                    zIndex: 1
                }, {
                    color: '#fcffc5',
                    from: 5.5,
                    to: 8.5,
                    label: {
                        align: 'center',
                        rotation: 0,
                        style: {
                            color: "red",
                            zIndex: 1,
                            fontSize: '10'
                        },
                        textAlign: 'center',
                        text: "____ 100% Goal ____",
                        verticalAlign: 'top',
                        x: 0,
                        y: 20,
                    },
                    zIndex: 1
                }] : []
            },
            tooltip: {
                useHTML: true,
                headerFormat: '<table>',
                pointFormat: percentage ?
                    '<td style="color:#333;text-aling=center"><b>{point.y}%</b></td>'
                    : '<td style="color:#333;text-aling=center"><b>{point.y:,.0f}</b></td>',
                shared: true,
                outside: true,
                distance: 60,
                followPointer: true
            },
            series: [
                {
                    name: "infojini",
                    colorByPoint: true,
                    data: seriesData,
                    animation: {
                        duration: 1000
                    }

                }
            ],
            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 500
                    },
                    chartOptions: {
                        plotOptions: {
                            column: {
                                borderWidth: 1,
                                minPointLength: 3,
                                borderColor: '#dddddd',
                                dataLabels: {
                                    enabled: true,
                                    style: {
                                        fontSize: labelFontSize + 'px'
                                    },
                                    format: percentage
                                        ? '<td style="color:#00000;font-weight:400;">{point.y}%</td>'
                                        : '<td style="color:#00000;font-weight:400;">{point.y:,.0f}</td>'
                                },

                                events: {
                                    click: function (e) {
                                        if (this.drilldown) {
                                            const seriesOptions = {
                                                type: "drilldown",
                                                seriesOptions: drilldownData.find(drillDown => drillDown.id === this.drilldown)
                                            }
                                            this.onDrilldown(seriesOptions)
                                        }

                                    }
                                },

                            },

                        },
                        xAxis:{
                            plotBands: this.props.name === "percentageOfCasesTerminatedWithinStandard" && this.props.subTitle.name === "circuitCourt" && this.props.selectedCaseType === "All Cases" ?
                            [{
                                color: '#dddddd',
                                from: -1,
                                to: 5.5,
                                label: {
                                    align: 'center',
                                    rotation: 0,
                                    style: {
                                        color: "red",
                                        zIndex: 1,
                                        fontSize: '8'
                                    },
                                    text: "___ 98% Goal ___",
                                    textAlign: 'center',
                                    verticalAlign: 'top',
                                    x: 0,
                                    y: 20,
                                },
                                zIndex: 1 
                            }, {
                                color: '#fcffc5',
                                from: 5.5,
                                to: 8.5,
                                label: {
                                    align: 'center',
                                    rotation: 0,
                                    style: {
                                        color: "red",
                                        zIndex: 1,
                                        fontSize: '8'
                                    },
                                    textAlign: 'center',
                                    text: "___ 100% Goal ___",
                                    verticalAlign: 'top',
                                    x: 0,
                                    y: 20,
                                },
                                zIndex: 1
                            }] : []
                            
                        }

                    }
                },
                ]
            },
            exporting: {
                enabled: false,
            },
            drilldown: {
                drillUpButton: {
                    position: {
                        y: 0,
                        x: 50
                    }
                },
                series: drilldownData
            }
        };
        if(this.props.name === "percentageOfCasesTerminatedWithinStandard" && this.props.subTitle.name === "circuitCourt" && this.props.selectedCaseType === "All Cases"){
            options.yAxis.max = 125
        }
        return (
            <React.Fragment>
                <div className= "application-bar-chart" id={title}>
                    {
                        seriesData && seriesData.length > 0
                            ? <React.Fragment>
                                
                                <div className= "highchart-container" >
                                    <HighchartsReact highcharts={Highcharts} options={options} />
                                </div>
                                
                            </React.Fragment>
                            : <div className='no-results'> No Data Available </div>
                    }
                    {
                        seriesData && seriesData.length > 0 && 
                        <div className= "chart-option-container">
                            <div className='icon-download download-chart'>
                                <ApplicationListSection 
                                    listNames={listNames} 
                                    viewType={viewType} 
                                    onClickOption={({ displayName }) => this.onDownload(displayName, options)} />
                            </div>
                            <ApplicationChartOptions
                                listNames={listNames}
                                title={title}
                                footnotes={footnotes}
                                exdcludeOptions={exdcludeOptions}
                                onClickOption={onClickOption} />
                        </div>
                    
                    }
                    
                    {
                        (modifiedDrilldownData.length > 0) &&
                        <span className=" back-button" onClick={e => this.drillup()}> &lt; Back </span>

                    }
                </div>
            </React.Fragment>
        );
    }
}
