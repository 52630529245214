import Actions from './actions';

const defaultState = {
    selectedCategoryId: null,
    filings: {
        counties: [],
        fiscalYears: [],
        selectedFiscalYear: null,
    },
    mapChartData: [
        {
            "hc-key": "us-md-019",
            "value": 3,
            "color": "#a98d59",
            "countyId": 2,
            "displayName": "Dorchester"
        },
        {
            "hc-key": "us-md-039",
            "value": 3,
            "color": "#8b6f3b",
            'countyId': 3,
            "displayName": "Somerset"
        },
        {
            "hc-key": "us-md-045",
            "value": 3,
            "color": "#705420",
            "countyId": 4,
            "displayName": "Wicomico"
        },
        {
            "hc-key": "us-md-047",
            "value": 3,
            "color": "#a98d59",
            "countyId": 5,
            "displayName": "Worcester"
        },
        {
            "hc-key": "us-md-011",
            "value": 3,
            "color": "#8b6f3b",
            "countyId": 10,
            "displayName": "Caroline"
        },
        {
            "hc-key": "us-md-015",
            "value": 3,
            "color": "#a98d59",
            "countyId": 6,
            "displayName": "Cecil"
        },
        {
            "hc-key": "us-md-029",
            "value": 3,
            "color": "#705420",
            "countyId": 7,
            "displayName": "Kent"
        },
        {
            "hc-key": "us-md-035",
            "value": 3,
            "color": "#a98d59",
            "countyId": 8,
            "displayName": "Queen Anne's"
        },
        {
            "hc-key": "us-md-041",
            "value": 3,
            "color": "#8b6f3b",
            "countyId": 9,
            "displayName": "Talbot"
        },
        {
            "hc-key": "us-md-005",
            "value": 3,
            "color": "#604410",
            "countyId": 17,
            "displayName": "Baltimore"
        },
        {
            "hc-key": "us-md-025",
            "value": 3,
            "color": "#8b6f3b",
            "countyId": 19,
            "displayName": "Harford"
        },
        {
            "hc-key": "us-md-001",
            "value": 3,
            "color": "#8b6f3b",
            "countyId": 22,
            "displayName": "Allegany"
        },
        {
            "hc-key": "us-md-023",
            "value": 3,
            "color": "#a98d59",
            "countyId": 24,
            "displayName": "Garrett"
        },
        {
            "hc-key": "us-md-043",
            "value": 3,
            "color": "#705420",
            "countyId": 23,
            "displayName": "Washington"
        },
        {
            "hc-key": "us-md-003",
            "value": 3,
            "color": "#442d01",
            "countyId": 16,
            "displayName": "Anne Arundel"
        },
        {
            "hc-key": "us-md-013",
            "value": 3,
            "color": "#8b6f3b",
            "countyId": 20,
            "displayName": "Carroll"
        },
        {
            "hc-key": "us-md-027",
            "value": 3,
            "color": "#a98d59",
            "countyId": 18,
            "displayName": "Howard"
        },
        {
            "hc-key": "us-md-021",
            "value": 3,
            "color": "#a98d59",
            "countyId": 21,
            "displayName": "Frederick"
        },
        {
            "hc-key": "us-md-031",
            "value": 3,
            "color": "#8b6f3b",
            "countyId": 15,
            "displayName": "Montgomery"
        },
        {
            "hc-key": "us-md-009",
            "value": 3,
            "color": "#a98d59",
            "countyId": 11,
            "displayName": "Calvert"
        },
        {
            "hc-key": "us-md-017",
            "value": 3,
            "color": "#8b6f3b",
            "countyId": 12,
            "displayName": "Charles"
        },
        {
            "hc-key": "us-md-033",
            "value": 3,
            "color": "#442d01",
            "countyId": 14,
            "displayName": "Prince George's"
        },
        {
            "hc-key": "us-md-037",
            "value": 3,
            "color": "#705420",
            "countyId": 13,
            "displayName": "St. Mary's"
        },
        {
            "hc-key": "us-md-510",
            "value": 3,
            "color": "#442d01",
            "countyId": 1,
            "displayName": "Baltimore City"
        },

    ],
    categoryMenuList: [],
    homeDetailsLoaded: false
}


function getFillingsData(category) {
    // get filings data
    const filings = [...category.find(({ displayName }) => displayName.toLowerCase() === 'filings').data];
    // form a county wise data with each county having total filings and dispositions for each year
    const filteredOptions = filings.reduce((accum, data) => {
        if (data.countyList) {
            // merge district and circuit court counties in one list
            const countyList = [...data.countyList];
            countyList.reduce((accum, county) => {
                const countyIndex = accum.countyFilingsAndDispositions.findIndex(({ countyId }) => countyId === county.countyId);
                if (countyIndex === -1) {
                    accum.countyFilingsAndDispositions.push({ ...county });

                }
                else {
                    // add district and circuit court county filings and dispositons total for a fiscal year
                    county.fiscalYears.reduce((accum, fiscalYear) => {
                        const fiscalYears = accum.countyFilingsAndDispositions[countyIndex].fiscalYears;
                        const fiscalYearIndex = fiscalYears.findIndex(({ displayName }) => displayName === fiscalYear.displayName);
                        if (fiscalYearIndex !== -1) {
                            const totalFilings = fiscalYears[fiscalYearIndex].filings;
                            const totalDispositions = fiscalYears[fiscalYearIndex].dispositions;
                            fiscalYears[fiscalYearIndex].filings = parseInt(totalFilings) + parseInt(fiscalYear.filings);
                            fiscalYears[fiscalYearIndex].dispositions = parseInt(totalDispositions) + parseInt(fiscalYear.dispositions);
                        } else {
                            fiscalYears.push({ ...fiscalYear });
                        }
                        if (accum && accum.fiscalYears.indexOf(fiscalYear.displayName) === -1) {
                            accum.fiscalYears.push(fiscalYear.displayName);
                        }
                        return accum;
                    }, accum);
                }
                return accum;
            }, accum);
        }
        return accum;
    }, {
        fiscalYears: [],
        countyFilingsAndDispositions: []
    });
    const _fiscalYears = getFiscalYears(filings, filteredOptions.countyFilingsAndDispositions)

    return {
        counties: [...filteredOptions.countyFilingsAndDispositions],
        fiscalYears: [..._fiscalYears],
        selectedFiscalYear: _fiscalYears[_fiscalYears.length - 1],
        appeals: [
            {
                displayName: 'APPELLATE COURT OF MARYLAND',
                type: 'special-appeals',
                contents: [`The Appellate Court of Maryland was created in 1966. Cases heard by the Court include any reviewable judgment, decree, or order of other action of a circuit court. The Court also considers applications for leave to appeal in such cases as post-conviction, habeas corpus matters involving denial of or excessive bail, inmate grievances, appeals from criminal guilty pleas, and violations of probation.`,
                `The Court includes fifteen judges - one judge from each of the seven appellate judicial circuits and eight from the State at large. The judges are empowered to sit in panels of three. All judges may sit “en banc” to hear a case if the majority of the judges so order. The Appellate Court of Maryland was renamed in 2022 and was formerly named the Court of Special Appeals.`]
            },
            {
                displayName: 'SUPREME COURT OF MARYLAND',
                type: 'appeals',
                contents: [`The Supreme Court of Maryland was created by the Constitution of 1776, and is the highest court in Maryland. Cases heard by the seven judges of the Court are, for the most part, selected through a discretionary review process known as “certiorari.” Petitions for certiorari come to the Court either before or after a case has been decided by the Appellate Court of Maryland or from Circuit Court decisions when the Circuit Court is acting in the capacity of an appellate court from a District Court appeal. For most cases, the Court grants those petitions for certiorari it deems to be desirable and in the public interest.`,
                `The Supreme Court of Maryland also adopts rules of judicial administration, practice and procedure; admits persons to the practice of law; answers certified questions of law; and conducts disciplinary proceedings involving members of the bench and bar. The Supreme Court of Maryland was renamed in 2022 and was formerly named the Court of Appeals.
                `]
            }
        ]
    }
}
function getFiscalYears(courts, counties) {
    ///get Cosa And Coa total
    const cosaAndCoaTotalFilingsAndDisposition = courts.reduce((accumlator, court) => {
        if (court.fiscalYears) {
            court.fiscalYears.reduce((accumlator, fiscalYear) => {
                let fiscalYearIndex = accumlator.findIndex(({ displayName }) => displayName === fiscalYear.displayName);
                if (fiscalYearIndex === -1) {
                    accumlator.push({
                        displayName: fiscalYear.displayName,
                        totalFilings: 0,
                        totalDispositions: 0
                    });
                    fiscalYearIndex = accumlator.length - 1;
                }
                const filings = fiscalYear.filings ? fiscalYear.filings : 0
                const dispositions = fiscalYear.dispositions ? fiscalYear.dispositions : 0
                accumlator[fiscalYearIndex].totalFilings += parseInt(filings);
                accumlator[fiscalYearIndex].totalDispositions += parseInt(dispositions);
                return accumlator
            }, accumlator);
        }
        return accumlator;
    }, [])

    ///get Distric And Circuit total
    const districAndCircuitTotalFilingsAndDisposition = counties.reduce((accumlator, county) => {
        if (county.fiscalYears) {
            county.fiscalYears.reduce((accumlator, fiscalYear) => {

                let fiscalYearIndex = accumlator.findIndex(({ displayName }) => displayName === fiscalYear.displayName);
                if (fiscalYearIndex === -1) {
                    accumlator.push({
                        displayName: fiscalYear.displayName,
                        totalFilings: 0,
                        totalDispositions: 0
                    });
                    fiscalYearIndex = accumlator.length - 1;
                }

                const filings = fiscalYear.filings ? fiscalYear.filings : 0
                const dispositions = fiscalYear.dispositions ? fiscalYear.dispositions : 0
                accumlator[fiscalYearIndex].totalFilings += parseInt(filings);
                accumlator[fiscalYearIndex].totalDispositions += parseInt(dispositions);
                return accumlator
            }, accumlator);
        }
        return accumlator
    }, [])
    ///get total of all courts 

    const totalFilingsAndDisposition = districAndCircuitTotalFilingsAndDisposition.reduce((accumlator, value) => {
        const year = cosaAndCoaTotalFilingsAndDisposition.find(({ displayName }) => displayName === value.displayName)
        let fiscalYearIndex = accumlator.findIndex(({ displayName }) => displayName === value.displayName);
        if (fiscalYearIndex === -1) {
            accumlator.push({
                displayName: value.displayName,
                totalFilings: 0,
                totalDispositions: 0
            })
            fiscalYearIndex = accumlator.length - 1;
        }
        accumlator[fiscalYearIndex].totalFilings += parseInt(value.totalFilings) + parseInt(year ? year.totalFilings : 0);
        accumlator[fiscalYearIndex].totalDispositions += parseInt(value.totalDispositions) + parseInt(year ? year.totalFilings : 0);
        return accumlator
    }, [])
    return totalFilingsAndDisposition
}


export default function HomeReducer(state = defaultState, action) {
    switch (action.type) {
        case Actions.ON_UPDATE_HOME_DETAILS:
            const categories = [...action.data];
            return {
                ...state,
                homeDetailsLoaded: action.status,
                categoryMenuList: categories.map(({ categoryId, displayName }) => {
                    return { id: categoryId, displayName };
                }),
                selectedCategoryId: categories[0].categoryId,
                filings: { ...getFillingsData(categories) },
            }

        case Actions.ON_UPDATE_SELECTED_CATEGORYID:
            return {
                ...state,
                selectedCategoryId: action.data
            }

        ///For Filings
        case Actions.ON_UPDATE_SELECTED_FISCALYEAR:
            return {
                ...state,
                filings: {
                    ...state.filings,
                    selectedFiscalYear: action.data
                }
            }

        ///For Language Service
        ///For Access To Justice
        ///For Commissioner Data
        default:
            return state;
    }
}