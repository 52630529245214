import React from 'react';
import './index.scss';

export default function ApplicationListSection ({
    listNames,
    onClickOption,
    disableItems = false,
    viewType = 'chart',
}) {
    function onClick(e,name){
        onClickOption && onClickOption(name);
    }
    let className;
    if (viewType === 'data'){
        className = 'position-table'
    } else if (viewType === 'sub-menu') {
        className = 'position-sub-menu'
    } else {
        className = 'position-chart'
    }

    return (
        <ul className = {`application-list ${className}`}>
            {   
                listNames && listNames.map((listName, index) => {
                    return (
                        <li 
                            key = {index} 
                            className = {disableItems ? 'application-list__item disabled' : 'application-list__item'}
                            onClick = {e => onClick(e,listName)}> 
                            {listName.displayName}                                
                        </li>   
                    )
                })
            }
        </ul>
        
            
        );
};
