import React from 'react';
import ApplicationRadioButtons from '../../../helpers/ApplicationRadioButtons';
import './index.scss';

export default function DashboardFilingsByFilters({
    options,
    onClose,
    onChange,
    selectedOption = null
}) {
    const _selectedOption = selectedOption.find((value) => value)
    options = options.map(({ key, optionId }) => ({ name: 'filingBy', value: key, id: optionId }));
    
    return(
        <div className = 'dashboard-filings-by-filters'>
            <ApplicationRadioButtons 
                options = {options} 
                onClose = {onClose}
                selectedOption = {_selectedOption}
                onChange = {onChange} />
        </div>
    );
}