import DashboardReducer from './screens/Dashboard/store/reducer';
import SettingReducer from './shared/Settings/store/reducers';
import HomeReducer from './screens/Home/store/reducers'
import PreviewReducer from '../src/screens/Preview/store/reducers'

export default {
    dashboard: DashboardReducer,
    setting : SettingReducer,
    preview:PreviewReducer,
    home: HomeReducer
};