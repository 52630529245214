import React from 'react';
import ApplicationCheckBoxButtons from '../../../helpers/ApplicationCheckBoxButtons';
import './index.scss';

export default function DashboardJurisdictionTypeFilters({
    options,
    onChange,
    selectedOptions,
}) {
    const _selectedOptions = selectedOptions && selectedOptions.map(({value})=> value);
    options = options.map(({ optionId, displayName }) => ({name: 'jurisdiction', id: optionId, value: displayName }));
    
    return (
        <div className='dashboard-jurisdiction-type'>
             <ApplicationCheckBoxButtons 
                options = {options} 
                showHeaderAsSection = {false}
                headerCheckbox = {options[0].value}
                selectedOptions = {_selectedOptions}
                onChange = {value => onChange(value)} />
        </div>     
    );
};