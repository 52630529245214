import React, { Component } from 'react';
import { connect } from 'react-redux';
import AccessToJusticeContainer from './accessToJusticeContainer';
import { setProgramValues } from '../../../Dashboard/store/dispatchers';
import './index.scss';

class accessToJustice extends Component {
    constructor(props) {
        super(props)
        this.onViewClicked = this.onViewClicked.bind(this)
    }
    onViewClicked(type) {
        const { history, setProgramValues } = this.props
        let submenuIndex, childrenIndex;
        if (type === "Courtroom Services (Court Interpreter Program)") {
            setProgramValues(["Courtroom Services (Court Interpreter Program)"])
            submenuIndex = 3;
            childrenIndex = 17;
        }
        if (type === "out of courtroom services (telephonic)") {
            setProgramValues(["Out of Courtroom Services (Telephonic)"])
            submenuIndex = 3;
            childrenIndex = 17;
        }
        if (type === "Maryland Court Help Center (Remote Services)") {
            submenuIndex = 18;
            childrenIndex = 20;
        }
        if (type === "District Court Help Centers (DCHCs)/All Civil Centers (Walk-in Services)") {
            submenuIndex = 18;
            childrenIndex = 22;
        }
        if (type === "FAMILY COURT HELP CENTERS (FCHCs)") {
            submenuIndex = 18;
            childrenIndex = 21;
        }
        history.push(`/menus/2/sub-menu/${submenuIndex}/children/${childrenIndex}/Activity`)
    }
    render() {
        return (
            <React.Fragment>
                <div className="access-to-justice-logo-container">
                    <div className="logo" />
                </div>
                <AccessToJusticeContainer
                    onViewClicked={this.onViewClicked}
                />
            </React.Fragment>
        );
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        setProgramValues(value) {
            dispatch(setProgramValues(value))
        }
    };
};
export default connect(null, mapDispatchToProps)((accessToJustice));

