import React from 'react';
import './index.scss';

export default function HomeAppealsSection({
    type,    
    title,
    children,
    onViewClicked
}) {
    return (
        <div className = {`appeals-section ${type}`}>
            <div className = 'appeals-section__header'>
                <span className = 'appeals-section__header__title'>
                    {title}
                </span>
                <div className = 'appeals-section__header__button' onClick = { e => onViewClicked(type)}>
                    <span>VIEW REPORT </span>
                </div>
            </div>
            <div className = 'appeals-section__body'>
                {children}
            </div>
            <div className = 'appeals-section__header__button footer-btn' onClick = { e => onViewClicked(type)}>
                <span>VIEW REPORT </span>
            </div>
        </div>  
    );
}