import React from 'react';
import './index.scss';

export default function ApplicationModalMobileFilter({
    children
}) {
    return (
        <div className='application-modal-mobile-filter'>
            {children}
        </div>
    )
}