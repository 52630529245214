import React, { Component } from 'react';
import './index.scss';

export default class HomeFiscalYearFilingsAndTerminations extends Component {
    constructor(props) {
        super();
        this.state = {
            selectedOption: this._getFiscalYearFilingsAndTerminations(props.fiscalYearOptions, props.selectedYear)
        }
        this._onChange = this._onChange.bind(this);
    }

    _getFiscalYearFilingsAndTerminations(fiscalYearOptions, selectedFiscalYear) {
        let _selectedOption = null;
        if (fiscalYearOptions && fiscalYearOptions.length > 0) {
            _selectedOption = fiscalYearOptions.find((value) => {
                return value.displayName === parseInt(selectedFiscalYear.displayName)
            });
        }
        return _selectedOption;
    }

    _onChange(e) {
        const { fiscalYearOptions, onFiscalYearChange } = this.props
        const selectedOption = fiscalYearOptions.find(({ displayName }) => displayName === parseInt(e.target.value))
        const updateSelectedOption = this._getFiscalYearFilingsAndTerminations(fiscalYearOptions, selectedOption);
        this.setState(() => {
            return {
                selectedOption: updateSelectedOption
            }
        });
        onFiscalYearChange(updateSelectedOption)
    }

    render() {
        const { selectedOption } = this.state;
        const { fiscalYearOptions, selectedYear } = this.props;
        const _fiscalYearOptions = fiscalYearOptions.sort(function (a, b) {
            return b.displayName - a.displayName;
        });

        return (
            <div className='dashboard-fillings-and-terminations'>
                <div className="dashboard-fillings-and-terminations__content">
                    <div className="fiscal-year">
                        Fiscal Year
                        <select className='fiscal-year__dropdown' defaultValue={selectedYear} onChange={(e) => this._onChange(e)}>
                            {
                                _fiscalYearOptions.map(({ displayName }, index) => <option key={index}> {displayName} </option>)
                            }
                        </select>
                    </div>
                    <div className='value-container'>
                        <div className='dashboard-fillings-and-terminations__content__filings'>
                            <div className="total-filings"> TOTAL FILINGS </div>
                            <div className={`filings-count ${selectedOption && selectedOption.totalFilings ? '' : 'center'} `}>
                                {selectedOption && selectedOption.totalFilings ? selectedOption.totalFilings.toLocaleString() : '-'}
                            </div>
                        </div>
                        <div className='dashboard-fillings-and-terminations__content__terminations'>
                            <div className="total-terminations"> TOTAL TERMINATIONS </div>
                            <div className={`terminations-count ${selectedOption && selectedOption.totalDispositions ? '' : 'center'} `}>
                                {selectedOption && selectedOption.totalDispositions ? selectedOption.totalDispositions.toLocaleString() : '-'}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}








