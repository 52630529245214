import React from 'react';
import ApplicationCheckBoxButtons from '../../../helpers/ApplicationCheckBoxButtons';
import ApplicationVerticalScroller from '../../../helpers/ApplicationVerticalScroller';


export default function DashboardMultipleMonthsFilters({
    options,
    selectedOptions,
    onChange
}) {
    const _selectedOptions = selectedOptions && selectedOptions.map(({value})=> value);
    options = options && options.map(({ optionId, displayName }) => ({name: 'months', id: optionId, value: displayName }));
    
    return (
        <ApplicationVerticalScroller className = 'dashboard-month-scroller'>
            <div className='dashboard-month-wise-filters'>
                <ApplicationCheckBoxButtons 
                    options = {options} 
                    headerCheckbox = {''}
                    showHeaderAsSection = {false}
                    selectedOptions = {_selectedOptions}
                    onChange = {value => onChange(value)}/>
            </div>    
        </ApplicationVerticalScroller> 
    );
};

