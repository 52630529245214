import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import App from "./App";
import React from "react";
import dotenv from "dotenv";
import ReactGA from "react-ga4";
import ReactDOM from "react-dom";
import { API_URL } from "./constants";
import Reducers from "./reducers";
import { Provider } from "react-redux";
import { onError } from "apollo-link-error";
import { ApolloClient } from "apollo-client";
import { ApolloProvider } from "react-apollo";
import * as serviceWorker from "./serviceWorker";
import { setContext } from "apollo-link-context";
import { createHttpLink } from "apollo-link-http";
import { combineReducers, createStore } from "redux";
import { InMemoryCache } from "apollo-cache-inmemory";
import { redirectToLogin } from "../src/utils/Authentication";
import { getSessionToken } from "../src/utils/Authentication";
import "./index.scss";
dotenv.config();

if (process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID) {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID);
}

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getSessionToken();
  return token
    ? {
        headers: {
          ...headers,
          authorization: `${token}`,
        },
      }
    : { headers };
});

const invalidSessionLink = onError(({ graphQLErrors }) => {
  if (
    graphQLErrors &&
    graphQLErrors.length > 0 &&
    graphQLErrors.find(({ errorCode }) => errorCode === 401)
  ) {
    setTimeout((_) => {
      redirectToLogin();
    }, 1000);
  }
});

const httpLink = createHttpLink({
  uri: API_URL,
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: "network-only",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "network-only",
    errorPolicy: "all",
  },
};

const client = new ApolloClient({
  link: authLink.concat(invalidSessionLink).concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions,
});

const reducers = combineReducers(Reducers);
const store = createStore(reducers);

ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <App />
    </Provider>
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
