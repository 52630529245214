import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loader from '../../../helpers/Loader';
import DashboardTableView from "../DashboardTableView";
import DashboardActivities from "../DashboardActivities";
import DashboardComparison from '../DashboardComparison';
import DashboardMobileFilters from '../DashboardMobileFilters';
import DashboardDesktopFilters from '../DashboardDesktopFilters';
import DashboardSelectedFilters from '../DashboardSelectedFilters';
import ApplicatonPageLayout from '../../../helpers/ApplicationPageLayout';
import ApplicationStickyHeader from '../../../helpers/ApplicationStickyHeader';
import ApplicationDeviceIdentifier from '../../../helpers/ApplicationDeviceIdentifier';
import './index.scss';

class DashboardBody extends Component {

    getDashboardBodyView(selectedDashboardViewOption, subMenuId) {
        switch (selectedDashboardViewOption.displayName.toLowerCase()) {
            case 'activity':
                return <DashboardActivities subMenuId={subMenuId} />
            case 'data':
                return <DashboardTableView subMenuId={subMenuId} />
            case 'comparison':
                return <DashboardComparison subMenuId={subMenuId} />
            default:
                return null
        }
    }

    render() {
        const { selectedDashboardViewOption, isPreviewMode = false, subMenuId } = this.props;
        return (
            <React.Fragment>
                <ApplicationStickyHeader>
                    <ApplicationDeviceIdentifier isMobile={true}>
                        <DashboardMobileFilters />
                    </ApplicationDeviceIdentifier>
                    <DashboardSelectedFilters isPreviewMode={isPreviewMode} />
                </ApplicationStickyHeader>

                <ApplicatonPageLayout>
                    <div className="dashboard-body">
                        <ApplicationDeviceIdentifier>
                            <DashboardDesktopFilters />
                        </ApplicationDeviceIdentifier>
                        {
                            this.props.loadingDashboardDetails === false
                                ? this.getDashboardBodyView(selectedDashboardViewOption, subMenuId)
                                : <Loader className={'filters-loader'} />
                        }
                    </div>
                </ApplicatonPageLayout>
            </React.Fragment>
        );
    }

};
const mapStateWithProps = ({ dashboard }) => {
    return {
        loadingDashboardDetails: dashboard.loadingDashboardDetails,
        selectedDashboardViewOption: dashboard.selectedDashboardViewOption
    };
};

export default connect(mapStateWithProps, null)(DashboardBody);