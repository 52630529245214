import React, { Component } from 'react';
import ApplicationNavigation from '../../helpers/ApplicationNavigation';

export default class DashboardHeader extends Component {
    render() {
        return (
            <React.Fragment>
                <ApplicationNavigation {...this.props} />
            </React.Fragment>
        );
    }
};
