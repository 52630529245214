import React, { Component } from "react";
import ReactGA from "react-ga4";
import { connect } from "react-redux";
import Loader from "../../helpers/Loader";
import DashboardBody from "./DashboardBody";
import DashboardHeader from "./DashboardHeader";
import SettingsGetQuery from "../../shared/Settings/SettingsGetQuery";
import FilingsByFiltersGetQuery from "../Dashboard/FilingsByFiltersGetQuery";
import { loadWebsiteSettings } from "../../shared/Settings/store/dispatchers";
import {
  resetState,
  loadDashboardData,
  initializeDashboard,
} from "../Dashboard/store/dispatchers";

class Dashboard extends Component {
  componentDidMount() {
    const { match, loadWebsiteSettings, initializeDashboard } = this.props;
    if (match && match.params) {
      const { menuId, subMenuId, viewOption, childrenId } = match.params;
      if (menuId) {
        initializeDashboard({
          viewOption,
          categoryId: menuId,
          subCategoryId: subMenuId,
          subCategoryChildId: childrenId,
        });
      }
    }
    loadWebsiteSettings();
  }
  componentWillUnmount() {
    const { resetState } = this.props;
    resetState();
  }
  componentDidUpdate() {
    const {
      onDetailsLoaded,
      settingsDetailsLoaded,
      loadingDashboardDetails,
      selectedCategoryId,
    } = this.props;
    //ReactGA.pageview(window.location.pathname);
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Dashboard" });
    if (
      settingsDetailsLoaded === true &&
      loadingDashboardDetails === false &&
      onDetailsLoaded === false &&
      selectedCategoryId
    ) {
      const {
        circuit,
        counties,
        menuList,
        districts,
        filterList,
        viewOptions,
        fiscalYears,
        isPreviewMode,
        jurisdictionList,
        loadDashboardData,
      } = this.props;

      // set the values required in dashboard
      loadDashboardData({
        circuit,
        menuList,
        counties,
        districts,
        filterList,
        viewOptions,
        isPreviewMode,
        jurisdictionList,
        previewFiscalYears: fiscalYears,
      });
    }
  }

  render() {
    const { onDetailsLoaded, isPreviewMode = false, match } = this.props;
    const { subMenuId } = match.params;
    return (
      <React.Fragment>
        <SettingsGetQuery isPreviewMode={isPreviewMode} />
        <FilingsByFiltersGetQuery />
        {onDetailsLoaded ? (
          <React.Fragment>
            <DashboardHeader {...this.props} />
            <DashboardBody
              subMenuId={subMenuId}
              isPreviewMode={isPreviewMode}
            />
          </React.Fragment>
        ) : (
          <Loader className={"page-loader"} />
        )}
      </React.Fragment>
    );
  }
}

const mapStateWithProps = ({ setting, dashboard, preview }) => {
  return {
    circuit: setting.circuit,
    counties: setting.counties,
    menuList: setting.menuList,
    title: dashboard.selectedMenu,
    districts: setting.districts,
    filterList: setting.filterList,
    viewOptions: setting.viewOptions,
    fiscalYears: preview.fiscalYears,
    durationValue: preview.durationValue,
    onDetailsLoaded: dashboard.onDetailsLoaded,
    jurisdictionList: setting.jurisdictionList,
    dashboardViewOptions: dashboard.dashboardViewOptions,
    settingsDetailsLoaded: setting.settingsDetailsLoaded,
    loadingDashboardDetails: dashboard.loadingDashboardDetails,
    selectedCategoryId: dashboard.selectedCategoryId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initializeDashboard(details) {
      dispatch(initializeDashboard(details));
    },
    loadDashboardData(details) {
      dispatch(loadDashboardData(details));
    },
    loadWebsiteSettings() {
      dispatch(loadWebsiteSettings());
    },
    resetState() {
      dispatch(resetState());
    },
  };
};

export default connect(mapStateWithProps, mapDispatchToProps)(Dashboard);
