import React from 'react';
import ApplicationVerticalScroller from '../../../helpers/ApplicationVerticalScroller';
import ApplicationRadioButtons from '../../../helpers/ApplicationRadioButtons';
import './index.scss';

export default function DashboardMonthWiseFilters({ 
    options,
    onClose,
    onChange,
    selectedOption = null
}) {    
    const _selectedOption = selectedOption.find((value) => value);
    options = options.map(({ displayName, optionId }) => ({ name: 'month', value: displayName, id: optionId }));

    return (
        <ApplicationVerticalScroller className = 'dashboard-month-scroller'>   
            <div className = 'dashboard-month-wise-filters'>
                <ApplicationRadioButtons 
                    options = {options} 
                    onClose = {onClose} 
                    onChange = {onChange} 
                    selectedOption = {_selectedOption} />
            </div>          
        </ApplicationVerticalScroller>
      
    );
}