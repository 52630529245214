import React,{Component} from "react";
import Highcharts from "highcharts";
import drilldown from "highcharts/modules/drilldown";
import HighchartsReact from "highcharts-react-official";
import HC_exporting from 'highcharts/modules/exporting';
import ApplicationListSection from '../ApplicationListSection'
import ApplicationChartOptions from "../ApplicationChartOptions";

import './index.scss';

HC_exporting(Highcharts)
drilldown(Highcharts);

Highcharts.setOptions({
    colors : ['#8d90ff',
    '#8e2d5a',
    '#8a6e3d',
    '#c5fffe',
    '#0c48bb',
    '#d8696a',
    '#000464',
    '#d9bd05',
    '#c64690',
    '#ff7522',
    '#9d8189',
    '#008c95',
    '#ffadeb',
    '#75e4b3',
    '#ffbfb5'],
    lang: {
        decimalPoint: '.',
        thousandsSep: ','
    }
});

export default class ApplicationStackChart extends Component{
    constructor(props) {
        super(props)
        this.rotation = this.rotation.bind(this)
        
        this.drillup = this.drillup.bind(this)
        this.onDownload =this.onDownload.bind(this)
        this.state = { index:null}
    }
    componentDidUpdate(){
        Highcharts.charts.map((chart)=>{
            if(chart){
                chart.drillUp()
                if(this.props.modifiedDrilldownData.length > 0){
                    chart.series[0].show()
                    if(chart.series.length===1){
                        chart.series[0].points[0].onMouseOver()
                    }
                }
               
            }
            return ''
       })
    }
    rotation(data){
        let status=false ;
        data && data.map(({name})=>{
            name && name.split(" ").map((value)=>{
                if(value.length > 15){
                    status =true
                } 
                return ''
            })
            return ''
        })
        if(status){return { rotation:-45}}
        else{
            return {}
        }
    }
    drillup(onDrillUp){
        Highcharts.charts.map((chart)=>{
            if(chart){
                onDrillUp() 
                chart.drillUp()
            }
            return ''
       })
    }
    onDownload(
        displayName,
        options,
        title,
        subTitle,
        selectedYears,
        selectedSubMenuChild
    ){

        let type
        if(displayName==='PNG'){
            type= 'image/png'
        } 
        if(displayName==='JPG'){
            type= 'image/jpeg'
        }
        if(displayName==='PDF'){
            type= 'application/pdf'
        }
        const _selectedYears = selectedYears.reduce((accum,{value})=>{
                accum.push(value)
                return accum
            },[])
        // const name=`${subTitle.displayName}-${title}(${_selectedYears.join(',')})`
        let name = `${subTitle.displayName}-${title}(${_selectedYears.join(',')})`;
        if(subTitle.name === "selfHelpCenter"){
            name = `${title}(${_selectedYears.join(',')})`;
            if(selectedSubMenuChild && (selectedSubMenuChild.displayName !== "All")){
                name = `${selectedSubMenuChild.displayName}-${title}(${_selectedYears.join(',')})`;
            }
        }
        Highcharts.charts.map((chart) => {
            if(chart) {
                if(chart.userOptions.title.text === options.title.text){
                    chart.exportChart({
                        type: type,
                        filename: name,
                    },
                    {
                        title: {
                            text:name,
                            style: {
                                display: 'block'
                            }
                        } ,
                        subtitle: {
                            text: ''
                        },
                        plotOptions: {
                            column: {
                                dataLabels: {
                                    enabled: true,
                                    color: '#000',
                                    borderColor: '#000',
                                    fontSize:'10px',
                                    format: '{point.y:,.0f}'
                                }
                            }
                        }
                    });
                }
            }
            return '';
       })
    }
    setDrilldownData(e){
        const {onDrilldown}=this.props
        if(e.seriesOptions){   
            return (onDrilldown({
                points:e.points,
                seriesOptions:e.seriesOptions
            }));
        }
        else{
            if(e.point.drilldown){
                const _data ={
                    points:e.points,
                    seriesOptions:{
                        title:e.point.name,
                        id:e.point.drilldown,
                    }
                }
                return onDrilldown(_data)
            }
        }
    }
    render(){
        const {
            chartData,
            drilldownData,
            listNames,
            exdcludeOptions,
            footnotes,
            onClickOption,
            viewType,
            title,
            subTitle,
            selectedYears,
            modifiedDrilldownData,
            selectedSubMenuChild,
            stackTitle,
            onDrillUp
        }=this.props
        const _modifiedDrilldownData = modifiedDrilldownData;
        const series = _modifiedDrilldownData.length > 0 ? _modifiedDrilldownData : chartData;
        const drillDown = _modifiedDrilldownData.length > 0 ? null :drilldownData;
        const labelFontSize = ((series && series.length > 0) && (series[0].data.length > 9)) ? 6 : 11

    const options = {
        chart: {
            type: 'column', 
            height: 330,
            events: {
                drilldown:(e)=>{ 
                    e.preventDefault()
                    if (!e.points || e.points[0] === e.point) {
                        this.setDrilldownData(e)
                    }
                    else{
                        const isDrillDown=  e.points.find( ({options}) => options === e.point.options )
                        if(isDrillDown){
                            this.setDrilldownData(e)
                        }
                    }
                },
            }
        },
        title: {
            text: title,
            style: {
                display: 'none'
            }  
        },
        subtitle: {
            text: stackTitle? stackTitle :'',
            style: {
                fontSize: '18',
                color: '#333'
            } 
        },
        credits: {
            enabled: false
        },
        exporting: {
            enabled: false,
        },
        legend: {
            itemMargintop: 10,
            itemStyle: {
                color: '#676767',
                fontWeight: 'bold'
            }
        },
        yAxis: {
            title: {
                text: ""
            },
            labels: {
                format: '{value:,.0f}'
            },
            stackLabels: {
                enabled: true,
                overflow:"allow",
                crop:false,
                style: {
                    fontWeight: 'bold',
                    color: ( // theme
                        Highcharts.defaultOptions.title.style &&
                        Highcharts.defaultOptions.title.style.color
                    ) || 'gray'
                }
            }
        },
        xAxis: {
            type:'category',
            // labels: this.rotation(series),
        },
        plotOptions: {
            column: {
                stacking: 'normal',
                cropThreshold: 300,
                events: {
                    click: function(e) {
                        // if(e.point.series){
                        //     if(this.points&& this.points[0].drilldown){
                        //         const seriesOptions={
                        //             points:this.points,
                        //             seriesOptions:drilldownData.find(drillDown => drillDown.id ===  this.points[0].drilldown)
                        //         }
                        //         this.points[0].doDrilldown(seriesOptions)
                        //     }     
                        // }
                    }
                },
            },
            
        },
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 500
                },
                chartOptions: {
                    yAxis: {
                        title: {
                            text: ""
                        },
                        labels: {
                            format: '{value:,.0f}'
                        },
                        stackLabels: {
                            enabled: true,
                            style: {
                                fontWeight: 'bold',
                                fontSize:labelFontSize + 'px',
                                color: ( // theme
                                    Highcharts.defaultOptions.title.style &&
                                    Highcharts.defaultOptions.title.style.color
                                ) || 'gray'
                            }
                        }
                    },
                    
                }
                },
            ]
        },
        series: series 
        , 
        drilldown: {
            drillUpButton: {
                relativeTo: 'spacingBox',
                position: {
                    y: 0,
                    x: 50
                }
            },
         series : drillDown
        },
        tooltip: {
            useHTML: true,
            headerFormat: '<small style="color:#000 ;font-size:12px" >{point.key}</small><table  style="z-index:1000">',
            pointFormat: '<tr><td style="color:#000;padding-right:50px">{series.name}: </td>' +
            '<td style="color:#000;text-aling=right"><b>{point.y}</b></td></tr>',
            footerFormat: '</table>',
            shared: true,
            outside:true,
            distance:60,
            followPointer:true
        }
    };
        return (
            <div className="application-bar-chart">
            {
                chartData && chartData.length > 0 
                ?   <React.Fragment>
                        <div className= "highchart-container" >
                            <HighchartsReact highcharts={Highcharts} options={options} />
                        </div>
                    </React.Fragment>
                :   <div className= 'no-results' >No Data Available</div>
            }
            {
               chartData && chartData.length > 0  &&
               <div className= "chart-option-container">
                    <div className='icon-download download-chart'>
                        <ApplicationListSection 
                            listNames={listNames} 
                            viewType={viewType} 
                            onClickOption={({displayName}) => 
                                this.onDownload(
                                    displayName,
                                    options,
                                    title,
                                    subTitle,
                                    selectedYears,
                                    selectedSubMenuChild
                                )
                            } />
                    </div>
                    <ApplicationChartOptions
                        listNames={listNames}
                        title={title}
                        footnotes={footnotes}
                        exdcludeOptions={exdcludeOptions}
                        onClickOption={onClickOption} />
                </div>
            }
            
            {
                (_modifiedDrilldownData.length >0  ) &&
                <span className="back-button" onClick={e=> this.drillup(onDrillUp)}> &lt; Back </span>
            }
        </div>  
        );
    }
}

