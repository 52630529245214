import React from 'react';
import './index.scss';

export default function ApplicationModalMobileFilterHeader({
    onClose
}){
    return(
        <div className="aplication-modal-mobile-filter-header" id="mobileFilterHeader">
            <div className="aplication-modal-mobile-filter-header__mobile-filter">
                <span className="icon-cancel aplication-modal-mobile-filter-header__mobile-filter-close" onClick = {onClose}></span>
            </div>
            <div className="aplication-modal-mobile-filter-header__mobile-filter">
                <span className="aplication-modal-mobile-filter-header__mobile-filter-title"> filter </span>
            </div>
        </div>
    )
}