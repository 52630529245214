import React,{Component} from 'react';
import ApplicationOutsideClick from '../../../helpers/ApplicationOutsideClick'
import './index.scss';

export default class DashboardActionDropdown extends Component{
    constructor(props) {
        super(props);
        this.customDropdownOptions = props.customDropdownOptions;
        this.state = {
            isDropdownOpen: false
        }
        this.onDropdownClick = this.onDropdownClick.bind(this);
        this.onDropdownOptionChange = this.onDropdownOptionChange.bind(this);
        this.onBackdropClick = this.onBackdropClick.bind(this);
    }
    onDropdownClick() {
        this.setState(state => {
            return {
                isDropdownOpen: !state.isDropdownOpen
            };
        });
    }
    onBackdropClick(){
        this.setState({
            isDropdownOpen: false
        })
    }
    onDropdownOptionChange(e,selectedOption) {
        const {title,subTitle,onClick,selectedDashboardViewOption} =this.props
        const viewOption = selectedOption.toLowerCase();
        const subMenu = subTitle ? subTitle.id : null;
        if(!(selectedDashboardViewOption.displayName === selectedOption)){
            onClick({
                viewOption,
                menuId: title.id,
                subMenuId: subMenu
            });
        }
        
    }
    
    render(){
        const {isDropdownOpen} = this.state;
        const { dashboardViewOptions, selectedDashboardViewOption } = this.props;

        return(
            <ApplicationOutsideClick  onOutsideClick = {this.onBackdropClick}>
                <div className = "dashboard-action-dropdown">
                    <div className = 'dashboard-action-dropdown__label' onClick = {this.onDropdownClick}>
                        <div className = 'title'> {selectedDashboardViewOption.displayName} </div>
                        <span className = {`icon-chevron-down dashboard-action-dropdown__icon`}></span>
                    </div>
                    {
                        isDropdownOpen && 
                        <div className = "dropdown-list">
                            <ul >
                                {dashboardViewOptions.map(({ displayName, viewTypeId } , index) => {
                                    return <li
                                        key = {index}
                                        value = {displayName}
                                        id = {viewTypeId}
                                        onClick = {e => this.onDropdownOptionChange(e,displayName)}> 
                                            {displayName}
                                    </li>
                                })}
                            </ul>
                        </div>
                    }
                </div>
            </ApplicationOutsideClick>
        )
    }
}

