import React from 'react';
import ApplicationTableHeader from "../ApplicationTableHeader";
import ApplicationTableSections from "../ApplicationTableSections";
import ApplicationTabularScrollbar from "../ApplicationTabularScrollbar";
import './index.scss';

export default function ApplicationTabularData({data ,SubMenuChild}) {  
    return (  
        ((data.tableHeaders && data.tableHeaders.length > 0 ) && (data.tableSections && data.tableSections.length > 0))?
            <div className='table-relative'>
                <ApplicationTabularScrollbar>
                    <div className="application-table-container">
                        <table className="application-table-container__table">
                            <ApplicationTableHeader 
                                tableHeader={data.tableHeaders} 
                                menuName= {data.menuName} 
                                displayName = {data.displayName} 
                                variableName = {data.variableName}
                            />
                            <ApplicationTableSections 
                                tableSections = {data.tableSections} 
                                menuName= {data.menuName} 
                                unit={data.unit} 
                                SubMenuChild={SubMenuChild}  
                                displayName = {data.displayName} 
                                variableName = {data.variableName}
                            /> 
                        </table> 
                    </div>
                </ApplicationTabularScrollbar>  
            </div>   
        :   <div className= 'no-results no-results__table' >No Data Available</div> 
    );    
};