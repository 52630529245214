import React, { Component } from 'react';
import './index.scss';

export function closeModal() {
    document.body.classList.remove("on-modal-open");
};

export default class ApplicationModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: props.showModal
        };
        this.modalPosition = props.modalPosition;        
        this.modalBackgroundOpacity = props.modalBackgroundOpacity;
    }
    onBackdropClick(event) {
        const { closeOnBackdropClick = true, onClose } = this.props;
        const classNames = event.target.classList.value.split(' ');
        if(closeOnBackdropClick && (classNames.indexOf('modal') !== -1 || classNames.indexOf('modal-opacity') !== -1)) {
            if(onClose) {
                onClose();
            }
        }
    }
    render() {
        const { showModal, children, type = null } = this.props;

        if(showModal) {
            document.body.classList.add("on-modal-open");
        }

        return showModal ? (
            <div className = {this.modalBackgroundOpacity === true ? 'modal-opacity' : 'modal'} onClick = {this.onBackdropClick.bind(this)}>
                {
                    type === 'fiscalYears' ?
                    <div  className = {'alert-modal'}>
                        {children}
                    </div> :
                    <div  className = {this.modalPosition === "top" ? 'top-modal' : 'custom-modal'}>
                        {children}
                    </div>
                }
            </div>
        ) : null
    }
}