import React from 'react';
import './index.scss';

function _onclick(title,OnClickView){
    OnClickView(title)
}
export default function ApplicationContentBlock({
    content,
    OnClickView,
    title = "default title"
}) {
    return (
        <div className="application-content-block">
            <div className="application-content-block__header">
                <div>
                    <span> {title} </span>
                </div>
                <div className="application-content-block__header__button">
                    <button onClick={e => _onclick(title,OnClickView)}>
                        view report
                    </button>
                </div>
            </div>
            <div className="application-content-block__body">
                <div>
                    <p> {content} </p>
                </div>
            </div>
        </div>
    )
}