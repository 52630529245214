import React from 'react';
import ApplicationCheckBoxButtons from '../../../helpers/ApplicationCheckBoxButtons';

export default function DashboardMultipleFiscalYearsFilters({
    options,
    selectedOptions,
    onChange
}) {
    const _selectedOptions = selectedOptions && selectedOptions.map(({value})=> value);
    options = options && options.map(({ optionId, displayName }) => ({name: 'fiscalYears', id: optionId, value: displayName }));
    
    return (
        <div className='dashboard-multiple-fiscal-years-type'>
             <ApplicationCheckBoxButtons 
                options = {options} 
                headerCheckbox = {''}
                showHeaderAsSection = {false}
                selectedOptions = {_selectedOptions}
                onChange = {value => onChange(value)}/>
        </div>     
    );
};

