import React from 'react';
import ApplicationActionButton from '../ApplicationActionButton';
import ApplicationModal, { closeModal } from '../ApplicationModal';
import './index.scss';

export default class ApplicationCheckBoxButtons extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            selectedOptions: this.getModifiedSelectedOptions(props),
            showModal: false,
        };
        this.onClose = this.onClose.bind(this);
        this._onChange = this._onChange.bind(this);
    }
    getModifiedSelectedOptions(props) {        
        const { headerCheckbox, options, selectedOptions } = props;
        if(headerCheckbox 
            && (
                selectedOptions.indexOf(headerCheckbox) !== -1 
                || selectedOptions.length === options.length - 1
            )) {
            return options.map(({  value }) => value);
        } else {
            return selectedOptions || [];
        }     
    }
    componentWillReceiveProps(props) {
        // this method will be triggered if the props value of the componnent is changed
        this.setState({
            selectedOptions: this.getModifiedSelectedOptions(props)
        });              
    }
    _onChange(isChecked, checkboxOption, headerCheckbox, isCheckboxSectionHeader, option) {
        const { options, onChange } = this.props;
        const { selectedOptions } = this.state;
        let modifiedOptions = [];
        
        // if checkbox selected and the selected checkbox is the parent header checkbox
        // or if all checkbox child item selected - make parent header checkbox as selected
        if(isChecked === true && (
            isCheckboxSectionHeader === true || headerCheckbox === checkboxOption || (headerCheckbox && selectedOptions.length === options.length - 2))
        ) {            
            modifiedOptions = options.map(({ value }) => value);                
        } else if(isChecked === true) {
            // if checkbox is unselected
            modifiedOptions = selectedOptions.concat(checkboxOption);            
        } else {
            // If checkbox is unselected
            // or if parent header checkbox is unselected - deselect all child checkbox
            modifiedOptions = isCheckboxSectionHeader === true || headerCheckbox === checkboxOption  
                ? [] : selectedOptions.filter(selectedOption => {
                    let status = false;
                    if(selectedOption !== checkboxOption) { 
                        status = true
                    }
                    if(headerCheckbox && headerCheckbox === selectedOption) {                        
                        status = false;
                    }
                    return status;
                });
        }
        this.setState({
            selectedOptions: [...modifiedOptions]
        });
        const changedOptions = modifiedOptions.map(value => {
            const option = options.find(option => option.value === value);
            return option;
        });
        onChange({...option, isChecked, selectedOptions: [...changedOptions]});
    } 

    onAlertModal() {
        this.setState({
            showModal: true
        })
    }

    alertModalMessage(name) {
        switch(name) {
            case 'jurisdiction':
                return 'Minimum one jurisdiction type required';
            case 'allcounties':
                return 'Minimum one county required';
            case 'fiscalYears':
                return 'Minimum one year selection required';
            case 'months':
                return 'Minimum one month selection required'
            default:
                return null;
        }
    }

    onClose() {
        closeModal();
        this.setState({
            showModal: false
        })
    }

    render() {
        // Terminology
        // headerCheckbox is the parent of all checkbox
        // isCheckboxSectionHeader is for formatting the parent header checkbox
        const { options, headerCheckbox, showHeaderAsSection = false } = this.props;
        const { selectedOptions, showModal } = this.state;
        return (
            <ul className = 'application-checkbox-section'>
                {
                    options && options.map((option, index) => {    
                        const { name, value } = option;
                        const isCheckboxSectionHeader = headerCheckbox && showHeaderAsSection === true ? value === headerCheckbox : false;
                        const isChecked = selectedOptions.indexOf(value) === -1 ? false : true;
                        const isAllJurisdictionSelected = option.value === 'All Jurisdiction';
                        return (
                            <li key = {index}
                                className = {isCheckboxSectionHeader === true ? 'application-checkbox-section__header ' 
                                    : showHeaderAsSection ? 'application-checkbox-section__modal-item' : 'application-checkbox-section__item' }> 
                                <input 
                                    type = 'checkbox' 
                                    id = {value}
                                    className = 'application-checkbox-section__checkbox-button'
                                    name = {name} 
                                    value = {value}
                                    checked = {isChecked}
                                    onChange = {e => ((name === 'fiscalYears')|| (name === 'months') || (name === 'allcounties') || (name === 'jurisdiction'))
                                        && ((selectedOptions.length === 1 && isChecked) || (isAllJurisdictionSelected && isChecked))
                                            ? this.onAlertModal()
                                            : this._onChange(!isChecked, value, headerCheckbox, isCheckboxSectionHeader, option)} 
                                    />
                                <label className = 'application-checkbox-section__values' htmlFor = {value}> 
                                    {value} 
                                </label>  
                                <div className = {name === 'fiscalYears'|| name === 'months' || name === 'allcounties' || name === 'jurisdiction' 
                                    ? 'display-block' : 'hide-block'}>
                                {
                                    name === 'fiscalYears' || name === 'months' || name === 'allcounties' || name === 'jurisdiction'
                                        ? showModal 
                                            ? <ApplicationModal 
                                                type = 'fiscalYears'
                                                showModal = {true} 
                                                onClose = {this.onClose}
                                                closeOnBackdropClick = {true}
                                                modalBackgroundOpacity = {false} >
                                                <div className = 'fiscalYear-modal'>
                                                    <div className = 'modal-text'> 
                                                        <span> {this.alertModalMessage(name)} </span>
                                                    </div>
                                                    <div>
                                                        <ApplicationActionButton 
                                                            type = 'cancel'
                                                            title = 'Cancel'
                                                            className = 'cancel-button'
                                                            onClick = {this.onClose} />
                                                    </div>
                                                </div>
                                                </ApplicationModal>
                                            : null
                                        : null
                                }
                                </div>
                            </li>
                        );
                    })
                }
            </ul>
        )
    }
}

