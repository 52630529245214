import React, { Component } from 'react';
import { connect } from 'react-redux';
import ApplicationChartContainers from '../../../helpers/ApplicationChartContainers';
import './index.scss';

class DashboardComparison extends Component {
    render() {
        const { 
            activityData,
            subTitle,
            viewType,
            subMenuId,
            selectedFilters,
            selectedSubMenuChild
        } = this.props;
        
        return (
            <div className="dashboard-activities" >
                {
                    viewType.toLowerCase() === 'comparison'
                        ? activityData && activityData.map((container, index) => {
                            return(
                                <ApplicationChartContainers 
                                    key = {index}
                                    subMenuId = {subMenuId}
                                    selectedSubMenuChild={selectedSubMenuChild}
                                    containerData={JSON.parse(JSON.stringify(container))}
                                    subTitle = {subTitle}
                                    selectedFilters={selectedFilters}
                                    viewType ={viewType.toLowerCase()} />
                            );
                        }) 
                        : null
                }
            </div>
        );
    }
};

const mapStateWithProps = ({ dashboard }) => {
    return {
        activityData: dashboard.activityData,
        subTitle: dashboard.selectedSubMenu,
        viewType:dashboard.selectedDashboardViewOption.displayName,
        selectedSubMenuChild :dashboard.selectedSubMenuChild,
        selectedFilters:dashboard.selectedFilters,
        dashboardFiltersList: dashboard.dashboardFiltersList
    };
};

export default connect(mapStateWithProps, null)(DashboardComparison);