import React from 'react';
import ApplicationRadioButtons from '../../../helpers/ApplicationRadioButtons';
import './index.scss';

export default function DashboardFiscalYearFilters({ 
    options,
    onClose,
    onChange,
    selectedOption = null,
}) {
    const _selectedOption = selectedOption && selectedOption.find((value) => value)
    options = options && options.map(({ displayName, optionId }) => ({ name: 'fiscalYear', value: displayName, id: optionId }));

    return(
        <div className = 'dashboard-fiscalyear-filters'>
            <ApplicationRadioButtons 
                options = {options} 
                onClose = {onClose}
                selectedOption = {_selectedOption}
                onChange = {onChange} />
        </div>
    );
}

