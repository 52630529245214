import React from 'react';
import './index.scss';

export default function DashboardModalCountyContent ({
    children
}) {
    return (
        <div className="dashboard-modal-county-content">
            {children}
        </div>
    )
}