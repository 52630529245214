import React, { Component } from 'react';
import ApplicationHorizontalScroller from '../../../../helpers/ApplicationHorizontalScroller'
import './index.scss';

export default class HomeMobileCountyView extends Component {
    render() {
        const {counties,openModal,selectedFiscalYear,detailsOnCountyView} = this.props;
        return (
            <div className = 'county-details-container'> 
                <div className = "county-details-container__Header">
                    <div className = "county-details-container__Header__title">Click On a County</div>
                    <div className = "county-details-container__Header__button button" onClick = {e => {openModal('STATE WIDE')}}> 
                        View statewide data
                    </div>
                </div>
                <ApplicationHorizontalScroller>
                    <div className = "county-details-container__sections">
                        {
                            counties.map((county,index) => {
                               
                                return (
                                    <div className = 'county-section' onClick = {e=>{openModal(county.displayName,county.countyId)}} key = {index}>
                                            <div className = 'title'>{county.displayName}</div>
                                            <div className = 'contain'>
                                                {
                                                    detailsOnCountyView.map((value,index)=>{
                                                        return ( 
                                                            <div className = 'contain__details' key={index}> 
                                                                {value.displayName} : {value.getDetails(county,selectedFiscalYear)} 
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                    </div>
                                );
                            })
                        }
                    </div>
                </ApplicationHorizontalScroller>
            </div>
        )
    }
}


