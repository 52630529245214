import React from 'react';
import './index.scss';

export default class DashboardModalCountyContentHeader extends React.PureComponent {
    constructor(props) {
        super(props);
        this.selectDiselectClick = this.selectDiselectClick.bind(this)
    }
    selectDiselectClick(event) {
        const { selectDiselectOptions } = this.props;
        selectDiselectOptions(event.target.value)
    }
    render() {
        const { title, buttonName } = this.props
        return (
            <div className="dashboard-modal-county-content-header">
                <span> {title} </span>
                <button onClick = {this.selectDiselectClick} value = {buttonName}> {buttonName} </button>
            </div>
        )
    }
}