import React from 'react';
import ApplicationOutsideClick from '../ApplicationOutsideClick';
import ApplicationVerticalScroller from '../ApplicationVerticalScroller';
import './index.scss';

export default class ApplicationFootNotesDetails extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isDropdownOpen: false
        }
        this.onOutsideClick = this.onOutsideClick.bind(this);
        this.onViewNotesClick = this.onViewNotesClick.bind(this);
    }

    onViewNotesClick() {
        this.setState(state => {
            return {
                isDropdownOpen: !state.isDropdownOpen
            };
        });
    }

    onOutsideClick() {
        this.setState({
            isDropdownOpen: false
        })
    }

    render() {
        const { footnotes } = this.props;
        const { isDropdownOpen } = this.state;
        return (
            footnotes
            ? <ApplicationOutsideClick onOutsideClick = {this.onOutsideClick} >
                <div className = 'application-footnote-details'>
                    <div className = 'application-footnote-details__view-note' 
                        onClick = {this.onViewNotesClick}> View Notes  
                    </div>
                    <div className = {isDropdownOpen === true ? 'application-footnote-details__paragraph-show' :
                        'application-footnote-details__paragraph-hide'}>
                        <p className = 'header'> Foot Notes: </p>
                        <ApplicationVerticalScroller>
                            <pre> {footnotes} </pre>
                        </ApplicationVerticalScroller>
                        <div className = 'arrow'></div>
                    </div>
                </div>
            </ApplicationOutsideClick> : null
        )
    }
}