
export default {
    //new added 
    ON_SUBMENU_OPEN:'ON_SUBMENU_OPEN',
    ON_ACTIVITY_CALLED:"ON_ACTIVITY_CALLED",
    LOAD_DASHBOARD_DATA: 'LOAD_DASHBOARD_DATA',
    INITIALIZE_DASHBOARD: 'INITIALIZE_DASHBOARD',    
    RESET_DASHBOARD_STATE: 'RESET_DASHBOARD_STATE',
    ON_TITLE_SUBTITLE_UPDATE: 'ON_TITLE_SUBTITLE_UPDATE',
    RESET_DASHBOARD_ACTIVITY:'RESET_DASHBOARD_ACTIVITY',
    ON_DASHBOARD_DATA_LOADED: 'ON_DASHBOARD_DATA_LOADED',
    ON_DASHBOARD_MONTH_FILTER_CHANGE: 'ON_DASHBOARD_MONTH_FILTER_CHANGE',
    ON_DASHBOARD_PROGRAM_FILTER_CHANGE: 'ON_DASHBOARD_PROGRAM_FILTER_CHANGE',
    ON_DASHBOARD_SELECTED_FILTER_CLOSE: 'ON_DASHBOARD_SELECTED_FILTER_CLOSE',
    ON_DASHBOARD_MULTIPLE_MONTHS_CHANGE:'ON_DASHBOARD_MULTIPLE_MONTHS_CHANGE',
    ON_DASHBOARD_SELECT_BY_FILTER_CHANGE: 'ON_DASHBOARD_SELECT_BY_FILTER_CHANGE',
    SET_COUNTIES_AND_JURISDICTION_VALUES: 'SET_COUNTIES_AND_JURISDICTION_VALUES',
    SET_PROGRAM_VALUES: 'SET_PROGRAM_VALUES',
    ON_DASHBOARD_CASE_TYPE_FILTER_CHANGE: 'ON_DASHBOARD_CASE_TYPE_FILTER_CHANGE',
    ON_DASHBOARD_FILINGS_BY_FILTER_CHANGE: 'ON_DASHBOARD_FILINGS_BY_FILTER_CHANGE',
    ON_DASHBOARD_COUNTY_TYPE_FILTER_CHANGE: 'ON_DASHBOARD_COUNTY_TYPE_FILTER_CHANGE',
    ON_DASHBOARD_FISCAL_YEAR_FILTER_CHANGE: 'ON_DASHBOARD_FISCAL_YEAR_FILTER_CHANGE',
    ON_DASHBOARD_COMPARSION_BY_FILTER_CHANGE: 'ON_DASHBOARD_COMPARSION_BY_FILTER_CHANGE',
    ON_DASHBOARD_DISTRICT_TYPE_FILTER_CHANGE: ' ON_DASHBOARD_DISTRICT_TYPE_FILTER_CHANGE',
    ON_DASHBOARD_MULTIPLE_FISCAL_YEARS_CHANGE: 'ON_DASHBOARD_MULTIPLE_FISCAL_YEARS_CHANGE',
    ON_DASHBOARD_JURISDICTION_TYPE_FILTER_CHANGE: 'ON_DASHBOARD_JURISDICTION_TYPE_FILTER_CHANGE'
}; 