import Actions from './actions';

export function loadDashboardData(data) {
    return {
        type: Actions.LOAD_DASHBOARD_DATA,
        data
    }
};

export function setCountiesAndJurisdictionValues(data) {
    return {
        type: Actions.SET_COUNTIES_AND_JURISDICTION_VALUES,
        data
    }
}
export function setProgramValues(data) {
    return {
        type: Actions.SET_PROGRAM_VALUES,
        data
    }
}

export function onDashboardDataLoaded(data) {
    return {
        type: Actions.ON_DASHBOARD_DATA_LOADED,
        data
    };
};

export function initializeDashboard(data) {    
    return {
        type: Actions.INITIALIZE_DASHBOARD,
        data
    };
};

export function onTitleSubtitleUpdate(title, subTitle) {
    return {
        type: Actions.ON_TITLE_SUBTITLE_UPDATE,
        title: title,
        subTitle: subTitle
    };
};

export function isOpenSubmenu(values){
    return {
        type: Actions.ON_SUBMENU_OPEN,
        data : values
    };
};

export function onActivityDataCalled(value){
    return{
        type:Actions.ON_ACTIVITY_CALLED,
        data:value
    };
};

export function resetState() {
    return {
        type: Actions.RESET_DASHBOARD_STATE
    };
};

export function onDashboardFiscalYearFilterChange(year) {
    return {
        type: Actions.ON_DASHBOARD_FISCAL_YEAR_FILTER_CHANGE,
        data: year
    };
};

export function onDashboardProgramFilterChange(selectedProgram) {
    return {
        type: Actions.ON_DASHBOARD_PROGRAM_FILTER_CHANGE,
        data: selectedProgram
    };
};

export function onDashboardCaseTypeFilterChange(caseType) {
    return {
        type: Actions.ON_DASHBOARD_CASE_TYPE_FILTER_CHANGE,
        data: caseType
    };
};

export function onDashboardFilingsByFilterChange(filingBy) {
    return {
        type: Actions.ON_DASHBOARD_FILINGS_BY_FILTER_CHANGE,
        data: filingBy
    };
};

export function onDashboardSelectByFilterChange(selectBy) {
    return {
        type: Actions.ON_DASHBOARD_SELECT_BY_FILTER_CHANGE,
        data: selectBy
    };
};

export function onDashboardMonthFilterChange(selectedMonth) {
    return {
        type: Actions.ON_DASHBOARD_MONTH_FILTER_CHANGE,
        data: selectedMonth
    };
};

export function onDashboardComparsionByFilterChange(comparisonBy) {
    return {
        type: Actions.ON_DASHBOARD_COMPARSION_BY_FILTER_CHANGE,
        data: comparisonBy
    };
};

export function onDashboardMultipleFiscalYearsChange(fiscalYears) {
    return {
        type: Actions.ON_DASHBOARD_MULTIPLE_FISCAL_YEARS_CHANGE,
        data: fiscalYears
    };
};

export function onDashboardMultipleMonthsChange(months) {
   
    return {
        type: Actions.ON_DASHBOARD_MULTIPLE_MONTHS_CHANGE,
        data: months
    };
};
export function onDashboardJurisdictionTypeFilterChange(type) {
    return {
        type: Actions.ON_DASHBOARD_JURISDICTION_TYPE_FILTER_CHANGE,
        data: type
    };
};

export function onDashboardCountyTypeFilterChange(type) {
    return {
        type: Actions.ON_DASHBOARD_COUNTY_TYPE_FILTER_CHANGE,
        data: type
    }
}

export function onDashboardDistrictTypeFilterChange(selectedFilterOptions) {
    return {
        type: Actions.ON_DASHBOARD_DISTRICT_TYPE_FILTER_CHANGE,
        data: selectedFilterOptions
    };
};

export function onDashboardSelectedFilterClose(option){
    return{
        type: Actions.ON_DASHBOARD_SELECTED_FILTER_CLOSE,
        data: option
    };
};


export function resetActivities(){
    return {
        type: Actions.RESET_DASHBOARD_ACTIVITY
    };
};



