import Actions from './actions';

const defaultState = {
    loadingWebsiteSettings: false,
    menuList: [],
    filterList: [],
    counties: [],
    districts: [],
    circuit: [],
    viewOptions: [],
    jurisdictionList: [],
    settingsDetailsLoaded: false,
}
export default function SettingReducer(state = defaultState, action) {
    switch (action.type) {
        case Actions.LOAD_WEBSITE_SETTINGS:
            return {
                ...state,
                loadingWebsiteSettings: true,
                settingsDetailsLoaded: false
            };
        case Actions.ON_WEBSITE_SETTINGS_LOADED:
            return {
                ...state,
                menuList: action.data.menu,
                filterList: JSON.parse(JSON.stringify(action.data.filterList)),
                counties: action.data.countyList,
                districts: action.data.districtList,
                circuit: action.data.circuitList,
                jurisdictionList: action.data.jurisdictionList,
                viewOptions: action.data.viewTypeList,
                settingsDetailsLoaded: true
            };        
        default:
            return state;
    }
}