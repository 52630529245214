import React from 'react';
import './index.scss';

export default function DashboardModalCountyContentFooter({
    onClear,
    onCancel,
    onApply,
    disabled
}){
    return (
        <div className="dashboard-modal-county-content-footer">
            <button className = "dashboard-modal-county-content-footer__button" onClick = {onClear}> clear </button>
            <button className = "dashboard-modal-county-content-footer__button" onClick = {onCancel}> cancel </button>
            <button 
                className = { disabled ? "dashboard-modal-county-content-footer__disabled" : "dashboard-modal-county-content-footer__button" }
                disabled = {disabled ? disabled : null} 
                onClick = {onApply}> apply 
            </button>
        </div>
    );
}
