import React from 'react';
import './index.scss';

export default function ApplicationDesktopFilters({ 
    title,
    children 
}){
    return(
        <h2 className='application-desktop-filters'>
            <div className='application-desktop-filters__title'>
                <span className='icon-filter application-desktop-filters__image'></span>
                {title} 
            </div>
            {children}
        </h2>
    )
}